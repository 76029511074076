<script setup lang="ts">
import { computed, onMounted, ref, shallowRef, watch } from 'vue';
import {
  addMonths,
  format,
  lastDayOfMonth,
} from 'date-fns';

import { useRoute, useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';
import { RefreshCw } from 'lucide-vue-next';

import LitAlert from '@/js/components/Base/LitAlert.vue';
import LitCheckbox from '@/js/components/Base/LitCheckbox.vue';
import LitRadio from '@/js/components/Base/LitRadio.vue';
import LitInput from '@/js/components/Base/LitInput.vue';
import LitSelect from '@/js/components/Base/LitSelect.vue';
import LitButton from '@/js/components/Base/LitButton.vue';
import CustomStrategyWarningModal from '@/js/components/Modals/CustomStrategyWarningModal.vue';

import ContributionSelection from '@/js/components/ContributionSelection.vue';
import DistributionStepper from '@/js/components/Steppers/DistributionStepper.vue';
import StrategySelection from '@/js/components/StrategySelection.vue';
import CustomStrategySelection from '@/js/components/CustomStrategySelection.vue';
import QuestionnaireLoader from '@/js/components/Questionnaire/QuestionnaireLoader.vue';

import gtm from '@/js/services/gtm';
import pensionCompanies from '@/js/data/pensionCompanies';
import { scrollToError } from '@/js/utils';

import { useDistributionStore } from '@/js/stores';
import { updateContract } from '@/js/stores/utils';
import { useQuestionnaire } from '@/js/composables/useQuestionnaire';
import type { Question } from '@/js/types/questionnaire';
import { contributionAndStrategyFields, currentContractFields } from '@/js/data/validationFields/commonFields';
import availableAnswers from '@/js/data/financialQuestionnaire.json' with { type: 'json' };
import StepButtons from '@/js/views/common/StepButtons.vue';

import StepperVertical from '@/js/components/StepperVertical.vue';
import StepperVerticalItem from '@/js/components/StepperVerticalItem.vue';
import QuestionnaireForm from '@/js/components/QuestionnaireForm.vue';
import QuestionnaireRadioGroup from '@/js/components/QuestionnaireRadioGroup.vue';

import { calculateQuestionnairePoints } from '@/js/utils/questionnairePoints';
import { getCategoryDescription, getStrategiesForCategory } from '@/js/utils/recommendedStrategies';
import { useDebounceFn } from '@vueuse/core';

const store = useDistributionStore();
const {
  isFromRequest,
  isFromTransfer,
  contributionAndStrategy,
  contractSettings,
  legalRequirements,
  clientAge,
  birthDay,
} = storeToRefs(store);
const {
  validateField,
  validateFields,
  updatePreferConservativeFund,
} = store;

const route = useRoute();
const router = useRouter();

const showCustomStrategyModal = ref(false);
const currentPensionCompanyOptions = ref(pensionCompanies);
const dateFormat = ref('dd. MM. yyyy');

const {
  questions,
  status,
  resetAnswer,
  lastAnsweredQuestion,
  loading,
  isQuestionVisible,
  handleMultipleChoice,
  handleSingleChoice,
  processMatrixResults,
  forceKoAfterQuestion2,
  updateQuestionnaireUI,
} = useQuestionnaire(availableAnswers.questions, contributionAndStrategy.value.questionnaire);

const contractTypeOptions = shallowRef([
  { value: 'pensionCompany', label: 'Doplňkové penzijní spoření' },
  { value: 'transformFund', label: 'Transformovaný fond' },
]);

const totalPoints = computed({
  get () {
    return contributionAndStrategy.value.totalPoints.value;
  },
  set (value) {
    contributionAndStrategy.value.totalPoints.value = value;
  },
});

const isQuestionnaireProcessed = computed(() => {
  return totalPoints.value !== null && !loading.value;
});

const selectCustomStrategy = computed({
  get () {
    return contributionAndStrategy.value.selectCustomStrategy.value;
  },
  set (value) {
    contributionAndStrategy.value.selectCustomStrategy.value = value;
  },
});

const selectedStrategy = computed({
  get () {
    return contributionAndStrategy.value.selectedStrategy.value;
  },
  set (value) {
    contributionAndStrategy.value.selectedStrategy.value = value;
  },
});

const showStrategySelection = computed(() => {
  const investmentStrategy = contributionAndStrategy.value.investmentRiskCategory.value;
  // do not show for investment strategy E or D
  if (investmentStrategy === 'E' || investmentStrategy === 'D') {
    return false;
  }

  return isQuestionnaireProcessed.value || selectCustomStrategy.value;
});

watch(showStrategySelection, (val) => {
  if (!val) {
    selectedStrategy.value = null;
  }
});

const transferExistingRetirementSavings = computed({
  get () {
    return contractSettings.value.transferExistingRetirementSavings.value;
  },
  set (value) {
    contractSettings.value.transferExistingRetirementSavings.value = value;
  },
});

const currentRiskCategoryDescription = computed(() => {
  const riskCategory = contributionAndStrategy.value.investmentRiskCategory.value;
  if (!riskCategory) {
    return null;
  }

  return getCategoryDescription(riskCategory, transferExistingRetirementSavings.value);
});

const fundCombination = computed({
  get () {
    return contributionAndStrategy.value.fundCombination.value;
  },
  set (value) {
    contributionAndStrategy.value.fundCombination.value = value;
  },
});

const isFundCombinationComplete = computed(() => {
  const sum = Object.values(fundCombination.value).reduce((accumulator, current) => accumulator + current, 0);

  return sum === 100;
});

const canBeSubmitted = computed(() => {
  if (selectedStrategy.value === 'customFundCombination') {
    return isFundCombinationComplete.value;
  }

  return selectedStrategy.value !== null;
});

const currentContractTransferType = computed({
  get () {
    return contractSettings.value.currentContract.type.value;
  },
  set (value) {
    contractSettings.value.currentContract.type.value = value;
  },
});

const currentContractCompanyName = computed({
  get () {
    return contractSettings.value.currentContract.companyName.value;
  },
  set (value) {
    contractSettings.value.currentContract.companyName.value = value;
  },
});

const contributionGroup = computed({
  get () {
    return contributionAndStrategy.value.contributionGroup.value;
  },
  set (value) {
    contributionAndStrategy.value.contributionGroup.value = value;
  },
});

const showQuestionnaire = computed({
  get () {
    return contributionAndStrategy.value.showQuestionnaire.value;
  },
  set (value) {
    contributionAndStrategy.value.showQuestionnaire.value = value;
  },
});

function formatDate (date: Date) {
  const dateToFormat = new Date(date);
  return format(dateToFormat, dateFormat.value);
}

function setCurrentContractEndDate () {
  if (currentContractTransferType.value === null) {
    contractSettings.value.currentContract.contractEndsAt.value = null;
  } else {
    const today = new Date();
    const addedMonths = currentContractTransferType.value === 'transformFund' ? 2 : 1;
    const startDate = addMonths(today, addedMonths);

    if (!isFromRequest.value) {
      contractSettings.value.currentContract.contractEndsAt.value = format(lastDayOfMonth(startDate), 'yyyy-MM-dd');
    }
  }
}

function setTotalPointsFromStatus () {
  if ((status.value.isKo || status.value.unansweredQuestions === 0)
    && totalPoints.value === null) {
    totalPoints.value = calculateQuestionnairePoints(status.value.totalPoints, clientAge.value);
  }
}

function setDefaultStrategy (transferCurrentContract: boolean) {
  if (selectedStrategy.value !== null) {
    return;
  }

  if (transferCurrentContract && selectedStrategy.value === 'lifeCycle') {
    selectedStrategy.value = 'reinvestment';
  }

  if (!transferCurrentContract && selectedStrategy.value === 'reinvestment') {
    selectedStrategy.value = 'lifeCycle';
  }
}

function setContributionGroup () {
  switch (contributionAndStrategy.value.contributionAmount.value) {
    case 0:
      contributionGroup.value = 'option-1';
      break;
    case 500:
      contributionGroup.value = 'option-500';
      break;
    case 1700:
      contributionGroup.value = 'option-1000';
      break;
    case 5700:
      contributionGroup.value = 'option-5000';
      break;
    default:
      contributionGroup.value = 'option-custom';
  }
}

function setShowQuestionnaire () {
  if (!selectCustomStrategy.value) {
    showQuestionnaire.value = !isQuestionnaireProcessed.value;
  } else {
    showQuestionnaire.value = false;
  }
}

function onCustomStrategyClick () {
  selectedStrategy.value = null;
  selectCustomStrategy.value = true;
  showQuestionnaire.value = true;
  showCustomStrategyModal.value = false;
}

function handleFillQuestionnaireAgain () {
  showQuestionnaire.value = true;
  resetAnswer(1, (questionId, _answer) => {
    const questionnaire = contributionAndStrategy.value.questionnaire;
    const questionKey = `question${questionId}` as keyof typeof questionnaire;
    const questionAnswer = questionnaire[questionKey];

    questionAnswer.answer.value = null;
    questionAnswer.answer.errors = [];
    questionAnswer.openAnswer.value = '';
    questionAnswer.openAnswer.errors = [];
    questionAnswer.category.value = null;
  });

  contributionAndStrategy.value.investmentRiskCategory.value = null;
  contributionAndStrategy.value.selectedStrategy.value = null;
  contributionAndStrategy.value.selectCustomStrategy.value = false;
  contributionAndStrategy.value.totalPoints.value = null;
  contributionAndStrategy.value.recommendedStrategies.value = [];
}

async function submitStep () {
  const { contractUuid } = route.params;

  try {
    await validateFields({
      contractUuid,
      throwOnErrors: true,
      fieldPaths: [
        ...contributionAndStrategyFields,
        ...currentContractFields,
      ],
    });

    await router.push({
      params: {
        contractUuid,
      },
      name: 'distribution.contractSettings',
    });

    gtm.onStepSubmit('vyse-prispevku-a-strategie');
  } catch (e) {
    scrollToError();

    console.warn(`There was a validation contractSettingsError: ${e}`);
  } finally {
    scrollToError();
  }
}

onMounted(() => {
  setTotalPointsFromStatus();
  setContributionGroup();
  setShowQuestionnaire();

  if (isFromTransfer.value) {
    transferExistingRetirementSavings.value = true;
    currentContractTransferType.value = 'transformFund';
  }

  setDefaultStrategy(transferExistingRetirementSavings.value);
});

watch(transferExistingRetirementSavings, (newValue) => {
  if (newValue && !isFromTransfer.value) {
    currentContractTransferType.value = 'pensionCompany';
  } else if (!newValue && !isFromTransfer.value) {
    currentContractTransferType.value = null;
  }

  handleFillQuestionnaireAgain();
  store.contractSettings.contractCreationDate.value = null;

  setDefaultStrategy(newValue);
});

watch(selectCustomStrategy, async (val) => {
  const { contractUuid } = route.params;

  if (val) {
    showQuestionnaire.value = false;
  } else {
    selectedStrategy.value = null;
  }

  await validateFields({
    contractUuid,
    throwOnErrors: false,
    fields: [
      {
        value: val,
        path: 'contributionAndStrategy.selectCustomStrategy',
      },
    ],
  });
});

watch (currentContractCompanyName, (newVal) => {
  if (newVal !== 'choose') {
    contractSettings.value.currentContract.companyName.errors = [];
  }
});

watch(fundCombination, () => {
  const { contractUuid } = route.params;

  updateContract({
    state: store.$state,
    contractUuid: contractUuid as string,
  });
}, {
  deep: true,
});

function calculateAndSetPoints () {
  const basePoints = status.value.totalPoints;
  const age = clientAge.value;
  const totalPointsWithAge = calculateQuestionnairePoints(basePoints, age);

  if (status.value.unansweredQuestions === 0 || status.value.isKo) {
    contributionAndStrategy.value.totalPoints.value = totalPointsWithAge;
  }

  return totalPointsWithAge;
}

function handleQuestionnairePoints () {
  const totalPoints = calculateAndSetPoints();
  updateQuestionnaireUI();

  if (status.value.unansweredQuestions === 0 || status.value.isKo) {
    showQuestionnaire.value = false;
  }

  return totalPoints;
}

const handleValidateContributionFields = () => {
  if (!questions.value) {
    console.error('Questions not loaded');
    return;
  }

  const totalPoints = handleQuestionnairePoints();

  if (totalPoints < 0) {
    contributionAndStrategy.value.investmentRiskCategory.value = 'A';
    return;
  }

  const question7 = questions.value.find((q) => q.id === 7);
  if (question7 && question7.answer !== 'b') {
    contributionAndStrategy.value.investmentRiskCategory.value = 'A';
    return;
  }

  const REQUIRED_QUESTION_IDS = [8, 9, 10] as const;

  try {
    const [question8, question9, question10] = REQUIRED_QUESTION_IDS.map((id) => {
      const question = questions.value.find((q) => q.id === id);

      if (!question) {
        console.error(`Missing required question ${id}`);
        throw new Error(`Missing required question ${id}`);
      }

      return question;
    });

    // Ensure categories exist before using them
    if (!question8?.category || !question9?.category || !question10?.category) {
      console.error('Missing categories for questions');
      return;
    }

    processMatrixResults(
      question8 as Question,
      question9 as Question,
      question10 as Question,
      (_matrix89Result, matrix10Result) => {
        contributionAndStrategy.value.investmentRiskCategory.value = matrix10Result;
      },
    );
  } catch (error) {
    console.error('Error processing matrix results:', error);
  }
};

function setAnswer (question: Question, answer: string | string[] | null) {
  const questionnaire = contributionAndStrategy.value.questionnaire;
  const questionKey = `question${question.id}` as keyof typeof questionnaire;
  const questionAnswer = questionnaire[questionKey];

  let result;

  if (question.type === 'single') {
    result = handleSingleChoice(question, answer as string | null);
  } else {
    result = handleMultipleChoice(
      question,
      answer as string,
      questionAnswer.answer.value as string[] | null,
    );
  }

  if (result.answer !== undefined) {
    questionAnswer.answer.value = result.answer;
  }

  if (result.category !== undefined) {
    questionAnswer.category.value = result.category;
  }

  // Reset open answer if needed
  if (result.answer === null || (Array.isArray(result.answer) && result.answer.length === 0)) {
    questionAnswer.openAnswer.value = '';
  }

  // Reset strategy selection when answers change
  contributionAndStrategy.value.selectedStrategy.value = null;
  contributionAndStrategy.value.selectCustomStrategy.value = false;
}

function setOpenAnswer (question: Question, answer: string | number) {
  const questionnaire = contributionAndStrategy.value.questionnaire;
  const questionKey = `question${question.id}` as keyof typeof questionnaire;
  const questionAnswer = questionnaire[questionKey];

  questionAnswer.openAnswer.value = String(answer);
}

const syncQuestionnaireToServer = useDebounceFn(async () => {
  const { contractUuid } = route.params;

  try {
    await updateContract({
      state: store.$state,
      contractUuid: contractUuid as string,
    });
  } catch (error) {
    console.error('Error sending answer to server:', error);
  }
}, 500);

const recommendedStrategies = computed(() => {
  const riskCategory = contributionAndStrategy.value.investmentRiskCategory.value;
  if (!riskCategory) {
    return [];
  }

  return getStrategiesForCategory(riskCategory, contractSettings.value.transferExistingRetirementSavings.value);
});

watch(recommendedStrategies, async (newVal) => {
  if (newVal) {
    contributionAndStrategy.value.recommendedStrategies.value = newVal;

    await syncQuestionnaireToServer();
  }
});

const excludeConservativeFund = computed({
  get () {
    return contributionAndStrategy.value.excludeConservativeFund.value;
  },
  set (value) {
    contributionAndStrategy.value.excludeConservativeFund.value = value;
  },
});

watch(birthDay, () => {
  updatePreferConservativeFund();
}, {
  immediate: true,
});

const questionnaireHasErrors = ref(false);
const isValidating = ref(false);

const validateQuestionnaireFields = async (isKo = false) => {
  if (isValidating.value) {
    return false;
  }

  if (isKo) {
    updateQuestionnaireUI();
  }

  isValidating.value = true;
  const { contractUuid } = route.params;

  try {
    const fieldPaths = isKo
      ? [
          'contributionAndStrategy.questionnaire.question1.openAnswer',
          'contributionAndStrategy.questionnaire.question1.answer',
          'contributionAndStrategy.questionnaire.question2.answer',
        ]
      : [
          'contributionAndStrategy.questionnaire.question1.openAnswer',
          'contributionAndStrategy.questionnaire.question1.answer',
          'contributionAndStrategy.questionnaire.question2.answer',
          'contributionAndStrategy.questionnaire.question3.answer',
          'contributionAndStrategy.questionnaire.question4.answer',
          'contributionAndStrategy.questionnaire.question5.answer',
          'contributionAndStrategy.questionnaire.question6.answer',
          'contributionAndStrategy.questionnaire.question7.answer',
          'contributionAndStrategy.questionnaire.question8.answer',
          'contributionAndStrategy.questionnaire.question9.answer',
          'contributionAndStrategy.questionnaire.question10.answer',
        ];

    await validateFields({
      contractUuid,
      throwOnErrors: true,
      fieldPaths,
    });

    return true;
  } catch {
    questionnaireHasErrors.value = true;
    const questionnaire = contributionAndStrategy.value.questionnaire;
    const question1Key = 'question1' as keyof typeof questionnaire;
    const question1 = questionnaire[question1Key];

    if (question1.answer.errors.length > 0 || question1.openAnswer.errors.length > 0) {
      showQuestionnaire.value = true;

      if (isKo) {
        // Reset from question 2 if question 1 has errors
        resetAnswer(2, (questionId, _answer) => {
          const questionKey = `question${questionId}` as keyof typeof questionnaire;
          const questionAnswer = questionnaire[questionKey];

          questionAnswer.answer.value = null;
          questionAnswer.answer.errors = [];
          questionAnswer.openAnswer.value = '';
          questionAnswer.openAnswer.errors = [];
          questionAnswer.category.value = null;
        });
      }
    }

    return false;
  } finally {
    isValidating.value = false;
  }
};

const debouncedValidation = useDebounceFn(async (isKo: boolean) => {
  const isValid = await validateQuestionnaireFields(isKo);
  if (isValid) {
    handleQuestionnairePoints();
  }

  return isValid;
}, 300);

watch([lastAnsweredQuestion, status], async ([newLastAnsweredQuestion, newStatus]) => {
  if (newLastAnsweredQuestion === 2 && contributionAndStrategy.value.preferConservativeFund.value && !excludeConservativeFund.value) {
    // Validate before setting the strategy
    const isValid = await debouncedValidation(true);
    if (!isValid) {
      return;
    }

    forceKoAfterQuestion2.value = true;
    contributionAndStrategy.value.investmentRiskCategory.value = 'E';
    handleQuestionnairePoints();
  }

  if (newStatus.isKo) {
    const isValid = await debouncedValidation(true);
    if (!isValid) {
      return;
    }

    // Check if it's a forced KO due to conservative fund conditions
    if (contributionAndStrategy.value.preferConservativeFund.value && !excludeConservativeFund.value) {
      contributionAndStrategy.value.investmentRiskCategory.value = 'E';
    } else if (!contributionAndStrategy.value.preferConservativeFund.value || excludeConservativeFund.value) {
      contributionAndStrategy.value.investmentRiskCategory.value = 'D';
    }
  }
});

watch(excludeConservativeFund, (newValue, oldValue) => {
  if (oldValue === undefined) {
    return;
  }

  if (oldValue !== null) {
    handleFillQuestionnaireAgain();
  }

  if (contributionAndStrategy.value.preferConservativeFund.value && !newValue) {
    forceKoAfterQuestion2.value = true;
    contributionAndStrategy.value.investmentRiskCategory.value = 'E';
    handleQuestionnairePoints();
  } else {
    forceKoAfterQuestion2.value = false;
  }
});

const handleAnswerUpdate = (question: Question, answer: string | string[] | null) => {
  setAnswer(question, answer);
};

const handleOpenAnswerUpdate = (question: Question, answer: string | number) => {
  setOpenAnswer(question, answer);
};

const getQuestionErrors = (questionId: number) => {
  const questionnaireKey = `question${questionId}` as keyof typeof contributionAndStrategy.value.questionnaire;

  return contributionAndStrategy.value.questionnaire[questionnaireKey].answer.errors;
};

const getQuestionOpenAnswerErrors = (questionId: number) => {
  const questionnaire = contributionAndStrategy.value.questionnaire;
  const questionnaireKey = `question${questionId}` as keyof typeof questionnaire;
  const answer = questionnaire[questionnaireKey].answer.value;
  const openAnswerChoice = 'd';

  if (answer !== null && answer.includes(openAnswerChoice)) {
    return questionnaire[questionnaireKey].openAnswer.errors;
  }

  return [];
};

watch(() => contributionAndStrategy.value.preferConservativeFund.value, (newValue) => {
  if (excludeConservativeFund.value === true) {
    return;
  }

  if (newValue && excludeConservativeFund.value === false) {
    return;
  }

  // Set default values:
  // - For preferConservativeFund true: exclude = false
  // - For preferConservativeFund false: exclude = null
  excludeConservativeFund.value = newValue ? false : null;
}, {
  immediate: true,
});

watch(currentContractTransferType, (newValue) => {
  if (newValue) {
    setCurrentContractEndDate();
  }
});
</script>

<template>
  <div dusk="investment-questionnaire">
    <DistributionStepper current-step="contributionAndStrategy" />

    <div class="container--sm mt-20">
      <h2 dusk="contribution-heading">
        Výše příspěvku, který si bude klient měsíčně platit
      </h2>

      <ServerValidatedFormField
        v-slot="{ value, input, errors }"
        namespace="distribution"
        field-path="contributionAndStrategy.contributionAmount"
        class="mb-40"
        :validate-on-input="true"
        :debounce="0"
        @validate="validateField"
      >
        <ContributionSelection
          v-model:contribution-group="contributionGroup"
          class="mb-30"
          :model-value="value"
          :error="errors.length > 0 ? errors[0] : ''"
          :is-for-distributor="true"
          :is-granted-pension="legalRequirements.grantedPension.value"
          @update:model-value="input"
        />
      </ServerValidatedFormField>

      <hr class="my-30">

      <div
        v-if="!isFromRequest"
        class="mb-40"
        dusk="current-contract"
      >
        <h3 dusk="existing-contract">
          Už si na penzi spořím
        </h3>

        <ServerValidatedFormField
          v-slot="{ value, input }"
          namespace="distribution"
          field-path="contractSettings.transferExistingRetirementSavings"
          :validate-on-input="true"
          :debounce="0"
          @validate="validateField"
        >
          <LitCheckbox
            class="mb-20"
            dusk="pensionCompany"
            :model-value="value"
            @update:model-value="input"
          >
            Už si spořím na jiné smlouvě
          </LitCheckbox>
        </ServerValidatedFormField>

        <div
          v-if="transferExistingRetirementSavings"
          class="py-20"
          dusk="current-contract"
        >
          <ServerValidatedFormField
            v-slot="{ value, input, errors }"
            namespace="distribution"
            field-path="contractSettings.currentContract.companyName"
            :debounce="0"
            @validate="validateField"
          >
            <LitSelect
              label="Stávající penzijní společnost"
              dusk="current-contract-company"
              class="mb-20"
              searchable
              :options="currentPensionCompanyOptions"
              :model-value="value"
              :error="errors.length > 0 ? errors[0] : ''"
              @update:model-value="input"
            />
          </ServerValidatedFormField>

          <ServerValidatedFormField
            v-slot="{ value, input, errors, validate }"
            namespace="distribution"
            field-path="contractSettings.currentContract.contractNumber"
            @validate="validateField"
          >
            <LitInput
              class="mb-20"
              name="currentContractNumber"
              dusk="current-contract-number"
              placeholder="Např. 5875737291"
              label="Číslo současné smlouvy"
              :model-value="value"
              :errors="errors"
              @update:model-value="input"
              @blur="validate($event.target.value)"
            />
          </ServerValidatedFormField>

          <ServerValidatedFormField
            v-slot="{ value, input, errors }"
            namespace="distribution"
            field-path="contractSettings.currentContract.type"
            :validate-on-input="true"
            :debounce="0"
            @validate="validateField"
          >
            <LitRadio
              class="mb-20"
              label="Typ současné smlouvy"
              dusk="current-contract-type"
              name="current-contract-type"
              :options="contractTypeOptions"
              :model-value="value"
              :error="errors.length > 0 ? errors[0] : ''"
              @update:model-value="input"
            />
          </ServerValidatedFormField>

          <ServerValidatedFormField
            v-slot="{ value, input, errors, validate }"
            namespace="distribution"
            field-path="contractSettings.currentContract.contractEndsAt"
            :validate-on-input="true"
            :debounce="0"
            @validate="validateField"
          >
            <LitInput
              class="mb-20"
              dusk="current-contract-end"
              label="Orientační datum ukončení současné smlouvy"
              disabled
              :model-value="value === null ? '' : formatDate(value)"
              :errors="errors"
              @update:model-value="input"
              @blur="validate($event.target.value)"
            />
          </ServerValidatedFormField>
        </div>
        <hr class="my-30">
      </div>

      <div>
        <LitAlert v-if="contributionAndStrategy.preferConservativeFund.value && excludeConservativeFund !== null">
          <p>
            Vážený kliente,
            <br><br>
            ze zákona nám vyplývá povinnost umístit Vaše prostředky nejpozději 5 let před dosažením důchodového věku do povinného konzervativního fondu.
            <br><br>
            V případě, že s umístěním Vašich prostředků do povinného konzervativního fondu nesouhlasíte, vystavujete se riziku, že v případě nepříznivého vývoje na trzích může dojít k významným ztrátám, které mohou ovlivnit hodnotu úspor těsně před důchodem.
          </p>

          <ServerValidatedFormField
            v-slot="{ value, input, errors }"
            namespace="distribution"
            field-path="contributionAndStrategy.excludeConservativeFund"
            :validate-on-input="true"
            @validate="validateField"
          >
            <LitCheckbox
              :model-value="value"
              :error="errors.length > 0 ? errors[0] : ''"
              class="mt-20"
              @update:model-value="input"
            >
              Žádám, aby mé prostředky nebyly umístěny do povinného konzervativní fondu a přeji si doporučit jinou strategii spoření.
            </LitCheckbox>
          </ServerValidatedFormField>
        </LitAlert>

        <h3>
          Nastavte s účastníkem nejvhodnější strategii spoření v doplňkovém penzijním spoření („penzijko“)
        </h3>
        <p>
          Abychom mohli doporučit tu nejvhodnější strategii spoření, potřebujeme vědět, jaké jsou účastníkovy cíle v rámci penzijka, jak se orientuje ve světě financí a investic a jaký je jeho vztah k investičnímu riziku.
        </p>
        <p>
          Prosíme, vyplňte s účastníkem penzijní dotazník.
        </p>

        <StepperVertical>
          <StepperVerticalItem :class="{ disabled: !showStrategySelection }">
            <template #icon>
              <span class="step__icon--number">
                1
              </span>
            </template>

            <template #label>
              Penzijní dotazník
            </template>

            <template #content>
              <LitAlert
                v-if="isQuestionnaireProcessed"
                dusk="questionnaire-successful-alert"
                alert-type="success"
                class="mb-20"
              >
                Děkujeme za vyplnění penzijního dotazníku.
              </LitAlert>

              <div
                v-show="isQuestionnaireProcessed"
                dusk="toggle-show-questionnaire"
                class="btn-simple mb-20"
                @click="handleFillQuestionnaireAgain"
              >
                <RefreshCw :size="12" class="mr-5" />

                <span>
                  Vyplnit dotazník znovu
                </span>
              </div>

              <div v-show="!loading && showQuestionnaire">
                <QuestionnaireForm
                  :questions="(questions as Question[])"
                  :is-question-visible="isQuestionVisible"
                  @update:answer="handleAnswerUpdate"
                  @update:open-answer="handleOpenAnswerUpdate"
                >
                  <template #singleOption="{ question, updateAnswer }">
                    <ServerValidatedFormField
                      v-slot="{ value, errors, input }"
                      namespace="distribution"
                      :default-value="question.answer"
                      :field-path="`contributionAndStrategy.questionnaire.question${question.id}.answer`"
                      :validate-on-input="true"
                      @validate="validateField"
                    >
                      <QuestionnaireRadioGroup
                        v-if="question.viewStyle && question.viewStyle === 'radioGroup'"
                        :model-value="typeof question.answer === 'string' ? question.answer : ''"
                        :options="(question.options as any)"
                        :error="errors.length > 0 ? errors[0] : ''"
                        @update:model-value="updateAnswer(question, $event); input($event)"
                      />
                      <LitRadio
                        v-else
                        :name="`question${question.id}Options`"
                        :options="question.options"
                        :model-value="value"
                        :error="errors.length > 0 ? errors[0] : ''"
                        @update:model-value="updateAnswer(question, $event); input($event)"
                      />
                    </ServerValidatedFormField>
                  </template>

                  <template #multipleOption="{ question, option, updateAnswer, disabled }">
                    <ServerValidatedFormField
                      v-slot="{ errors, input }"
                      namespace="distribution"
                      :default-value="question.answer"
                      :field-path="`contributionAndStrategy.questionnaire.question${question.id}`"
                      :validate-on-input="true"
                      @validate="validateField"
                    >
                      <LitCheckbox
                        :label="option.label"
                        :error="errors.length > 0 ? errors[0] : ''"
                        :model-value="question.answer?.includes(option.value)"
                        rounded
                        :is-disabled="disabled"
                        @update:model-value="updateAnswer(question, option.value); input($event)"
                      >
                        {{ option.label }}
                      </LitCheckbox>
                    </ServerValidatedFormField>
                  </template>

                  <template #additionalContent="{ question }">
                    <div
                      v-if="getQuestionErrors(question.id).length > 0"
                      class="text-danger pt-10"
                    >
                      {{ getQuestionErrors(question.id)[0] }}
                    </div>

                    <div
                      v-if="getQuestionOpenAnswerErrors(question.id).length > 0"
                      class="text-danger pt-10"
                    >
                      {{ getQuestionOpenAnswerErrors(question.id)[0] }}
                    </div>
                  </template>
                </QuestionnaireForm>

                <div
                  v-if="!status.isKo && lastAnsweredQuestion >= 2"
                  class="flex flex-center"
                >
                  <LitButton
                    class="mt-15 mb-15"
                    dusk="evaluate-questionnaire"
                    :disabled="status.unansweredQuestions !== 0"
                    @click="handleValidateContributionFields"
                  >
                    Vyhodnotit penzijní dotazník
                  </LitButton>
                </div>
              </div>

              <QuestionnaireLoader v-if="loading" />
            </template>
          </StepperVerticalItem>

          <StepperVerticalItem :class="{ disabled: !showStrategySelection }">
            <template #icon>
              <span class="step__icon--number">
                2
              </span>
            </template>

            <template #label>
              Vyberte si strategii
            </template>

            <template #content>
              <LitAlert
                v-if="!showStrategySelection && !isQuestionnaireProcessed"
                dusk="strategy-selection-alert"
              >
                Navrhované strategie spoření se zobrazí po vyplnění Penzijního dotazníku.
              </LitAlert>

              <div v-if="isQuestionnaireProcessed && selectCustomStrategy">
                <p class="mb-30">
                  Výběrem vlastní strategie spoření se klient vystavuje riziku,
                  že zvolená strategie spoření nebude odpovídat
                  jeho cílům, odborným znalostem nebo zkušenostem potřebným
                  pro pochopení rizik souvisejících s investováním.
                </p>

                <ServerValidatedFormField
                  v-slot="{ value, input }"
                  namespace="distribution"
                  field-path="contributionAndStrategy.selectedStrategy"
                  :debounce="0"
                  :validate-on-input="true"
                  @validate="validateField"
                >
                  <CustomStrategySelection
                    v-model:fund-combination="fundCombination"
                    :model-value="value"
                    :is-custom-strategy="selectCustomStrategy"
                    :prefer-conservative-fund="contributionAndStrategy.preferConservativeFund.value"
                    @update:model-value="input"
                  />
                </ServerValidatedFormField>
              </div>

              <div v-if="isQuestionnaireProcessed && !selectCustomStrategy">
                <LitAlert
                  v-if="status.isKo && !status.isKoFromQuestion2"
                  class="mb-30"
                >
                  Vážená klientko / Vážený kliente,
                  <br><br>
                  na základě vámi uvedených informací jsme vyhodnotili, že pro vás sjednání Doplňkového penzijního
                  spoření není vhodné. Jedná se o dlouhodobé spoření,
                  a proto vám nedoporučujeme smlouvu uzavřít.
                </LitAlert>
                <p v-else class="mb-30">
                  <span v-if="currentRiskCategoryDescription !== null">
                    {{ currentRiskCategoryDescription }}
                    <br><br>
                    Na základě vašich odpovědí vám doporučujeme níže uvedené strategie.
                  </span>
                </p>

                <StrategySelection
                  dusk="strategy-selection"
                  :model-value="contributionAndStrategy.selectedStrategy.value"
                  :recommended-strategies="recommendedStrategies"
                  @update:model-value="contributionAndStrategy.selectedStrategy.value = $event"
                />
              </div>

              <div v-show="showStrategySelection && isQuestionnaireProcessed" class="text-center mt-20">
                <button
                  v-if="!selectCustomStrategy"
                  class="btn-simple"
                  type="button"
                  @click="showCustomStrategyModal = true"
                >
                  Případně vyberte vlastní strategii
                </button>
                <button
                  v-else
                  class="btn-simple"
                  type="button"
                  @click="selectCustomStrategy = false"
                >
                  Zpět na doporučené strategie
                </button>
              </div>
            </template>
          </StepperVerticalItem>
        </StepperVertical>
      </div>
    </div>

    <div class="container mt-30">
      <hr>
    </div>

    <StepButtons
      namespace="distribution"
      previous-step="legalRequirements"
      :disabled="!canBeSubmitted"
      @submit="submitStep"
    />

    <CustomStrategyWarningModal
      :model-value="showCustomStrategyModal"
      @close="showCustomStrategyModal = false"
      @click="onCustomStrategyClick"
    />
  </div>
</template>

<style lang="scss" scoped>
@import '@sass/tools/variables';
@import '@sass/tools/mixins';
@import '@sass/tools/functions';

.btn-outline {
  margin-left: 5px;
}

.btn-green {
  margin-right: 5px;
}
</style>
