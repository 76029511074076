export default {
  lifeCycle: {
    title: 'Strategie životního cyklu',
    titleFull: 'Strategie životního cyklu',
    slug: 'lifeCycle',
    content: {
      description: {
        short: `Automaticky převádí vaše úspory mezi jednotlivými fondy v závislosti na vašem věku.`,
        long: `Strategie životního cyklu automaticky převádí vaše prostředky mezi
              jednotlivými účastnickými fondy v závislosti na vašem věku. V mladším věku,
              kdy vám zbývá do důchodu ještě dlouhá doba, jsou vaše prostředky
              investovány do dynamického typu fondu s potenciálně vyšším výnosem a
              delším investičním horizontem. S blížícím se důchodovým věkem se
              pak postupně zvyšuje zastoupení konzervativního typu fondu,
              který má kratší investiční horizont.`,
      },
      detailLink: 'Více informací o strategii životního cyklu',
    },
    label: 'Doporučujeme',
    labelType: 'warning',
    labelIcon: 'tag-star_white',
    dusk: 'life-cycle',
    typeText: 'Vyvážený',
    typeIconName: 'bar-2',
  },

  pension: {
    title: 'Pro penzi',
    titleFull: 'ČSOB pro penzi účastnický fond',
    slug: 'pension',
    content: {
      description: {
        short: `Fond s konzervativnějším přístupem ale vyšším potenciálem zhodnocení díky akciové složce.
              Ta může být až 30 %.`,
        long: `ČSOB pro penzi účastnický fond investuje zejména do dluhopisů a
              nástrojů peněžního trhu. Díky akciové složce, která může být až 30 %,
              však nabízí možnost zajímavějšího zhodnocení
              než ČSOB povinný konzervativní účastnický fond.`,
      },
      detailLink: 'Více informací o fondu pro penzi',
    },
    dusk: 'fund-pension',
    label: null,
    labelType: null,
    labelIcon: null,
    typeText: 'Konzervativní',
    typeIconName: 'bar-1',
  },

  conservative: {
    title: 'Povinný konzervativní fond',
    titleFull: 'ČSOB účastnický povinný konzervativní fond',
    slug: 'conservative',
    content: {
      description: {
        short: `Fond investuje zejména do dluhopisů a nástrojů peněžního trhu,
        proto nabízí možnost nižšího výnosu s nízkou mírou rizika.`,
        long: `ČSOB účastnický povinný konzervativní fond je ideální volba,
              když chcete spořit krátkou dobu nebo nemáte s investováním zkušenosti.
              Fond investuje zejména do dluhopisů a nástrojů peněžního trhu,
              proto je jeho výnos spolehlivý a riziko velmi nízké.`,
      },
      detailLink: 'Více informací o konzervativním fondu',
    },
    dusk: 'fund-conservative',
    label: null,
    labelType: null,
    labelIcon: null,
    typeText: 'Konzervativní',
    typeIconName: 'bar-1',
  },

  balanced: {
    title: 'Vyvážený fond',
    titleFull: 'ČSOB vyvážený účastnický fond',
    slug: 'balanced',
    content: {
      description: {
        short: `Fond investuje vyváženě zejména do dluhopisů a akcií,
        a to bez ohledu na věk klienta. Akciová složka může být až 60 %.`,
        long: `ČSOB vyvážený účastnický fond je vhodný pro investory, kteří chtějí vyšší výnos a nevadí
                jim vyšší riziko kolísání hodnoty investice než u konzervativních fondů. Fond investuje
                hlavně do dluhopisů a akcií. Akciová složka bývá max. 60 %.`,
      },
      detailLink: 'Více informací o vyváženém fondu',
    },
    dusk: 'fund-balanced',
    label: null,
    labelType: null,
    labelIcon: null,
    typeText: 'Vyvážený',
    typeIconName: 'bar-2',
  },

  dynamic: {
    title: 'Dynamický fond',
    titleFull: 'ČSOB dynamický účastnický fond',
    slug: 'dynamic',
    content: {
      description: {
        short: `Fond investuje zejména do akcií, podílových fondů a dluhopisů. Akciová složka může být až 100 %.`,
        long: `ČSOB dynamický účastnický fond je vhodný pro investory,
                kteří chtějí maximální růst a nevadí jim vyšší riziko kolísání hodnoty investice.
                Fond investuje zejména do akcií, podílových fondů a dluhopisů - akciová složka může být až 100%.`,
      },
      detailLink: 'Více informací o dynamickém fondu',
    },
    dusk: 'fund-dynamic',
    label: null,
    labelType: null,
    labelIcon: null,
    typeText: 'Dynamický',
    typeIconName: 'bar-3',
  },

  reinvestment: {
    title: 'Postupná reinvestice',
    titleFull: 'Postupná reinvestice',
    slug: 'reinvestment',
    content:
    {
      description: {
        short: `Omezuje významné výkyvy hodnoty investice při převodu smlouvy ze staršího
        typu penzijního spoření nebo z jiné penzijní společnosti.`,
        long: `Strategie je vhodná v případě většího jednorázového vkladu při sjednání smlouvy nebo při
                    převodu prostředků z penzijního připojištění nebo z jiné penzijní společnosti.
                    Vaše prostředky jsou nejprve zainvestovány do fondu Pro penzi, který je fondem konzervativního
                    typu s nízkou pravděpodobností významného výkyvu hodnoty investice. Poté jsou postupně po dobu
                    tří let převáděny do příslušné fáze Strategie životního cyklu dle vašeho věku.`,
      },
      detailLink: 'Více informací o postupné reinvestici',
    },
    dusk: 'fund-reinvestment',
    label: null,
    labelType: null,
    labelIcon: null,
    typeText: 'Konzervativní',
    typeIconName: 'bar-1',
  },

  preferredConservativeFund: {
    title: 'Povinný konzervativní fond',
    titleFull: 'ČSOB účastnický povinný konzervativní fond',
    slug: 'conservative',
    content:
    {
      description: {
        short: `Zákon nám ukládá, abychom klientům, kterým se blíží důchodový věk
        nebo již důchodového věku dosáhli, nabídli jako nejvhodnější strategii
        spoření povinný konzervativní fond. Vaše úspory tak nebudou nepříznivě
        ovlivňovány výkyvy na akciových trzích. Umístění svých prostředků do povinného
        konzervativního fondu můžete odmítnout a vybrat si z naší nabídky jiný fond,
        který bude lépe vyhovovat vašim potřebám.`,
        long: `Zákon nám ukládá, abychom klientům, kterým se blíží důchodový věk
        nebo již důchodového věku dosáhli, nabídli jako nejvhodnější strategii
        spoření povinný konzervativní fond. Vaše úspory tak nebudou nepříznivě
        ovlivňovány výkyvy na akciových trzích. Umístění svých prostředků do povinného
        konzervativního fondu můžete odmítnout a vybrat si z naší nabídky jiný fond,
        který bude lépe vyhovovat vašim potřebám.`,
      },
      detailLink: 'Více informací o konzervativním fondu',
    },
    dusk: 'fund-conservative',
    label: 'Doporučujeme',
    labelType: 'warning',
    labelIcon: 'tag-star_white',
    typeText: 'Konzervativní',
    typeIconName: 'bar-1',
  },

  esg: {
    title: 'Dynamický zodpovědný fond',
    titleFull: 'ČSOB dynamický zodpovědný účastnický fond',
    slug: 'esg',
    content: {
      description: {
        short: `Fond investuje zejména do akcií, podílových fondů a dluhopisů s ohledem na životní
        prostředí a sociální odpovědnost. Akciová složka může být až 100 %.`,
        long: `ČSOB dynamický zodpovědný účastnický fond je vhodný pro investory s dlouhodobými investičními cíli,
        kteří očekávají vysoký výnos, ale nevadí jim vysoké riziko kolísání hodnoty investice.
        Fond má za cíl investovat část svých aktiv do ekonomických činností, které přispívají k dosažení
        environmentálních nebo sociálních cílů („Udržitelné investice"). Akciová složka může být až 100 %.`,
      },
      detailLink: 'Více informací o zodpovědném dynamickém fondu',
    },
    dusk: 'fund-esg',
    label: '',
    labelType: 'success',
    labelIcon: 'icon_leaf-white',
    typeText: 'Dynamický',
    typeIconName: 'bar-3',
  },
};
