<script setup lang="ts">
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';

import ClientData from '@/js/components/RequestChange/ClientData.vue';
import ChangeRequestStepper from '@/js/components/Steppers/ChangeRequestStepper.vue';

import gtm from '@/js/services/gtm';

import { useChangeRequestStore } from '@/js/stores/distibution/changeRequest';
import { scrollToError } from '@/js/utils';
import StepButtons from '@/js/views/common/StepButtons.vue';

const route = useRoute();
const router = useRouter();

const store = useChangeRequestStore();
const {
  changeSettings,
  beforeStart,
} = storeToRefs(store);
const { validateField, validateFields } = store;

const validating = ref(false);

async function handleSubmit () {
  const { contractUuid } = route.params;

  try {
    validating.value = true;

    await validateFields({
      contractUuid,
      throwOnErrors: true,
      fieldPaths: [
        'clientData.firstName',
        'clientData.lastName',
        'clientData.personalIdNumber',
        'clientData.contractNumber',
        'clientData.email',
        'clientData.permanentAddressArea',
        'clientData.birthDate',

        'clientData.birthCertificate.inBundle',
        'clientData.birthCertificate.year',
        'clientData.birthCertificate.page',
        'clientData.birthCertificate.serialNumber',

        'signer.type',
        'signer.firstName',
        'signer.lastName',
        'signer.personalIdNumber',
        'signer.permanentAddressArea',
        'signer.birthDate',

        'signer.document.type',
        'signer.document.number',
        'signer.document.validUntil',
        'signer.document.issuedBy',
        'signer.document.name',
      ],

      documentsSent: beforeStart.value.isTiedAgent.value as boolean,
    });

    await router.push({
      name: `changeRequest.${changeSettings.value.involvedForms.value[0]}`,
      params: {
        contractUuid: route.params.contractUuid,
      },
    });

    gtm.onStepSubmit(`changeRequest.${changeSettings.value.involvedForms.value[0]}`);
  } catch (e) {
    scrollToError();

    console.warn(`There was a validation changeRequest ${changeSettings.value.involvedForms.value[0]}: ${e}`);
  } finally {
    validating.value = false;
  }
}
</script>

<template>
  <div>
    <ChangeRequestStepper current-step="clientData" />

    <div class="container--sm mt-20">
      <ClientData
        :validate-fnc="validateField"
      />

      <hr class="my-30">

      <div class="flex flex-center">
        <StepButtons
          namespace="changeRequest"
          previous-step="beforeStart"
          @submit="handleSubmit"
        />
      </div>
    </div>
  </div>
</template>
