<script setup lang="ts">
import LitInput from '@/js/components/Base/LitInput.vue';
import type { ValidateStoreParamsFnc } from '@/js/stores/utils.ts';

interface Props {
  namespace?: string
  title?: string
  isFromCz: boolean
  validateFnc: ValidateStoreParamsFnc
}

const props = withDefaults(defineProps<Props>(), {
  namespace: 'changeRequest',
  title: 'Rodný list účastníka',
  isFromCz: true,
});
</script>

<template>
  <div>
    <h3>{{ props.title }}</h3>
    <div v-if="isFromCz">
      <ServerValidatedFormField
        v-slot="{ value, input, errors, validate }"
        :namespace="props.namespace"
        :validate-on-input="true"
        field-path="clientData.birthCertificate.inBundle"
        :debounce="0"
        @validate="validateFnc"
      >
        <LitInput
          class="mb-20"
          label="Ve svazku"
          name="inBundle"
          dusk="in-bundle"
          :model-value="value"
          :errors="errors"
          @update:model-value="input"
          @blur="validate($event.target.value)"
        />
      </ServerValidatedFormField>

      <ServerValidatedFormField
        v-slot="{ value, input, errors, validate }"
        :namespace="props.namespace"
        :validate-on-input="true"
        field-path="clientData.birthCertificate.year"
        :debounce="0"
        @validate="validateFnc"
      >
        <LitInput
          class="mb-20"
          label="Ročník"
          name="year"
          dusk="year"
          :model-value="value"
          :errors="errors"
          @update:model-value="input"
          @blur="validate($event.target.value)"
        />
      </ServerValidatedFormField>

      <ServerValidatedFormField
        v-slot="{ value, input, errors, validate }"
        :namespace="props.namespace"
        :validate-on-input="true"
        field-path="clientData.birthCertificate.page"
        :debounce="0"
        @validate="validateFnc"
      >
        <LitInput
          class="mb-20"
          label="Strana/list"
          name="page"
          dusk="page"
          :model-value="value"
          :errors="errors"
          @update:model-value="input"
          @blur="validate($event.target.value)"
        />
      </ServerValidatedFormField>

      <ServerValidatedFormField
        v-slot="{ value, input, errors, validate }"
        :namespace="props.namespace"
        :validate-on-input="true"
        field-path="clientData.birthCertificate.serialNumber"
        :debounce="0"
        @validate="validateFnc"
      >
        <LitInput
          class="mb-20"
          label="Pořadové číslo"
          name="seriallNumber"
          dusk="serial-number"
          :model-value="value"
          :errors="errors"
          @update:model-value="input"
          @blur="validate($event.target.value)"
        />
      </ServerValidatedFormField>
    </div>

    <div v-else>
      <ServerValidatedFormField
        v-slot="{ value, input, errors, validate }"
        :namespace="props.namespace"
        :validate-on-input="true"
        field-path="clientData.birthCertificate.number"
        :debounce="0"
        @validate="validateFnc"
      >
        <LitInput
          class="mb-20"
          label="Číslo rodného listu"
          name="number"
          dusk="number"
          :model-value="value"
          :errors="errors"
          @update:model-value="input"
          @blur="validate($event.target.value)"
        />
      </ServerValidatedFormField>
    </div>
  </div>
</template>
