import type { DocumentReturn, NullableString } from './types';
import type { StoreField } from '@/js/composables/useStoreField';
import { useStoreField } from '@/js/composables/useStoreField';

interface DistributorReturn {
  firstName: StoreField<string>
  lastName: StoreField<string>
  email: StoreField<string>
}

function createDistributor (firstName = '', lastName = '', email = ''): DistributorReturn {
  return {
    firstName: useStoreField(firstName),
    lastName: useStoreField(lastName),
    email: useStoreField(email),
  };
}

export interface BeforeStartReturn {
  s1: StoreField<string>
  s2: StoreField<string>
  s3: StoreField<string | null>
  isTiedAgent: StoreField<boolean>
  distributionName: StoreField<string>
  promoCode: StoreField<string | null>
  meetingAddress?: AddressField
  distributor: DistributorReturn
}

export function beforeStart ({
  s1 = '',
  s2 = '',
  s3 = '',
  isTiedAgent = false,
  distributionName = '',
  promoCode = '',
  firstName = '',
  lastName = '',
  email = '',
}): BeforeStartReturn {
  return {
    s1: useStoreField(s1),
    s2: useStoreField(s2),
    s3: useStoreField<NullableString>(s3),
    isTiedAgent: useStoreField<boolean>(isTiedAgent),
    distributionName: useStoreField<string>(distributionName),
    promoCode: useStoreField<NullableString>(promoCode),
    meetingAddress: addressField(),
    distributor: createDistributor(firstName, lastName, email),
  };
}

export interface AddressField {
  street: StoreField<NullableString>
  streetNumber: StoreField<NullableString>
  city: StoreField<NullableString>
  zip: StoreField<NullableString>
  country: StoreField<NullableString>
  query: StoreField<NullableString>
  editable: StoreField<boolean >
  setManually: StoreField<boolean>
}

export function addressField (editableValue = true): AddressField {
  return {
    street: useStoreField('', true, undefined, true),
    streetNumber: useStoreField('', true, undefined, true),
    city: useStoreField('', true, undefined, true),
    zip: useStoreField('', true, undefined, true),
    country: useStoreField('cz', true, undefined, true),
    //
    query: useStoreField('', true, undefined, true),
    editable: useStoreField(editableValue),
    setManually: useStoreField(false),
  };
}

export interface ContactInformationReturn {
  firstName: StoreField<string>
  lastName: StoreField<string>
  email: StoreField<null | string>
  phoneNumber: StoreField<string>
  phonePrefix?: StoreField<string>
}

export function contactInformation (firstName = '', lastName = '', email = '', phoneNumber = ''): ContactInformationReturn {
  return {
    firstName: useStoreField(firstName),
    lastName: useStoreField(lastName),
    email: useStoreField(email),
    phoneNumber: useStoreField(phoneNumber),
  };
}

export function personalDocument ({
  type = 'idCard',
  number = '',
  validUntil = '',
  issuedBy = '',
  name = '',
}): DocumentReturn {
  return {
    type: useStoreField(type),
    number: useStoreField(number),
    validUntil: useStoreField(validUntil),
    issuedBy: useStoreField(issuedBy),
    name: useStoreField<NullableString>(name),
  };
}

export function birthCertificate ({
  type = 'birthCertificate',
  inBundle = '',
  year = '',
  page = '',
  serialNumber = '',
  birthCertificateNumber = '',
}) {
  return {
    type: useStoreField(type),
    inBundle: useStoreField(inBundle),
    year: useStoreField(year),
    page: useStoreField(page),
    serialNumber: useStoreField(serialNumber),
    number: useStoreField(birthCertificateNumber),
  };
}

export function personalData ({
  titlePrefix = '',
  titleSuffix = '',
  firstName = '',
  lastName = '',
  personalIdNumber = '',
  citizenship = 'cz',
  countryOfBirth = 'cz',
  birthplace = '',
  specifyMailingAddress = false,
}) {
  return {
    titlePrefix: useStoreField<NullableString>(titlePrefix, true, undefined, true),
    titleSuffix: useStoreField<NullableString>(titleSuffix, true, undefined, true),
    firstName: useStoreField(firstName, true, undefined, true),
    lastName: useStoreField(lastName, true, undefined, true),
    personalIdNumber: useStoreField(personalIdNumber, true, undefined, true),
    citizenship: useStoreField(citizenship, true, undefined, true),
    countryOfBirth: useStoreField(countryOfBirth, true, undefined, true),
    birthplace: useStoreField(birthplace, true, undefined, true),
    permanentAddress: addressField(),

    specifyMailingAddress: useStoreField(specifyMailingAddress),
    mailingAddress: addressField(),
  };
}

export interface SignerReturn {
  type: StoreField<string>
  firstName: StoreField<string | null>
  lastName: StoreField<string | null>
  citizenship: StoreField<string | null>
  countryOfBirth: StoreField<string | null>
  personalIdNumber: StoreField<string | null>
  birthDate: StoreField<string | null>
  birthplace: StoreField<string | null>
  permanentAddressArea: StoreField<string | null>
  permanentAddress?: AddressField
  document: DocumentReturn
  isPoliticallyExposed: StoreField<boolean | null>
}

export function signer ({
  type = 'participant',
  firstName = '',
  lastName = '',
  citizenship = 'cz',
  countryOfBirth = 'cz',
  personalIdNumber = '',
  birthDate = '',
  birthplace = '',
  permanentAddressArea = 'cz',
}): SignerReturn {
  return {
    type: useStoreField(type),
    firstName: useStoreField<NullableString>(firstName),
    lastName: useStoreField<NullableString>(lastName),
    citizenship: useStoreField<NullableString>(citizenship),
    countryOfBirth: useStoreField<NullableString>(countryOfBirth),
    personalIdNumber: useStoreField<NullableString>(personalIdNumber),
    birthDate: useStoreField<NullableString>(birthDate),
    birthplace: useStoreField<NullableString>(birthplace),
    permanentAddressArea: useStoreField<NullableString>(permanentAddressArea),
    permanentAddress: addressField(),
    document: personalDocument({}),
    isPoliticallyExposed: useStoreField<boolean | null>(null),
  };
}

export interface LegalRequirementsReturn {
  paysTaxesInCzechia: StoreField<boolean | null>
  isPoliticallyExposed: StoreField<boolean | null>
  sourceOfIncome: StoreField<string>
  occupation: StoreField<string | null>
}

export function legalRequirements (): LegalRequirementsReturn {
  return {
    paysTaxesInCzechia: useStoreField<boolean | null>(null),
    isPoliticallyExposed: useStoreField<boolean | null>(null, true, undefined, true),
    sourceOfIncome: useStoreField('ZAM'),
    occupation: useStoreField<NullableString>(''),
  };
}

interface FundCombination {
  dynamic: number
  balanced: number
  pension: number
  conservative: number
  esg: number
}

export type Category = 'A' | 'B' | 'C' | 'D' | 'E';

type Questionnaire<T, N extends number> = {
  [K in `question${Extract<N, number>}`]: {
    answer: StoreField<T>
    openAnswer: StoreField<NullableString>
    category: StoreField<NullableString>
  };
};

// Helper type to create a union of numbers from 1 to N
// Acc['length'] -> [...Acc, 0]['length'] is used to start the array with 1 instead of 0
type NumberRange<N extends number, Acc extends number[] = []> =
  Acc['length'] extends N
    ? Acc[number]
    : NumberRange<N, [...Acc, [...Acc, 0]['length']]>;

export type QuestionnaireAnswer = null | Array<string> | string;

export interface RecommendedStrategy {
  value: string
  isRecommended: boolean
}

export interface ContributionAndStrategyReturn {
  preferConservativeFund: StoreField<boolean | undefined>
  contributionAmount: StoreField<number>
  contributionGroup: StoreField<string>
  selectCustomStrategy: StoreField<boolean>
  showQuestionnaire: StoreField<boolean>
  totalPoints: StoreField<number | null>
  investmentRiskCategory: StoreField<Category | null>
  questionnaire: Questionnaire<QuestionnaireAnswer, NumberRange<10>>
  selectedStrategy: StoreField<string | null>
  excludeConservativeFund: StoreField<boolean | null>
  fundCombination: StoreField<FundCombination>
  recommendedStrategies: StoreField<RecommendedStrategy[]>
}

export const createQuestionnaireField = (answer: QuestionnaireAnswer) => ({
  answer: useStoreField<QuestionnaireAnswer>(answer),
  openAnswer: useStoreField<NullableString>(''),
  category: useStoreField<NullableString>(null),
});

export function contributionAndStrategy ({
  contributionAmount = 0,
  contributionGroupOption = 'option-1',
  selectCustomStrategy = false,
  showQuestionnaireDefaultValue = true,
  totalPointsDefaultValue = null,
  question1 = null,
  question2 = null,
  question3 = null,
  question4 = null,
  question5 = null,
  question6 = null,
  question7 = null,
  question8 = null,
  question9 = null,
  question10 = null,
  selectedStrategy = null,
}): ContributionAndStrategyReturn {
  return {
    preferConservativeFund: useStoreField(false),
    contributionAmount: useStoreField(contributionAmount),
    contributionGroup: useStoreField(contributionGroupOption),
    selectCustomStrategy: useStoreField(selectCustomStrategy),
    showQuestionnaire: useStoreField(showQuestionnaireDefaultValue),
    totalPoints: useStoreField<number | null>(totalPointsDefaultValue),

    questionnaire: {
      question1: createQuestionnaireField(question1),
      question2: createQuestionnaireField(question2),
      question3: createQuestionnaireField(question3),
      question4: createQuestionnaireField(question4),
      question5: createQuestionnaireField(question5),
      question6: createQuestionnaireField(question6),
      question7: createQuestionnaireField(question7),
      question8: createQuestionnaireField(question8),
      question9: createQuestionnaireField(question9),
      question10: createQuestionnaireField(question10),
    },

    selectedStrategy: useStoreField<NullableString>(selectedStrategy),
    investmentRiskCategory: useStoreField<Category | null>(null),
    excludeConservativeFund: useStoreField<boolean | null>(null),
    recommendedStrategies: useStoreField<RecommendedStrategy[]>([]),

    fundCombination: useStoreField({
      dynamic: 0,
      balanced: 0,
      pension: 0,
      conservative: 0,
      esg: 0,
    }),
  };
}
