<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { useFetch } from '@vueuse/core';

import { calculateAge, canEnterRent } from '@/js/utils';
import { RefreshCw } from 'lucide-vue-next';

import TheHeader from './TheHeader.vue';
import TheFooter from './TheFooter.vue';

import LitAlert from '@/js/components/Base/LitAlert.vue';
import LitInput from '../components/Base/LitInput.vue';
import CustomStrategySelection from '@/js/components/CustomStrategySelection.vue';
import StrategySelection from '@/js/components/StrategySelection.vue';

import availableAnswers from '@/js/data/financialQuestionnaire.json' with { type: 'json' };
import {
  contributionAndStrategy as contributionAndStrategyFields,
} from '@/js/stores/createStoreFields';

import type { Question } from '@/js/types/questionnaire';
import { useQuestionnaire } from '@/js/composables/useQuestionnaire';

import StepperVertical from '@/js/components/StepperVertical.vue';
import StepperVerticalItem from '@/js/components/StepperVerticalItem.vue';
import LitButton from '../components/Base/LitButton.vue';
import QuestionnaireForm from '@/js/components/QuestionnaireForm.vue';
import LitCheckbox from '@/js/components/Base/LitCheckbox.vue';
import DatePicker from '@/js/components/DatePicker.vue';
import CustomStrategyWarningModal from '@/js/components/Modals/CustomStrategyWarningModal.vue';
import QuestionnaireLoader from '@/js/components/Questionnaire/QuestionnaireLoader.vue';

import { calculateQuestionnairePoints } from '@/js/utils/questionnairePoints';
import { getCategoryDescription, getStrategiesForCategory } from '@/js/utils/recommendedStrategies';
import { prepareState } from '../stores/utils';

import { format, parse } from 'date-fns';
import type { Category } from '../utils/matrix';
import { getMatrixResult, QUESTION_89_MATRIX } from '../utils/matrix';

const selectCustomStrategy = ref(false);

const clientBirthDate = ref('');
const fundCombination = ref<Record<string, any>>();
const showCustomStrategyModal = ref(false);
const versionOfScenario = ref('');

const contributionAndStrategy = ref(contributionAndStrategyFields({}));
const submitLoading = ref(false);
const isFromTransfer = ref(false);
const dateError = ref('');

const dateFormatErrorMessage = 'Zadejte prosím datum ve formátu dd. mm. rrrr';

const {
  questions,
  status,
  resetAnswer,
  loading,
  lastAnsweredQuestion,
  isQuestionVisible,
  calculateMultipleChoicePoints,
  calculateSingleQuestionPoints,
  handleSingleChoice,
  handleMultipleChoice,
  processMatrixResults,
  forceKoAfterQuestion2,
  updateQuestionnaireUI,
} = useQuestionnaire(availableAnswers.questions, contributionAndStrategy.value.questionnaire);

const showQuestionnaire = computed({
  get () {
    return contributionAndStrategy.value.showQuestionnaire.value;
  },
  set (value) {
    contributionAndStrategy.value.showQuestionnaire.value = value;
  },
});

const isQuestionnaireProcessed = computed(() => {
  return contributionAndStrategy.value.totalPoints.value !== null && !loading.value;
});

const showStrategySelection = computed(() => {
  const investmentStrategy = contributionAndStrategy.value.investmentRiskCategory.value;
  // do not show for investment strategy E or D
  if (investmentStrategy === 'E' || investmentStrategy === 'D') {
    return false;
  }

  return isQuestionnaireProcessed.value || selectCustomStrategy.value;
});

watch(showStrategySelection, (val) => {
  if (!val) {
    contributionAndStrategy.value.selectedStrategy.value = null;
  }
});

watch(isFromTransfer, () => {
  handleFillQuestionnaireAgain();
});

const onCustomStrategyClick = () => {
  selectCustomStrategy.value = true;
};

const currentRiskCategoryDescription = computed(() => {
  const riskCategory = contributionAndStrategy.value.investmentRiskCategory.value;
  if (!riskCategory) {
    return null;
  }

  return getCategoryDescription(riskCategory, isFromTransfer.value);
});

const categoryResult89 = computed(() => {
  const question8 = questions.value.find((q) => q.id === 8);
  const question9 = questions.value.find((q) => q.id === 9);

  if (!question8?.category || !question9?.category) {
    return null;
  }

  const matrix89Result = getMatrixResult(
    QUESTION_89_MATRIX,
    question9.category as Category,
    question8.category as Category,
  );

  if (!matrix89Result) {
    throw new Error('Could not calculate matrix result for questions 8-9');
  }

  return matrix89Result;
});

function calculateAndSetPoints () {
  const basePoints = status.value.totalPoints;
  const age = clientBirthDate.value ? calculateAge(clientBirthDate.value) : null;
  const totalPointsWithAge = calculateQuestionnairePoints(basePoints, age);

  if (status.value.unansweredQuestions === 0 || status.value.isKo) {
    contributionAndStrategy.value.totalPoints.value = totalPointsWithAge;
  }

  return totalPointsWithAge;
}

function handleQuestionnairePoints () {
  const totalPoints = calculateAndSetPoints();
  updateQuestionnaireUI();

  if (status.value.unansweredQuestions === 0 || status.value.isKo) {
    showQuestionnaire.value = false;
  }

  return totalPoints;
}

const handleValidateContributionFields = () => {
  if (!questions.value) {
    console.error('Questions not loaded');
    return;
  }

  const totalPoints = handleQuestionnairePoints();

  if (totalPoints < 0) {
    contributionAndStrategy.value.investmentRiskCategory.value = 'A';
    return;
  }

  const question7 = questions.value.find((q) => q.id === 7);
  if (question7 && question7.answer !== 'b') {
    contributionAndStrategy.value.investmentRiskCategory.value = 'A';
    return;
  }

  const REQUIRED_QUESTION_IDS = [8, 9, 10] as const;

  try {
    const [question8, question9, question10] = REQUIRED_QUESTION_IDS.map((id) => {
      const question = questions.value.find((q) => q.id === id);

      if (!question) {
        console.error(`Missing required question ${id}`);
        throw new Error(`Missing required question ${id}`);
      }

      return question;
    });

    // Ensure categories exist before using them
    if (!question8?.category || !question9?.category || !question10?.category) {
      console.error('Missing categories for questions');
      return;
    }

    processMatrixResults(
      question8 as Question,
      question9 as Question,
      question10 as Question,
      (_matrix89Result, matrix10Result) => {
        contributionAndStrategy.value.investmentRiskCategory.value = matrix10Result;
      },
    );
  } catch (error) {
    console.error('Error processing matrix results:', error);
  }
};

function setAnswer (question: Question, answer: string | string[] | null) {
  const questionnaire = contributionAndStrategy.value.questionnaire;
  const questionKey = `question${question.id}` as keyof typeof questionnaire;
  const questionAnswer = questionnaire[questionKey];

  let result;

  if (question.type === 'single') {
    result = handleSingleChoice(question, answer as string | null);
  } else {
    result = handleMultipleChoice(
      question,
      answer as string,
      questionAnswer.answer.value as string[] | null,
    );
  }

  if (result.answer !== undefined) {
    questionAnswer.answer.value = result.answer;
  }

  if (result.category !== undefined) {
    questionAnswer.category.value = result.category;
  }

  // Reset open answer if needed
  if (result.answer === null || (Array.isArray(result.answer) && result.answer.length === 0)) {
    questionAnswer.openAnswer.value = '';
  }

  // Reset strategy selection when answers change
  contributionAndStrategy.value.selectedStrategy.value = null;
  contributionAndStrategy.value.selectCustomStrategy.value = false;
}

function setOpenAnswer (question: Question, answer: string | number) {
  const questionnaire = contributionAndStrategy.value.questionnaire;
  const questionKey = `question${question.id}` as keyof typeof questionnaire;
  const questionAnswer = questionnaire[questionKey];

  questionAnswer.openAnswer.value = String(answer);
}

function validate (value: string) {
  dateError.value = '';
  const today = new Date();

  const dateRegex = /^\d{2}\.\s\d{2}\.\s\d{4}$/;
  if (!dateRegex.test(value)) {
    dateError.value = dateFormatErrorMessage;
  }

  const [day, month, year] = value.split('. ').map(Number);

  if (month < 1 || month > 12) {
    dateError.value = dateFormatErrorMessage;
    return;
  }
  if (day < 1 || day > 31) {
    dateError.value = dateFormatErrorMessage;
    return;
  }

  const inputDate = new Date(year, month - 1, day);

  if (Number.isNaN(inputDate.getTime())) {
    dateError.value = dateFormatErrorMessage;
    return;
  }

  if (inputDate > today) {
    dateError.value = 'Vámi zadané datum nemůže být v budoucnosti';
  } else {
    dateError.value = '';
  }
}

function handleFillQuestionnaireAgain () {
  showQuestionnaire.value = true;
  resetAnswer(1, (questionId: number, _answer) => {
    const questionnaire = contributionAndStrategy.value.questionnaire;
    const questionKey = `question${questionId}` as keyof typeof questionnaire;
    const questionAnswer = questionnaire[questionKey];

    questionAnswer.answer.value = null;
    questionAnswer.openAnswer.value = '';
    questionAnswer.category.value = null;
  });

  contributionAndStrategy.value.investmentRiskCategory.value = null;
  contributionAndStrategy.value.selectedStrategy.value = null;
  contributionAndStrategy.value.selectCustomStrategy.value = false;
  contributionAndStrategy.value.totalPoints.value = null;
  contributionAndStrategy.value.recommendedStrategies.value = [];
}

const recommendedStrategies = computed(() => {
  const riskCategory = contributionAndStrategy.value.investmentRiskCategory.value;
  if (!riskCategory) {
    return [];
  }

  return getStrategiesForCategory(riskCategory, isFromTransfer.value);
});

const preferConservativeFund = computed(() => {
  const dateString = clientBirthDate.value.toString();
  const date = parse(dateString, 'dd. MM. yyyy', new Date());

  return clientBirthDate.value ? canEnterRent(date) : false;
});

const excludeConservativeFund = computed({
  get () {
    return contributionAndStrategy.value.excludeConservativeFund.value;
  },
  set (value) {
    contributionAndStrategy.value.excludeConservativeFund.value = value;
  },
});

watch(lastAnsweredQuestion, (newValue) => {
  if (newValue === 2 && preferConservativeFund.value && !excludeConservativeFund.value) {
    forceKoAfterQuestion2.value = true;
    contributionAndStrategy.value.investmentRiskCategory.value = 'E';
    handleQuestionnairePoints();
  }
});

watch(status, (newValue) => {
  if (newValue.isKo) {
    // Check if it's a forced KO due to conservative fund conditions
    if (preferConservativeFund.value && !excludeConservativeFund.value) {
      contributionAndStrategy.value.investmentRiskCategory.value = 'E';
    } else if (!preferConservativeFund.value || excludeConservativeFund.value) {
      // Normal questionnaire KO case
      contributionAndStrategy.value.investmentRiskCategory.value = 'D';
    }
    handleQuestionnairePoints();
  }
});

watch(excludeConservativeFund, (newValue, oldValue) => {
  if (oldValue === undefined) {
    return;
  }

  if (oldValue !== null) {
    handleFillQuestionnaireAgain();
  }

  if (preferConservativeFund.value && !newValue) {
    forceKoAfterQuestion2.value = true;
    contributionAndStrategy.value.investmentRiskCategory.value = 'E';
    handleQuestionnairePoints();
  } else {
    forceKoAfterQuestion2.value = false;
  }
});

watch(recommendedStrategies, (newValue) => {
  contributionAndStrategy.value.recommendedStrategies.value = newValue;
});

const handleAnswerUpdate = (question: Question, answer: string | string[] | null) => {
  setAnswer(question, answer);
};

const handleOpenAnswerUpdate = (question: Question, answer: string | number) => {
  setOpenAnswer(question, answer);
};

const canSubmitForm = computed(() => {
  if (isQuestionnaireProcessed.value && !loading.value && clientBirthDate.value) {
    return true;
  }

  return false;
});

const notificationMessage = ref<{ message: string, type: 'danger' | 'success', dusk: string } | null>(null);

const handleSubmitQuestionnaire = async () => {
  submitLoading.value = true;

  const dateOfBirth = clientBirthDate.value
    ? format(parse(clientBirthDate.value, 'dd. MM. yyyy', new Date()), 'dd-MM-yyyy')
    : null;

  const { error, isFetching, isFinished, statusCode } = await useFetch('/api/v1/contracts/pension-questionnaire', {
    headers: {
      Accept: 'application/json',
    },
  }).post({
    dateOfBirth,
    version: versionOfScenario.value,
    contractType: 'jps',
    fundCombination: fundCombination.value,
    preferConservativeFund: contributionAndStrategy.value.excludeConservativeFund.value,
    transferExistingRetirementSavings: isFromTransfer.value,
    contributionAndStrategy: prepareState(contributionAndStrategy.value),
    categoryResult89: categoryResult89.value,
  });

  if (error.value) {
    console.error('Error submitting questionnaire:', error.value);
    notificationMessage.value = {
      message: 'Došlo k chybě při odesílání dotazníku. Prosím obnovte stránku a zkuste to znovu.',
      type: 'danger',
      dusk: 'submit-error-alert',
    };
  }

  if (statusCode.value === 201) {
    notificationMessage.value = {
      message: 'Dotazník byl úspěšně odeslán.',
      type: 'success',
      dusk: 'submit-success-alert',
    };
  }

  if (isFetching.value) {
    submitLoading.value = true;
  }

  if (isFinished.value) {
    submitLoading.value = false;
  }
};

watch(preferConservativeFund, (newValue) => {
  if (newValue) {
    excludeConservativeFund.value = false;
  } else {
    excludeConservativeFund.value = null;
  }
}, {
  immediate: true,
});
</script>

<template>
  <div class="body">
    <TheHeader />

    <div class="question-answer">
      <div class="question-answer__item">
        <span>Ot.</span>
        <span>Odp.</span>
        <span>Výsl.</span>
      </div>
      <div
        v-for="(question, index) in questions"
        :key="question.id"
        class="question-answer__item"
      >
        <span>
          {{ index + 1 }}.
        </span>
        <span>
          {{
            Array.isArray(question.answer)
              ? question.answer.join(', ')?.toUpperCase() ?? '-'
              : question.answer?.toUpperCase() ?? '-'
          }}
        </span>
        <span v-if="question.category">
          {{ question.category }}
        </span>
        <span v-else>
          {{
            question.type === 'multiple'
              ? calculateMultipleChoicePoints(question as any)
              : calculateSingleQuestionPoints(question as any)?.points ?? '-'
          }}
        </span>
      </div>

      <hr>

      <div class="question-answer__result">
        <span>
          Mezivýsl. 8+9
        </span>
        {{ categoryResult89 }}
      </div>

      <div class="question-answer__result">
        <span>
          Mezivýsl. b.
        </span>
        {{ status.totalPoints }}
      </div>

      <hr>

      <div class="question-answer__result">
        <span>
          Výsl. b.
        </span>
        <strong>
          {{ contributionAndStrategy.totalPoints.value }}
        </strong>
      </div>

      <hr>

      <div class="question-answer__result">
        <span>
          Výsl. kat.
        </span>
        <strong>
          {{ contributionAndStrategy.investmentRiskCategory.value }}
        </strong>
      </div>
    </div>

    <main>
      <div class="benefits bg-blue pt-30 pb-10 mb-30">
        <div class="container--sm">
          <h2 class="dash text-bold">
            Penzijní dotazník - debug
          </h2>
        </div>
      </div>

      <div class="mt-20">
        <div class="container--sm">
          <LitCheckbox
            v-model="isFromTransfer"
            class="mb-20"
            dusk="is-from-transfer"
          >
            Převod stávajícího penzijního spoření
          </LitCheckbox>

          <DatePicker
            v-model="clientBirthDate"
            name="date-of-birth"
            class="mb-20"
            label="Datum narození"
            :error="dateError"
            @update:model-value="validate"
          />

          <LitInput
            v-model="versionOfScenario"
            dusk="pension-questionnaire-version"
            label="Verze scénáře"
          />

          <LitAlert
            v-if="preferConservativeFund && excludeConservativeFund !== null"
            dusk="conservative-fund-alert"
          >
            <p>
              Vážený kliente,
              <br><br>
              ze zákona nám vyplývá povinnost umístit Vaše prostředky nejpozději 5 let před dosažením důchodového věku do povinného konzervativního fondu.
              <br><br>
              V případě, že s umístěním Vašich prostředků do povinného konzervativního fondu nesouhlasíte, vystavujete se riziku, že v případě nepříznivého vývoje na trzích může dojít k významným ztrátám, které mohou ovlivnit hodnotu úspor těsně před důchodem.
            </p>

            <LitCheckbox
              v-model="excludeConservativeFund"
              class="mt-20"
              dusk="exclude-conservative-fund"
            >
              Žádám, aby mé prostředky nebyly umístěny do povinného konzervativního fondu a přeji si doporučit jinou strategii spoření.
            </LitCheckbox>
          </LitAlert>

          <h3>
            Nastavte s účastníkem nejvhodnější strategii spoření v doplňkovém penzijním spoření („penzijko“)
          </h3>
          <p>
            Abychom mohli doporučit tu nejvhodnější strategii spoření, potřebujeme vědět, jaké jsou účastníkovy cíle v rámci penzijka, jak se orientuje ve světě financí a investic a jaký je jeho vztah k investičnímu riziku.
          </p>
          <p>
            Prosíme, vyplňte s účastníkem penzijní dotazník.
          </p>

          <StepperVertical>
            <StepperVerticalItem :class="{ disabled: !showStrategySelection }">
              <template #icon>
                <span class="step__icon--number">
                  1
                </span>
              </template>

              <template #label>
                Penzijní dotazník
              </template>

              <template #content>
                <LitAlert
                  v-if="isQuestionnaireProcessed"
                  dusk="questionnaire-successful-alert"
                  alert-type="success"
                  class="mb-20"
                >
                  Děkujeme za vyplnění penzijního dotazníku.
                </LitAlert>

                <div
                  v-show="isQuestionnaireProcessed"
                  dusk="toggle-show-questionnaire"
                  class="btn-simple mb-20"
                  @click="handleFillQuestionnaireAgain"
                >
                  <RefreshCw :size="12" class="mr-5" />

                  <span>
                    Vyplnit dotazník znovu
                  </span>
                </div>

                <div v-show="!loading && showQuestionnaire">
                  <QuestionnaireForm
                    :questions="(questions as Question[])"
                    :is-question-visible="isQuestionVisible"
                    @update:answer="handleAnswerUpdate"
                    @update:open-answer="handleOpenAnswerUpdate"
                  />

                  <div
                    v-if="!status.isKo && lastAnsweredQuestion >= 2"
                    class="flex flex-center"
                  >
                    <LitButton
                      class="mt-15 mb-15"
                      dusk="evaluate-questionnaire"
                      :disabled="status.unansweredQuestions !== 0 || !clientBirthDate"
                      @click="handleValidateContributionFields"
                    >
                      Vyhodnotit penzijní dotazník
                    </LitButton>
                  </div>
                </div>

                <QuestionnaireLoader v-if="loading" />
              </template>
            </StepperVerticalItem>

            <StepperVerticalItem :class="{ disabled: !showStrategySelection }">
              <template #icon>
                <span class="step__icon--number">
                  2
                </span>
              </template>

              <template #label>
                <div dusk="strategy-selection">
                  Vyberte si strategii
                </div>
              </template>

              <template #content>
                <LitAlert
                  v-if="!showStrategySelection && !isQuestionnaireProcessed"
                  dusk="strategy-selection-alert"
                >
                  Navrhované strategie spoření se zobrazí po vyplnění Penzijního dotazníku.
                </LitAlert>

                <div v-if="isQuestionnaireProcessed && selectCustomStrategy">
                  <p class="mb-30">
                    Výběrem vlastní strategie spoření se vystavujete riziku,
                    že zvolená strategie spoření nebude odpovídat
                    vašim cílům, odborným znalostem nebo zkušenostem potřebným
                    pro pochopení rizik souvisejících s investováním.
                  </p>

                  <CustomStrategySelection
                    v-model:fund-combination="fundCombination"
                    :model-value="(contributionAndStrategy.selectedStrategy.value as string)"
                    :is-custom-strategy="selectCustomStrategy"
                    :prefer-conservative-fund="preferConservativeFund"
                    @update:model-value="contributionAndStrategy.selectedStrategy.value = $event"
                  />
                </div>

                <div v-if="isQuestionnaireProcessed && !selectCustomStrategy">
                  <LitAlert
                    v-if="status.isKo && !status.isKoFromQuestion2"
                    class="mb-30"
                    dusk="alert-unadvisable-product"
                  >
                    Vážená klientko / Vážený kliente,
                    <br><br>
                    na základě vámi uvedených informací jsme vyhodnotili, že pro vás sjednání Doplňkového
                    penzijního spoření není vhodné. Jedná se o dlouhodobé spoření,
                    a proto vám nedoporučujeme smlouvu uzavřít.
                  </LitAlert>
                  <p
                    v-else
                    class="mb-30"
                    dusk="text-advisable-product"
                  >
                    <span v-if="currentRiskCategoryDescription !== null">
                      {{ currentRiskCategoryDescription }}
                      <br><br>
                      Na základě vašich odpovědí vám doporučujeme níže uvedené strategie.
                    </span>
                  </p>

                  <StrategySelection
                    dusk="strategy-selection"
                    :recommended-strategies="recommendedStrategies"
                    :model-value="contributionAndStrategy.selectedStrategy.value"
                    @update:model-value="contributionAndStrategy.selectedStrategy.value = $event"
                  />
                </div>

                <div v-show="showStrategySelection && isQuestionnaireProcessed" class="text-center mt-20">
                  <button
                    v-if="!selectCustomStrategy"
                    class="btn-simple"
                    type="button"
                    dusk="custom-strategy-link"
                    @click="showCustomStrategyModal = true"
                  >
                    Případně vyberte vlastní strategii
                  </button>
                  <button
                    v-else
                    class="btn-simple"
                    type="button"
                    @click="selectCustomStrategy = false"
                  >
                    Zpět na doporučené strategie
                  </button>
                </div>
              </template>
            </StepperVerticalItem>
          </StepperVertical>

          <LitAlert
            v-if="notificationMessage"
            class="mt-30"
            :alert-type="notificationMessage.type"
            :dusk="notificationMessage.dusk"
          >
            {{ notificationMessage.message }}
          </LitAlert>

          <div class="flex flex-center mt-40 mb-40">
            <LitButton
              type="submit"
              dusk="submit"
              :disabled="!canSubmitForm || notificationMessage?.message"
              @click="handleSubmitQuestionnaire"
            >
              Odeslat dotazník
            </LitButton>
          </div>
        </div>
      </div>
    </main>

    <CustomStrategyWarningModal
      :model-value="showCustomStrategyModal"
      @close="showCustomStrategyModal = false"
      @click="onCustomStrategyClick"
    />

    <TheFooter />
  </div>
</template>

<style lang="scss" scoped>
@import '@sass/tools/variables';
@import '@sass/tools/mixins';
@import '@sass/tools/functions';

.question-answer {
  position: fixed;
  z-index: 5;
  top: 20vh;
  right: 0;
  width: 200px;
  background-color: #f5f5f5;
  border-radius: 4px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, .1);
  padding: 1rem;

  &__item {
    margin-bottom: 2px;
    justify-content: space-between;

    span {
      display: inline-block;
      width: 33%;

      &:last-child {
        text-align: right;
      }
    }
  }

  &__result {
    display: flex;
    justify-content: space-between;
  }
}
</style>
