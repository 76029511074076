<script setup lang="ts">
import { computed, ref, shallowRef } from 'vue';
import LitButton from '@/js/components/Base/LitButton.vue';
import LitIconSvg from '@/js/components/Base/LitIconSvg.vue';

interface Props {
  formType?: 'contract' | 'request'
}

const props = withDefaults(defineProps<Props>(), {
  formType: 'contract',
});

defineEmits([
  'showModal',
]);

const text = shallowRef({
  contract: 'smlouvu',
  request: 'žádost',
});

const showOtherContractFinishPossibilities = ref<boolean>(false);

const form = computed(() => {
  return text.value[props.formType];
});
</script>

<template>
  <div>
    <div class="container--xs my-20 center gap-16">
      <LitButton
        class="print-link"
        variant="link"
        @click="showOtherContractFinishPossibilities = !showOtherContractFinishPossibilities"
      >
        Ostatní možnosti

        <LitIconSvg
          v-if="showOtherContractFinishPossibilities"
          icon-name="arrow_up-grey"
        />
        <LitIconSvg
          v-else
          icon-name="arrow_down-grey"
        />
      </LitButton>
    </div>
    <div
      v-show="showOtherContractFinishPossibilities"
      class="container--md text-center print-link board"
    >
      Pokud není možné {{ form }} podepsat elektronicky, můžete využít <a @click="$emit('showModal')">
        TISK</a> a&nbsp;podepsat ručně
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@sass/tools/mixins.scss';
@import '@sass/tools/variables.scss';

:deep(.print-link.lit-btn) {
  color: getColor(grey);
  gap: 8px;
}

.board {
  padding: 10px 15px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .16);

  a {
    cursor: pointer;
  }
}
</style>
