<template>
  <div dusk="thank-you">
    <section class="benefits bg-blue pt-30 pb-10" />

    <div class="container--sm my-30">
      <TheHeading>Děkujeme vám za úpravu smlouvy</TheHeading>
      <p>
        Úprava parametrů smlouvy byla úspěšná. Do e-mailu pro zaslání dokumentace
        <span v-if="!store.beforeStart.isTiedAgent.value"> a e-mailu obchodního zástupce</span> jsme zaslali veškeré dokumenty.
      </p>

      <hr class="py-20 mt-20">

      <div
        v-if="store.beforeStart.isTiedAgent.value"
        class="container--sm"
      >
        <h3 class="text-center">
          Údaje účastníka, pro kterého byl dodatek ke smlouvě uzavřen
        </h3>

        <table class="recapitulation-table mb-16">
          <tbody>
            <tr>
              <td>Číslo smlouvy</td>
              <td
                colspan="2"
              >
                {{ store.clientData.contractNumber.value }}
              </td>
            </tr>
            <tr>
              <td>Rodné číslo účastníka</td>
              <td
                colspan="2"
              >
                {{ store.clientData.personalIdNumber.value }}
              </td>
            </tr>
            <tr>
              <td>Jméno a přijmení účastníka</td>
              <td
                colspan="2"
              >
                {{ store.clientData.firstName.value }} {{ store.clientData.lastName.value }}
              </td>
            </tr>
            <tr v-if="store.contractSettings.contributionChangeRequired.value">
              <td>Frekvence placení</td>
              <td
                colspan="2"
              >
                Měsíčně
              </td>
            </tr>
            <tr v-if="store.contractSettings.contributionChangeRequired.value">
              <td>Částka, kterou si bude účastník platit</td>
              <td
                colspan="2"
              >
                {{ store.contractSettings.contribution.amount.value }} Kč
              </td>
            </tr>
          </tbody>
        </table>

        <hr class="py-20 mt-20">
      </div>
    </div>

    <div class="container--sm my-30 flex flex-center">
      <LitButton
        variant="outline"
        :to="{ name: 'distribution.home' }"
        title="Přejít na hlavní rozcestník"
      >
        Přejít na hlavní rozcestník
      </LitButton>
    </div>
  </div>
</template>

<script lang="ts">
import { destroy } from '@/js/api/contracts';
import LitButton from '@/js/components/Base/LitButton.vue';
import TheHeading from '@/js/components/TheHeading.vue';
import { useChangeRequestStore } from '@/js/stores/distibution/changeRequest';
import { resetState } from '@/js/stores/utils';

export default {
  components: {
    LitButton,
    TheHeading,
  },

  beforeRouteLeave (to, _from, next) {
    this.clearLocalContractData();

    const disallowedPaths = [
      'changeRequest.recapitulation',
    ];

    if (disallowedPaths.includes(to.name as string)) {
      next({ name: 'distribution.home' });
    } else {
      next();
    }
  },

  setup () {
    const store = useChangeRequestStore();

    function clearLocalContractData () {
      resetState(store.$state, false);
    }

    return {
      store,
      clearLocalContractData,
    };
  },

  async mounted () {
    const { contractUuid } = this.$route.params;

    try {
      await destroy(contractUuid as string);
    } catch (e) {
      console.warn('Failed change request destroying', e);
    }
  },
};
</script>

<style lang="scss" scoped>
@import '@sass/tools/mixins';
@import '@sass/tools/variables';
@import '@sass/tools/functions';

.bg-green {
  background-color: getColor(bg-green);
  overflow-y: hidden;
}

h3 {
  text-align: center;

  @include media(min, $md) {
    text-align: left;
  }
}

.text-center {
  text-align: center;
}

.short {
  width: 100%;

  @include media(min, $md) {
    width: 50%;
  }
}

li {
  list-style-image: url('@/images/icons/dot.svg');
}

.cta {
  background: getColor(cta);
  display: flex;
  padding: 15px;

  &-text {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    margin-right: auto;
    line-height: 1.45;
    width: 100%;
  }

  i {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    flex-shrink: 0;
    margin-right: 15px;

    img {
      width: 25px;
    }
  }
}

.separator {
  width: auto;
  height: 1px;
  background-color: getColor(light-grey);
}
</style>
