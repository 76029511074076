<script lang="ts">
import type { VNode } from 'vue';
import { defineAsyncComponent, h } from 'vue';

import { objectEntries } from '@vueuse/core';
import StrategySelectionFunds from '@/js/components/StrategySelectionFunds.vue';
import FUNDS from '@/js/data/fundsData';
import LitTabs from '@/js/components/Base/Tabs/LitTabs.vue';
import LitTab from '@/js/components/Base/Tabs/LitTab.vue';
import LitAlert from '@/js/components/Base/LitAlert.vue';
import LitInput from '@/js/components/Base/LitInput.vue';
import LitModal from '@/js/components/Base/LitModal.vue';

const FundsChart = defineAsyncComponent(() => import('@/js/components/FundsChart.vue'));
const LifeCycleStrategyDetail = defineAsyncComponent(() => import('@/js/components/LifeCycleStrategyDetail.vue'));

const MAX_SHARE = 100;
const DEFAULT_SELECTED_STRATEGY = 'lifeCycle';
const INDIVIDUAL_FUND_STRATEGY = 'individualFunds';
const CUSTOM_FUND_COMBINATION_STRATEGY = 'customFundCombination';

interface Funds {
  esg: number
  dynamic: number
  balanced: number
  pension: number
  conservative: number
}

interface State {
  fundCombinationInt: Funds
  fundsData: typeof FUNDS
  showStrategyDetail: string | null
}

export default {
  components: {
    LitTabs,
    LitTab,
    LitAlert,
    LitInput,
    LitModal,
    FundsChart,
    LifeCycleStrategyDetail,
  },

  props: {
    modelValue: {
      type: String,
      required: false,
      default: null,
    },

    fundCombination: {
      type: Object,
      required: false,
      default: null,
    },

    preferConservativeFund: {
      type: Boolean,
      required: false,
      default: false,
    },

    isReinvestmentAllowed: {
      type: Boolean,
      required: false,
      default: true,
    },

    isCustomStrategy: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: [
    'update:modelValue',
    'update:fundCombination',
  ],

  data: (): State => ({
    fundCombinationInt: {
      esg: 0,
      dynamic: 0,
      balanced: 0,
      pension: 0,
      conservative: 0,
    },
    fundsData: FUNDS,
    showStrategyDetail: null,
  }),

  computed: {
    sumFunds () {
      const sum = Object.values(this.fundCombinationSync)
        .reduce((a, b) => a + b);

      return Number.parseInt(sum, 10);
    },

    /**
     * For every fund, calculates the remaining max share.
     * 100 - (current_sum - current_share)
     */
    remainingParts () {
      const sum = this.sumFunds;
      const funds = this.fundCombinationSync;

      const result = {} as Record<keyof Funds, number>;

      objectEntries(funds).forEach(([key, share]) => {
        result[key] = MAX_SHARE - (sum - Number.parseInt(share, 10));
      });

      return result;
    },

    selectedStrategy: {
      get () {
        if (this.isCustomStrategy && this.modelValue !== CUSTOM_FUND_COMBINATION_STRATEGY) {
          return INDIVIDUAL_FUND_STRATEGY;
        }

        return this.modelValue;
      },

      set (val: string) {
        if (this.isCustomStrategy && val === INDIVIDUAL_FUND_STRATEGY) {
          this.$emit('update:modelValue', DEFAULT_SELECTED_STRATEGY);
          return;
        }

        this.$emit('update:modelValue', val);
      },
    },

    selectedIndividualStrategy: {
      get (): string | null {
        if (this.selectedStrategy === CUSTOM_FUND_COMBINATION_STRATEGY) {
          return DEFAULT_SELECTED_STRATEGY;
        }

        return this.modelValue;
      },

      set (val: string) {
        if (this.selectedStrategy !== INDIVIDUAL_FUND_STRATEGY) {
          return;
        }

        this.$emit('update:modelValue', val);
      },
    },

    fundCombinationSync: {
      get () {
        return this.fundCombination === null
          ? this.fundCombinationInt
          : this.fundCombination;
      },

      set (val: Funds) {
        if (this.fundCombination === null) {
          this.fundCombinationInt = val;
        } else {
          this.$emit('update:fundCombination', val);
        }
      },
    },
  },

  methods: {
    updateFundCombination (fundName: keyof Funds, value: number) {
      const funds = { ...this.fundCombinationSync }; // creates copy of the object
      const newValue = Number.parseInt(value.toString(), 10);
      funds[fundName] = newValue || 0;
      this.fundCombinationSync = funds;
    },

    incrementShare (fund: keyof Funds, val = 5) {
      this.updateFundCombination(fund, this.fundCombinationSync[fund] + val);
    },

    decrementShare (fund: keyof Funds, val = 5) {
      this.updateFundCombination(fund, this.fundCombinationSync[fund] - val);
    },

    hideStrategyDetail (show: boolean) {
      if (show === false) {
        this.showStrategyDetail = null;
      }
    },

    roundParts () {
      const funds = this.fundCombinationSync;

      objectEntries(funds).forEach(([key, share]) => {
        const decrease = share % 5;
        funds[key] = share - decrease;
      });
    },
  },

  render () {
    const createFundOption = ({ title, slug }: {
      title: string
      slug: keyof Funds
      content: {
        description: {
          short: string
          long: string
        }
        detailLink: string
      }
    }) => (
      h(
        LitInput,
        {
          'min': 0,
          'max': this.remainingParts[slug],
          'showAppend': true,
          'step': 5,
          'modelValue': this.fundCombinationSync[slug],
          'label': title,
          'className': 'control--sm',
          'type': 'number',
          'class': 'mb-10',

          'onBlur': () => {
            this.roundParts();
          },

          'onUpdate:modelValue': (event) => {
            this.updateFundCombination(slug, event);
          },
        },
        {
          'append': () => h('span', {
            class: 'unit',
          }, '%'),

          'number-actions': () => h('div', {
            class: 'number-actions',
          }, [
            h('button', {
              class: 'number-actions__btn',
              type: 'button',
              onClick: () => {
                this.incrementShare(slug);
              },
            }, '+'),

            h('button', {
              class: 'number-actions__btn',
              type: 'button',
              onClick: () => {
                this.decrementShare(slug);
              },
            }, '-'),
          ]),

          'actions': () => h('div', {
            class: 'actions-group',
          }, [
            h('button', {
              class: 'fond-link',
              type: 'button',
              onClick: () => {
                this.showStrategyDetail = slug;
              },
            }, 'Více o fondu'),
          ]),
        },
      )
    );

    const fundCombinationOptions = [
      /* @ts-expect-error: json */
      createFundOption(FUNDS.esg),
      /* @ts-expect-error: json */
      createFundOption(FUNDS.dynamic),
      /* @ts-expect-error: json */
      createFundOption(FUNDS.balanced),
      /* @ts-expect-error: json */
      createFundOption(FUNDS.pension),
      /* @ts-expect-error: json */
      createFundOption(FUNDS.conservative),
    ];

    const fundSelectionTab = h(
      LitTab,
      {
        'key': INDIVIDUAL_FUND_STRATEGY,
        'title': 'Jednotlivé fondy / strategie',
        'label': null,
        'data-slug': INDIVIDUAL_FUND_STRATEGY,
      },
      () => [
        h(StrategySelectionFunds, {
          'modelValue': this.selectedIndividualStrategy,
          'onUpdate:modelValue': (event: string | null) => {
            this.selectedIndividualStrategy = event;
          },
          'preferConservativeFund': this.preferConservativeFund,
          'isReinvestmentAllowed': this.isReinvestmentAllowed,
        }),
      ],
    );

    const customFundsCombination = h(
      LitTab,
      {
        'title': 'Vlastní kombinace fondů',
        'label': null,
        'data-slug': CUSTOM_FUND_COMBINATION_STRATEGY,
        'key': CUSTOM_FUND_COMBINATION_STRATEGY,
        'class': 'custom-strategy-tab',
      },
      () => [
        h('div', {
          class: 'custom-strategy',
        }, 'Podíl v %'),

        ...fundCombinationOptions,

        h('hr'),

        h('div', {
          class: 'custom-strategy-sum my-20',
        }, [
          h('div', {
            class: 'custom-strategy-sum__label',
          }, 'Celkem:'),

          h('div', {
            class: 'custom-strategy-sum__sum',
          }, [
            h(
              'span',
              {
                class: [this.sumFunds === 100 ? 'text-success' : 'text-danger', 'text-bold'],
              },
              `${this.sumFunds} % `,
            ),
            'ze 100 %',
          ]),
        ]),

        (this.sumFunds < 100)
          ? h(LitAlert, {
              alertType: 'danger',
            }, () => [
              'Vámi zadaná hodnota musí činit ',
              h('span', {
                class: 'text-bold',
              }, 'v součtu 100%'),
              '. Pro pokračování vložte chybějící hodnotu.',
            ])
          : null,
      ],
    );

    const content = [
      h(LitTabs, {
        'returnAttribute': 'data-slug',
        'modelValue': this.selectedStrategy,

        'onUpdate:modelValue': (event) => {
          this.selectedStrategy = event;
        },
      }, () => [
        fundSelectionTab,
        customFundsCombination,
      ]),
    ];

    const fundDetailBody = [] as VNode[];

    if (this.showStrategyDetail === 'lifeCycle') {
      fundDetailBody.push(
        h(LifeCycleStrategyDetail),
      );
    } else {
      fundDetailBody.push(
        h('FundsChart', {
          fundType: this.showStrategyDetail,
          class: 'mb-30',
        }),

        h('p', {
          /* @ts-expect-error: json */
          innerHTML: FUNDS[this.showStrategyDetail]?.content?.description.long,
        }),
      );
    }

    if (this.showStrategyDetail !== null) {
      content.push(h(
        LitModal,
        {
          'modelValue': this.showStrategyDetail !== null,
          'max-width': 838,
          'onUpdate:modelValue': (show) => {
            if (show === false) {
              this.showStrategyDetail = null;
            }
          },
        },
        {
          header: () => h('h3', {
            /* @ts-expect-error: json */
            innerHTML: FUNDS[this.showStrategyDetail].title,
            class: 'text-left',
          }),

          body: () => fundDetailBody,

          footer: () => h('div', {
            class: 'modal__buttons flex-end',
          }, [
            h('button', {
              class: 'btn btn-primary btn-outline',
              dusk: 'close-modal',
              onClick: () => {
                this.showStrategyDetail = null;
              },
              innerHTML: 'Zavřít',
            }),
          ]),
        },
      ));
    }

    return h('div', content);
  },
};
</script>

<style lang="scss" scoped>
@use 'sass:math';

@import '@sass/tools/variables';
@import '@sass/tools/functions';
@import '@sass/tools/mixins';

.number-actions {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;

  .number-actions__btn {
    height: math.div($input-height, 2);
    width: math.div($input-height, 2);
    color: getColor(light-blue);
    outline: none;
    font-size: $root;

    &:hover {
      background: getColor(light-blue);
      color: getColor(white);
    }
  }
}

.actions-group {
  margin-left: auto;
  padding-right: 0;

  @include media(max, 620px) {
    text-align: right;

    button {
      display: block;
      text-align: right;
    }
  }
}

.control.control--unit {
  input[type=number] {
    padding-right: 50px;
    font-weight: $bold;
  }

  .unit {
    right: math.div($input-height, 2);
    width: 25px;
    font-weight: $bold;
    color: inherit;
  }
}

.custom-strategy {
  font-weight: $bold;
  margin-bottom: 10px;

  @include media(min, 620px) {
    max-width: 364px;
    margin-left: 187px;
  }

  &-sum {
    display: flex;
    justify-content: space-between;

    &__label {
      margin-right: 10px;
      font-weight: $bold;
      flex-basis: 177px;
    }

    @include media(min, 620px) {
      flex-basis: 177px;
      justify-content: flex-start;
    }

    &__title {
      font-weight: $bold;
    }
  }

  &-tab {
    & :deep(.field) {
      @include media(min, 620px) {
        flex-flow: row;
      }

      .field-info {
        display: flex;
        flex-flow: column;
        max-width: 330px;

        .field-info--m {
          @include media(min, 620px) {
            margin-left: 187px;
          }
        }
      }

      label {
        @include media(min, 620px) {
          flex-basis: 177px;
          margin-right: 10px;
          display: flex;
          align-items: center;
          margin-bottom: 0;
          flex-shrink: 0;
        }
      }
    }

    :deep(.control__wrapper) {
      display: flex;
      align-items: center;
      max-width: 100%;
      flex-shrink: 0; //too long description

      @include media(min, 620px) {
        flex-basis: 360px;
      }

      &.control {
        &--sm {
          .control {
            max-width: 100%;

            @include media(min, 620px) {
              max-width: 177px;
            }
          }

          @include media(min, 620px) {
            flex-basis: 207px;
          }
        }
      }
    }
  }
}

.fond-link {
  color: getColor(fund-balanced, .5);
  text-decoration: underline;
  cursor: pointer;
  font-size: $root-mini;

  &:hover {
    text-decoration: none;
  }
}
</style>
