import fundsData from '@/js/data/fundsData';

export const useStrategyTranslation = (strategy: string | null) => {
  switch (true) {
    case strategy === fundsData.lifeCycle.slug:
      return fundsData.lifeCycle.title;
    case strategy === fundsData.balanced.slug:
      return fundsData.balanced.title;
    case strategy === fundsData.dynamic.slug:
      return fundsData.dynamic.title;
    case strategy === fundsData.esg.slug:
      return fundsData.esg.title;
    case strategy === fundsData.pension.slug:
      return fundsData.pension.title;
    case strategy === fundsData.conservative.slug:
      return fundsData.conservative.title;
    case strategy === fundsData.reinvestment.slug:
      return fundsData.reinvestment.title;
    case strategy === 'customFundCombination':
      return 'Vlastní kombinace fondů';
    case strategy === 'reinvestment':
      return 'Postupná reinvestice';
    default:
      return strategy;
  }
};
