<script setup lang="ts">
import { ArrowLeft } from 'lucide-vue-next';
import { storeToRefs } from 'pinia';
import { computed, onMounted, ref, shallowRef } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import type { SignerKeys } from '@/js/data/signers.ts';
import { draftTokens, signatures } from '@/js/api';
import LitAlert from '@/js/components/Base/LitAlert.vue';
import LitButton from '@/js/components/Base/LitButton.vue';
import LitIconSvg from '@/js/components/Base/LitIconSvg.vue';
import LitInput from '@/js/components/Base/LitInput.vue';

import LitTextarea from '@/js/components/Base/LitTextarea.vue';
import MeetingRecordPreview from '@/js/components/MeetingRecordPreview.vue';
import ContractSigningModal from '@/js/components/Modals/ContractSigningModal.vue';

import ContractSubmissionModal from '@/js/components/Modals/ContractSubmissionModal.vue';
import FinishWithPaperPrintModal from '@/js/components/Modals/FinishWithPaperPrintModal.vue';
import PrintOffer from '@/js/components/PrintOffer.vue';

import ChangeRequestStepper from '@/js/components/Steppers/ChangeRequestStepper.vue';
import { useCountryTranslation } from '@/js/composables/useCountryTranslation.ts';
import { useDateTimeFormat } from '@/js/composables/useDateTimeFormat.ts';
import { useDocumentTypeTranslation } from '@/js/composables/useDocumentTypeTranslation.ts';
import { useIncomeTranslation } from '@/js/composables/useIncomeTranslation.ts';
import { useSignerTranslation } from '@/js/composables/useSignerTranslation.ts';
import { useChangeRequestStore } from '@/js/stores/distibution/changeRequest';
import { submitContract } from '@/js/stores/utils.ts';
import { scrollToError } from '@/js/utils.ts';
import { useStrategyTranslation } from '@/js/composables/useStrategyTranslation.ts';

const store = useChangeRequestStore();

const {
  draftToken,
  submitResponse,
  beforeStart,
  changeSettings,
  clientData,
  signer,
} = storeToRefs(store);
const { validateField, validateFields } = store;

const state = {
  ...store.$state,
};

const error = ref(false);
const validating = ref(false);
const submitting = ref(false);
const allowAnotherDeviceSignature = ref(false);
const isRedirected = ref(false);
const redirecting = ref(false);
const showDigitalSignModal = ref(false);
const showPrintConfirmationModal = ref(false);
const signingLink = ref(null);

const route = useRoute();
const router = useRouter();

const labels = shallowRef({
  distributorData: 'Kontaktní údaje obchodního zástupce',
  contactInformation: 'Kontaktní údaje účastníka',
  contractSettings: 'Nastavení smlouvy',
  legalRequirements: 'Zákonné požadavky',
  personalData: 'Osobní a kontaktní údaje',
});

const fields = shallowRef([
  'changeSettings.signatureDate',
  'changeSettings.signaturePlace',
]);

const signatureDate = computed(() => {
  return formatDate(new Date());
});

const signerLabel = computed(() => {
  return useSignerTranslation(signer.value.type.value as SignerKeys, 'locative');
});

const isParticipant = computed(() => {
  return signer.value.type.value === 'participant';
});

const isSubmitting = computed(() => {
  return submitting.value || redirecting.value;
});

const isClientFromCzechia = computed(() => {
  return clientData.value.permanentAddressArea.value === 'cz';
});

const isSignerFromCzechia = computed(() => {
  return signer.value.type.value !== 'participant' && signer.value.permanentAddressArea.value === 'cz';
});

const isCommittee = computed(() => {
  return signer.value.type.value === 'committee';
});

const displayClientStatementField = computed(() => {
  return store.beforeStart.isTiedAgent.value
    && (store.contractSettings.contributionChangeRequired.value
      || store.contractSettings.taxOptimizationChangeRequired.value
      || store.strategy.changeRequired.value);
});

const showMeetingRecordPreview = computed(() => {
  const clientStatement = store.changeSettings.clientStatement.value;
  let clientStatementIsFilled = true;

  if (!displayClientStatementField.value || clientStatement === null || clientStatement === '') {
    clientStatementIsFilled = false;
  }

  return [
    store.strategy.changeRequired.value,
    store.contractSettings.taxOptimizationChangeRequired.value,
    store.contractSettings.contributionChangeRequired.value,
    clientStatementIsFilled,
  ].some((field) => field);
});

const signingLinks = computed(() => {
  return submitResponse.value.value?.metadata.signingLinks;
});

const income = computed(() => {
  return useIncomeTranslation(store.legalRequirements.sourceOfIncome.value);
});

const strategy = computed(() => {
  return useStrategyTranslation(store.strategy.selectedStrategy.value);
});

const displayContractSettingsSection = computed(() => {
  return store.contractSettings.contributionChangeRequired.value
    || store.contractSettings.taxOptimizationChangeRequired.value
    || store.contractSettings.employerChangeRequired.value
    || store.strategy.changeRequired.value;
});

function formatDate (date: Date) {
  if (!date) {
    return '';
  }

  const dateToFormat = new Date(date);
  return useDateTimeFormat(dateToFormat, 'dd. MM. yyyy');
}

function translateEmployerAction (action: string): string {
  if (action === 'add') {
    return 'Přidat příspěvek zaměstnavatele';
  } else if (action === 'remove') {
    return 'Odstranit příspěvek od zaměstnavatele';
  } else {
    return 'Upravit zaměstnavatele';
  }
}

async function openModal (signMethod: string) {
  const { contractUuid } = route.params;

  try {
    await validateFields({
      contractUuid,
      throwOnErrors: true,
      fieldPaths: fields.value,
    });

    if (signMethod === 'digital') {
      showDigitalSignModal.value = true;
    }

    if (signMethod === 'offline') {
      showPrintConfirmationModal.value = true;
    }
  } catch (e: any) {
    throw new Error(e);
  }
}

function setSignaturePlace () {
  if (typeof beforeStart.value.meetingAddress !== 'undefined') {
    changeSettings.value.signaturePlace.value = beforeStart.value.meetingAddress.city.value as string;
  }
}

async function submitStep (signMethod: string) {
  const { contractUuid } = route.params;

  try {
    error.value = false;
    validating.value = true;
    showPrintConfirmationModal.value = false;
    showDigitalSignModal.value = false;

    const isDigital = signMethod === 'digital';

    if (isDigital) {
      redirecting.value = true;
    } else {
      submitting.value = true;
    }

    const signature = [
      {
        signer: 'distributor',
        signMethod,
      },
      {
        signer: 'client',
        signMethod,
      },
    ];

    await signatures.update(contractUuid, JSON.stringify(signature), true);

    await submitContract(state, {
      contractUuid: contractUuid as string,
      throwOnErrors: true,
      signature,
    });

    if (isDigital) {
      // @ts-expect-error: TODO: fix this
      signingLink.value = signingLinks.value[0].value;
      window.location.href = signingLink.value || '';
    } else {
      await router.push({
        name: 'changeRequest.thankYouPage',
        params: {
          contractUuid: route.params.contractUuid,
        },
      });
    }
  } catch (e: any) {
    error.value = true;
    submitting.value = false;
    scrollToError();

    console.error('Něco se nepovedlo', {
      response: e?.response,
    });

    throw new Error(`Change request ${contractUuid} with signMethod ${signMethod} submission error.
          Status code ${e?.response?.status}, Message: ${e?.response?.message}`);
  } finally {
    validating.value = false;
  }
}

async function sendDraftToken (signMethod: string) {
  const { contractUuid } = route.params;

  try {
    error.value = false;
    validating.value = true;

    await validateFields({
      contractUuid,
      throwOnErrors: true,
      fieldPaths: fields.value,
    });

    validating.value = false;

    const { data } = await draftTokens.create(contractUuid as string, signMethod);

    draftToken.value.value.value = data.draftToken;
    draftToken.value.value.expiresAt = data.validTo;

    const signature = [
      {
        signer: 'distributor',
        signMethod,
      },
      {
        signer: 'client',
        signMethod,
      },
    ];

    await signatures.update(contractUuid, JSON.stringify(signature), false, true);

    await router.push({
      name: 'changeRequest.thankYouSignaturePage',
      params: {
        contractUuid: route.params.contractUuid,
      },
    });
  } catch (e: any) {
    error.value = true;

    scrollToError();

    console.error('Něco se nepovedlo', {
      response: e?.response,
    });
  } finally {
    validating.value = false;
  }
}

function setContractSignatureDate () {
  changeSettings.value.signatureDate.value = useDateTimeFormat(new Date(), 'yyyy-MM-dd');
}

onMounted(() => {
  setContractSignatureDate();

  if (beforeStart.value.isTiedAgent.value === true
    && (changeSettings.value.signaturePlace.value === null || changeSettings.value.signaturePlace.value === '')) {
    setSignaturePlace();
  }

  if (route.query.draftToken) {
    isRedirected.value = true;
  }
});
</script>

<template>
  <div dusk="recapitulation">
    <ChangeRequestStepper current-step="recapitulation" />

    <div class="container--sm mt-20">
      <h2>Rekapitulace</h2>

      <LitAlert
        v-if="error"
        class="mb-20"
        alert-type="danger"
        dusk="contract-sending-failure"
      >
        <div>
          Při odesílání smlouvy došlo k chybě. Překontrolujte si všechny zadané údaje
          a zkuste to znovu. V případě, že se chyba bude opakovat nás prosím kontaktujte.
        </div>
      </LitAlert>

      <!-- distributor information -->
      <table class="mb-16">
        <thead>
          <tr>
            <th
              colspan="2"
            >
              <h3>{{ labels.distributorData }}</h3>
            </th>
            <th>
              <router-link
                :to="{ name: 'changeRequest.beforeStart' }"
                class="edit-section"
              >
                <LitIconSvg icon-name="edit_green" />

                Upravit
              </router-link>
            </th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>Jméno</td>
            <td
              colspan="2"
            >
              {{ store.beforeStart.distributor.firstName.value }}
            </td>
          </tr>
          <tr>
            <td>Příjmení</td>
            <td
              colspan="2"
            >
              {{ store.beforeStart.distributor.lastName.value }}
            </td>
          </tr>
          <tr>
            <td>E-mail</td>
            <td
              colspan="2"
            >
              {{ store.beforeStart.distributor.email.value }}
            </td>
          </tr>
          <tr>
            <td>S1</td>
            <td
              colspan="2"
            >
              {{ store.beforeStart.s1.value }}
            </td>
          </tr>

          <tr>
            <td>S2</td>
            <td
              colspan="2"
            >
              {{ store.beforeStart.s2.value }}
            </td>
          </tr>
          <tr v-if="store.beforeStart.s3.value !== null && store.beforeStart.s3.value !== ''">
            <td>S3</td>
            <td
              colspan="2"
            >
              {{ store.beforeStart.s3.value }}
            </td>
          </tr>
        </tbody>
      </table>

      <hr class="my-30">

      <!-- contact information -->
      <table
        class="mb-16"
      >
        <thead>
          <tr>
            <th
              colspan="2"
            >
              <h3>{{ labels.contactInformation }}</h3>
            </th>
            <th>
              <router-link
                :to="{ name: 'changeRequest.clientData' }"
                class="edit-section"
              >
                <LitIconSvg icon-name="edit_green" />

                Upravit
              </router-link>
            </th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>Jméno</td>
            <td
              colspan="2"
            >
              {{ store.clientData.firstName.value }}
            </td>
          </tr>

          <tr>
            <td>Příjmení</td>
            <td
              colspan="2"
            >
              {{ store.clientData.lastName.value }}
            </td>
          </tr>

          <tr>
            <td v-if="isClientFromCzechia">
              Rodné číslo
            </td>
            <td v-else>
              Číslo pojištěnce
            </td>
            <td
              colspan="2"
            >
              {{ store.clientData.personalIdNumber.value }}
            </td>
          </tr>

          <tr v-if="!isClientFromCzechia">
            <td>Datum narození</td>
            <td
              colspan="2"
            >
              {{ store.clientData.birthDate.value }}
            </td>
          </tr>

          <tr>
            <td>Číslo smlouvy</td>
            <td
              colspan="2"
            >
              {{ store.clientData.contractNumber.value }}
            </td>
          </tr>

          <tr>
            <td>E-mail</td>
            <td
              colspan="2"
            >
              {{ store.clientData.email.value }}
            </td>
          </tr>
        </tbody>
      </table>

      <hr class="my-30">

      <!-- signer -->
      <table
        v-if="!isParticipant"
        class="mb-16"
      >
        <thead>
          <tr>
            <th
              colspan="2"
            >
              <h3>Údaje o {{ signerLabel }}</h3>
            </th>
            <th>
              <router-link
                :to="{ name: 'changeRequest.clientData' }"
                class="edit-section"
              >
                <LitIconSvg icon-name="edit_green" />

                Upravit
              </router-link>
            </th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>Jméno</td>
            <td
              colspan="2"
            >
              {{ store.signer.firstName.value }}
            </td>
          </tr>

          <tr>
            <td>Příjmení</td>
            <td
              colspan="2"
            >
              {{ store.signer.lastName.value }}
            </td>
          </tr>

          <tr>
            <td v-if="isSignerFromCzechia">
              Rodné číslo
            </td>
            <td v-else>
              Číslo pojištěnce
            </td>
            <td
              colspan="2"
            >
              {{ signer.personalIdNumber.value }}
            </td>
          </tr>

          <tr v-if="!isSignerFromCzechia">
            <td>Datum narození</td>
            <td
              colspan="2"
            >
              {{ store.signer.birthDate.value }}
            </td>
          </tr>
          <tr>
            <td>Doklad totožnosti</td>
            <td
              colspan="2"
            >
              {{ useDocumentTypeTranslation(store.signer.document.type.value) }}
            </td>
          </tr>
          <tr v-if="store.signer.document.type.value === 'anotherDoc'">
            <td>Název dokladu</td>
            <td
              colspan="2"
            >
              {{ store.signer.document.name.value }}
            </td>
          </tr>
          <tr>
            <td>Číslo dokladu</td>
            <td
              colspan="2"
            >
              {{ store.signer.document.number.value }}
            </td>
          </tr>
          <tr>
            <td>Doklad je platný do</td>
            <td
              colspan="2"
            >
              {{ store.signer.document.validUntil.value }}
            </td>
          </tr>
          <tr>
            <td>Doklad vydal</td>
            <td
              colspan="2"
            >
              {{ store.signer.document.issuedBy.value }}
            </td>
          </tr>
        </tbody>
      </table>

      <hr
        v-if="!isParticipant"
        class="my-30"
      >

      <!-- contract settings -->
      <table
        v-if="displayContractSettingsSection"
        class="mb-16"
      >
        <thead>
          <tr>
            <th
              colspan="2"
            >
              <h3>{{ labels.contractSettings }}</h3>
            </th>
            <th>
              <router-link
                :to="{ name: 'changeRequest.contractSettings' }"
                class="edit-section"
              >
                <LitIconSvg icon-name="edit_green" />

                Upravit
              </router-link>
            </th>
          </tr>
        </thead>

        <tbody>
          <template v-if="store.strategy.changeRequired.value">
            <tr>
              <td>Změna strategie spoření</td>
              <td
                colspan="2"
              >
                {{ strategy }}
              </td>
            </tr>
            <tr
              v-if="store.strategy.selectedStrategy.value === 'customFundCombination'"
            >
              <td>
                Zvolená kombinace fondů
              </td>
              <td>
                <ul class="list-unstyled">
                  <li v-show="store.strategy.fundCombination.value.esg !== 0">
                    Zodpovědný fond: {{ store.strategy.fundCombination.value.esg }}%
                  </li>
                  <li v-show="store.strategy.fundCombination.value.balanced !== 0">
                    Vyvážený fond: {{ store.strategy.fundCombination.value.balanced }}%
                  </li>
                  <li v-show="store.strategy.fundCombination.value.conservative !== 0">
                    Konzervativní fond: {{ store.strategy.fundCombination.value.conservative }}%
                  </li>
                  <li v-show="store.strategy.fundCombination.value.dynamic !== 0">
                    Dynamický fond: {{ store.strategy.fundCombination.value.dynamic }}%
                  </li>
                  <li v-show="store.strategy.fundCombination.value.pension !== 0">
                    Fond pro penzi: {{ store.strategy.fundCombination.value.pension }}%
                  </li>
                </ul>
              </td>
            </tr>
          </template>
          <template v-if="store.contractSettings.contributionChangeRequired.value">
            <tr>
              <td>Výše měsíčního příspěvku</td>
              <td
                colspan="2"
              >
                {{ store.contractSettings.contribution.amount.value }} Kč/měsíc
              </td>
            </tr>
            <tr v-if="store.contractSettings.contribution.effectiveDate.value !== null">
              <td>Úprava měsíčního příspěvku od</td>
              <td
                colspan="2"
              >
                {{ formatDate(store.contractSettings.contribution.effectiveDate.value) }}
              </td>
            </tr>
          </template>
          <template v-if="store.contractSettings.taxOptimizationChangeRequired.value">
            <tr>
              <td>Úprava daňové optimalizace</td>
              <td
                colspan="2"
              >
                {{ store.contractSettings.taxOptimization.value.value === true ? 'Sjednat' : 'Zrušit' }}
              </td>
            </tr>
            <tr v-if="store.contractSettings.taxOptimization.effectiveDate.value !== null">
              <td>Úprava daňové optimalizace od</td>
              <td
                colspan="2"
              >
                {{ formatDate(store.contractSettings.taxOptimization.effectiveDate.value) }}
              </td>
            </tr>
          </template>
          <template v-if="store.contractSettings.employerChangeRequired.value">
            <tr>
              <td>Upravit informaci o zaměstnavateli</td>
              <td
                colspan="2"
              >
                Ano
              </td>
            </tr>
            <tr>
              <td>Úprava</td>
              <td
                colspan="2"
              >
                {{ translateEmployerAction(store.contractSettings.employer.action.value as string) }}
              </td>
            </tr>
            <tr v-if="store.contractSettings.employer.action.value !== 'remove'">
              <td>Název zaměstnavatele</td>
              <td
                colspan="2"
              >
                {{ store.contractSettings.employer.name.value }}
              </td>
            </tr>

            <tr v-if="store.contractSettings.employer.vat.value !== null">
              <td>IČO zaměstnavatele</td>
              <td
                colspan="2"
              >
                {{ store.contractSettings.employer.vat.value }}
              </td>
            </tr>
            <tr v-if="store.contractSettings.employer.effectiveDate.value !== null">
              <td>Úprava příspěvku zaměstnavatele od</td>
              <td
                colspan="2"
              >
                {{ formatDate(store.contractSettings.employer.effectiveDate.value) }}
              </td>
            </tr>
          </template>
        </tbody>
      </table>

      <hr
        class="my-30"
      >

      <!-- legal requirements -->
      <table
        v-if="store.legalRequirements.changeRequired.value"
        class="mb-16"
      >
        <thead>
          <tr>
            <th
              colspan="2"
            >
              <h3>{{ labels.legalRequirements }}</h3>
            </th>
            <th>
              <router-link
                :to="{ name: 'changeRequest.legalRequirements' }"
                class="edit-section"
              >
                <LitIconSvg icon-name="edit_green" />

                Upravit
              </router-link>
            </th>
          </tr>
        </thead>

        <tbody>
          <tr v-if="store.legalRequirements.isPoliticallyExposed.value !== null">
            <td>Politicky exponovaná osoba</td>
            <td
              colspan="2"
            >
              {{ store.legalRequirements.isPoliticallyExposed.value === true ? 'Ano' : 'Ne' }}
            </td>
          </tr>
          <tr v-if="store.legalRequirements.paysTaxesInCzechia.value !== null">
            <td>Odvádíte daně v ČR</td>
            <td
              colspan="2"
            >
              {{ store.legalRequirements.paysTaxesInCzechia.value === true ? 'Ano' : 'Ne' }}
            </td>
          </tr>
          <tr v-if="store.legalRequirements.sourceOfIncome.value !== null && store.legalRequirements.sourceOfIncome.value !== ''">
            <td>Úprava zdroje příjmů</td>
            <td
              colspan="2"
            >
              {{ income }}
            </td>
          </tr>
          <tr v-if="store.legalRequirements.occupation.value !== null && store.legalRequirements.occupation.value !== ''">
            <td>Úprava povolání</td>
            <td
              colspan="2"
            >
              {{ store.legalRequirements.occupation.value }}
            </td>
          </tr>
          <tr v-if="store.legalRequirements.effectiveDate.value !== null">
            <td>Úprava zákonných požadavků platná od</td>
            <td
              colspan="2"
            >
              {{ formatDate(store.legalRequirements.effectiveDate.value) }}
            </td>
          </tr>
        </tbody>
      </table>

      <hr
        v-if="store.legalRequirements.changeRequired.value"
        class="my-30"
      >

      <!-- personal and contact data -->
      <table
        v-if="store.personalData.changeRequired.value"
        class="mb-16"
      >
        <thead>
          <tr>
            <th
              colspan="2"
            >
              <h3>{{ labels.personalData }}</h3>
            </th>
            <th>
              <router-link
                :to="{ name: 'changeRequest.personalData' }"
                class="edit-section"
              >
                <LitIconSvg icon-name="edit_green" />

                Upravit
              </router-link>
            </th>
          </tr>
        </thead>

        <tbody>
          <tr v-if="store.personalData.firstName.value !== null && store.personalData.firstName.value !== ''">
            <td>Jméno</td>
            <td
              colspan="2"
            >
              {{ store.personalData.firstName.value }}
            </td>
          </tr>
          <tr v-if="store.personalData.lastName.value !== null && store.personalData.lastName.value !== ''">
            <td>Příjmení</td>
            <td
              colspan="2"
            >
              {{ store.personalData.lastName.value }}
            </td>
          </tr>
          <tr v-if="store.personalData.phoneNumber.value !== null && store.personalData.phoneNumber.value !== ''">
            <td>Telefonní číslo</td>
            <td
              colspan="2"
            >
              +{{ store.personalData.phonePrefix.value }} {{ store.personalData.phoneNumber.value }}
            </td>
          </tr>
          <tr v-if="store.personalData.permanentAddress.query.value !== null">
            <td>Trvalá adresa</td>
            <td
              colspan="2"
            >
              <ul class="list-unstyled">
                <li>
                  {{
                    store.personalData.permanentAddress.street.value
                      ? store.personalData.permanentAddress.street.value
                      : store.personalData.permanentAddress.city.value
                  }} {{ store.personalData.permanentAddress.streetNumber.value }}
                </li>
                <li>{{ store.personalData.permanentAddress.city.value }}</li>
                <li>{{ store.personalData.permanentAddress.zip.value }}</li>
                <li>{{ useCountryTranslation(store.personalData.permanentAddress.country.value) }}</li>
              </ul>
            </td>
          </tr>
          <tr v-if="store.personalData.mailingAddress.query.value !== null">
            <td>Korespondenční adresa</td>
            <td
              colspan="2"
            >
              <ul class="list-unstyled">
                <li>
                  {{
                    store.personalData.mailingAddress.street.value
                      ? store.personalData.mailingAddress.street.value
                      : store.personalData.mailingAddress.city.value
                  }} {{ store.personalData.mailingAddress.streetNumber.value }}
                </li>
                <li>{{ store.personalData.mailingAddress.city.value }}</li>
                <li>{{ store.personalData.mailingAddress.zip.value }}</li>
                <li>{{ useCountryTranslation(store.personalData.mailingAddress.country.value) }}</li>
              </ul>
            </td>
          </tr>
          <tr v-if="store.personalData.effectiveDate.value !== null">
            <td>Úprava kontaktních a osobních údajů platná od</td>
            <td
              colspan="2"
            >
              {{ formatDate(store.personalData.effectiveDate.value) }}
            </td>
          </tr>
        </tbody>
      </table>

      <hr
        v-if="store.personalData.changeRequired.value"
        class="my-30"
      >

      <div
        v-if="displayClientStatementField && store.isDPS"
        class="mb-20"
      >
        <h3>Vyjádření účastníka</h3>

        <ServerValidatedFormField
          v-slot="{ value, input, errors, validate }"
          namespace="changeRequest"
          field-path="changeSettings.clientStatement"
          :debounce="0"
          @validate="validateField"
        >
          <LitTextarea
            class="mb-20"
            name="clientStatement"
            dusk="client-statement"
            label="Poznámka účastníka do záznamu z jednání"
            placeholder="Např. Zaujalo mne ...."
            :resize="true"
            :model-value="value"
            :errors="errors"
            @update:model-value="input"
            @blur="validate($event.target.value)"
          >
            <template #label>
              Poznámka účastníka do záznamu z jednání
            </template>
          </LitTextarea>
        </ServerValidatedFormField>

        <hr class="my-30">
      </div>

      <ServerValidatedFormField
        v-slot="{ input, errors }"
        namespace="changeRequest"
        field-path="changeSettings.signatureDate"
        :validate-on-input="true"
        :debounce="0"
      >
        <LitInput
          class="mb-20"
          name="signDate"
          dusk="sign-date"
          disabled
          label="Datum podpisu dodatku"
          :read-only="true"
          :model-value="signatureDate"
          :errors="errors"
          @update:model-value="input"
        />
      </ServerValidatedFormField>

      <ServerValidatedFormField
        v-slot="{ value, input, errors }"
        namespace="changeRequest"
        field-path="changeSettings.signaturePlace"
        :validate-on-input="true"
        :debounce="0"
        @validate="validateField"
      >
        <LitInput
          class="mb-20"
          name="signPlace"
          dusk="sign-place"
          label="Místo podpisu dodatku"
          :model-value="value"
          :errors="errors"
          @update:model-value="input"
        />
      </ServerValidatedFormField>
    </div>

    <div
      :class="['container--sm', 'mt-30', allowAnotherDeviceSignature ? 'mb-90' : 'mb-30']"
    >
      <div
        class="row"
        dusk="email-alert"
      >
        <div class="col-md-6">
          <MeetingRecordPreview
            v-if="store.beforeStart.isTiedAgent.value && showMeetingRecordPreview && store.isDPS"
            meeting-state="changeRequestCompletedPreview"
          />
        </div>
      </div>

      <div class="btn-recapitulation flex flex-between content-center my-30">
        <LitButton
          variant="outline"
          :to="{ name: 'changeRequest.contractSettings' }"
        >
          <template #before>
            <ArrowLeft :height="20" :width="20" />
          </template>

          Zpět
        </LitButton>

        <div v-if="!isCommittee">
          <div>
            <LitButton
              v-if="!isRedirected"
              class="text-uppercase"
              @click="openModal('digital')"
            >
              Podepsat elektronicky
            </LitButton>
            <LitButton
              v-else
              class="text-uppercase"
              :disabled="isSubmitting"
              @click="submitStep('digital')"
            >
              Podepsat elektronicky s&nbsp;účastníkem
            </LitButton>
          </div>
        </div>

        <div v-else>
          <LitButton
            @click="submitStep('offline')"
          >
            VYTISKNOUT
          </LitButton>
        </div>
      </div>

      <div
        dusk="print-info"
        class="print-info"
      >
        <div class="container mt-30">
          <hr>
        </div>

        <PrintOffer
          v-if="!isCommittee"
          form-type="request"
          @show-modal="openModal('offline')"
        />
      </div>

      <ContractSubmissionModal
        type="distribution"
        :model-value="submitting"
      />

      <ContractSubmissionModal
        type="distribution"
        :model-value="redirecting"
        :is-digital="true"
      >
        <template #action>
          <p
            v-if="signingLink !== null"
            class="text-loading text-center"
          >
            Pokud nedojde k přesměrování, klikněte prosím na
            <a
              :href="signingLink"
            >
              tento odkaz
            </a>.
          </p>
        </template>
      </ContractSubmissionModal>

      <ContractSigningModal
        v-model="showDigitalSignModal"
        :disabled="isSubmitting"
        @sign-with-phone="sendDraftToken('digital')"
        @sign-later="sendDraftToken('digital')"
        @sign-with-computer="submitStep('digital')"
      >
        <template #header>
          <h2>Jak chcete dodatek ke smlouvě podepsat?</h2>
        </template>
      </ContractSigningModal>

      <FinishWithPaperPrintModal
        form-type="changeRequest"
        :model-value="showPrintConfirmationModal"
        :disabled="isSubmitting"
        @close="showPrintConfirmationModal = false"
        @click="submitStep('offline')"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@sass/tools/variables';
@import '@sass/tools/functions';  /* stylelint-disable-line no-extra-semicolons */
@import '@sass/tools/mixins';

$breakpoint-mobile-small: 400px;

@include media(max, $xs) {
  .btn-recapitulation {
    flex-direction: column-reverse;
    gap: 16px;
    align-items: center;
  }
}

@include media-between($xs, $sm) {
  .lit-btn {
    font-size: 14px;
  }
}

table {
  display: table !important;
  width: 100%;

  thead {
    th {
      font-size: 1.4rem;
      font-weight: 400;
      padding-bottom: .7rem;
      padding-left: .5rem;

      h3 {
        margin: 0;
      }

      a {
        font-size: .9rem;
        color: var(--color-green);
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 0 4px;
      }

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        text-align: right;
      }
    }
  }

  tbody {
    td {
      padding: .45rem 0;
      padding-left: 1rem;
      font-weight: 700;
      line-height: 1.4rem;

      &:first-child {
        font-weight: 400;
        width: 50% !important;
        max-width: 600px !important;
        padding-left: 0;
        vertical-align: top;
      }

      ul {
        margin: 0;
      }
    }
  }
}

.pointer {
  cursor: pointer;
}

.flex-v-stretch {
  align-items: stretch;
}

.btn-big {
  margin: 0 !important;
}

.btn-custom {
  align-items: center;
  padding-left: 40px;
  padding-right: 40px;
  margin-right: 1rem !important;
}

.btn-custom-smaller {
  @media screen and (max-width: $breakpoint-mobile-small) {
    padding: 10px;
  }
}

.print-info {
  color: var(--color-grey);

  a {
    color: var(--color-grey);
    text-decoration: underline;

    &:is(:hover, :focus) {
      text-decoration: none;
    }
  }
}
</style>
