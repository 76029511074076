<template>
  <div dusk="select">
    <div :class="['field', disabled ? 'pointer-events-none' : '']">
      <label v-if="label">
        {{ label }}
      </label>

      <div
        class="select__wrapper"
      >
        <div
          ref="selectRef"
          class="select"
          :class="{
            'select--error': error || errors.length > 0,
            'open': isOpen
          }"
          dusk="open-select"
          :tabindex="tabindex"
          @click="toggleSelectVisibility"
          @keyup.enter="isOpen = true"
        >
          <span
            v-if="selectedOption !== undefined"
            :class="['select__text', disabled ? 'disabled' : '']"
          >
            <slot
              name="selected-option"
              v-bind="selectedOption"
            >
              {{ selectedOption.label }}
            </slot>
          </span>

          <i>
            <img
              :src="getIconUrl('arrow_down-green')"
              alt="Ikona šipka dolů"
            >
          </i>

          <TransitionExpand>
            <div
              v-show="isOpen"
              class="select__items"
              :style="{
                left: isNumber(optionsLeft) ? `${optionsLeft}px` : optionsLeft,
                right: isNumber(optionsRight) ? `${optionsRight}px` : optionsRight,
                minWidth: isNumber(optionsMinWidth) ? `${optionsMinWidth}px` : optionsMinWidth
              }"
            >
              <div
                v-if="searchable"
                class="select__filter"
              >
                <input
                  ref="inputSearchRef"
                  v-model="searchQuery"
                  type="text"
                  placeholder="Hledat"
                  @click.stop.prevent
                >
              </div>

              <div class="select__item__wrapper">
                <div
                  v-for="(option, i) of filteredOptions"
                  :key="i"
                  class="select__item"
                  :dusk="`option-${i}`"
                  @click.stop="selectOption(option)"
                >
                  <div>
                    <slot
                      name="select-option"
                      v-bind="option"
                    >
                      {{ option.label }}
                    </slot>
                  </div>
                </div>
              </div>
            </div>
          </TransitionExpand>
        </div>

        <slot name="after">
          <LitTooltip
            v-if="tooltip"
            :text="tooltip"
            :icon-path="iconTooltip"
          />
        </slot>
      </div>
    </div>

    <div
      class="field-info"
      :class="{
        'field-info--m': props.label
      }"
    >
      <small
        v-if="description"
        class="description"
      >
        {{ description }}
      </small>

      <small
        v-if="error"
        class="error"
      >
        {{ error }}
      </small>

      <small
        v-if="errors.length > 0"
        class="error"
      >
        <span
          v-for="(validationError, index) in errors"
          :key="index"
        >
          {{ validationError }}
        </span>
      </small>
    </div>
  </div>
</template>

<script setup lang="ts">
import type {
  PropType,
} from 'vue';

import { onClickOutside } from '@vueuse/core';
import { isEqual } from 'lodash';

import {
  computed,
  nextTick,
  onMounted,
  ref,
} from 'vue';
import { getIconUrl } from '../../utils';

import TransitionExpand from '../Transition/TransitionExpand.vue';
import LitTooltip from './LitTooltip.vue';

const props = defineProps({
  options: {
    type: Array as PropType<Record<string, unknown>[]>,
    required: true,
  },

  label: {
    type: String,
    default: '',
  },

  tabindex: {
    type: Number,
    default: 0,
  },

  iconTooltip: {
    type: String,
    default: '',
  },

  tooltip: {
    type: String,
    default: '',
  },

  description: {
    type: String,
    default: '',
  },

  error: {
    type: String,
    default: '',
  },

  errors: {
    type: Array,
    required: false,
    default: () => ([]),
  },

  disabled: {
    tpe: Boolean,
    default: false,
  },

  modelValue: {
    type: [Object, String, Number],
    default: null,
  },

  searchable: {
    type: Boolean,
    default: false,
  },

  optionsMinWidth: {
    type: [Number, String],
    default: 'auto',
  },

  optionsLeft: {
    type: [Number, String],
    default: -1,
  },

  optionsRight: {
    type: [Number, String],
    default: -1,
  },

  optionLabelKey: {
    type: String,
    default: 'label',
  },

  optionValueKey: {
    type: String,
    default: 'value',
  },

  returnObject: {
    type: Boolean,
    default: false,
  },

  preselectFirst: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits([
  'update:modelValue',
]);

const selectRef = ref();
const inputSearchRef = ref();
const isOpen = ref(false);
const searchQuery = ref('');

function isNumber (number: unknown) {
  if (typeof number === 'number') {
    return true;
  }

  return false;
}

const reshapedOptions = computed(() => props.options.map((option) => {
  const reshaped: {
    value: unknown
    label: string
  } = {
    value: '',
    label: '',
  };

  const isString = typeof option === 'string';

  if (isString || isNumber(option)) {
    reshaped.value = option;
    reshaped.label = option as any;
  } else {
    reshaped.value = option[props.optionValueKey];
    reshaped.label = option[props.optionLabelKey] as string;
  }

  return reshaped;
}));

const selectedOption = computed({
  get () {
    if (!props.modelValue) {
      return undefined;
    }

    return reshapedOptions.value.find((option) => {
      if (props.returnObject) {
        return isEqual(option.value, (props.modelValue as Record<string, unknown>)?.value);
      }

      return option.value === props.modelValue;
    });
  },

  set (option) {
    if (props.returnObject) {
      emit('update:modelValue', option);
    } else {
      emit('update:modelValue', option?.value);
    }
  },
});

function selectDefault () {
  if (reshapedOptions.value.length > 0) {
    const [firstOption] = reshapedOptions.value;
    selectedOption.value = firstOption;
  }
}

function selectOption (option: any) {
  isOpen.value = false;
  searchQuery.value = '';

  selectedOption.value = option;
}

function toggleSelectVisibility () {
  isOpen.value = !isOpen.value;

  if (props.searchable) {
    nextTick(() => inputSearchRef.value.focus());
  }
}

function closeSelect () {
  isOpen.value = false;
}

const filteredOptions = computed(() => reshapedOptions.value.filter(({ label }) => {
  const query = searchQuery.value.toLowerCase();

  if (query === '') {
    return true;
  }

  if (label === '') {
    return false;
  }

  return (label || '')
    .toString()
    .toLowerCase()
    .includes(query);
}));

onClickOutside(selectRef, () => {
  closeSelect();
});

onMounted(() => {
  if (props.modelValue === null && props.preselectFirst) {
    selectDefault();
  }
});
</script>

<style lang="scss" scoped>
  @import '@sass/tools/mixins';
  @import '@sass/tools/variables';

  .field {
    @include media(min, $sm) {
      display: flex;
    }

    &:focus {
      outline: none;
    }

    &-info {
      display: flex;
      flex-flow: column;
      max-width: 330px;

      &--m {
        @include media(min, $sm) {
          margin-left: 187px;
        }
      }
    }
  }

  label {
    margin-bottom: 5px;
    display: block;
    font-weight: $normal;

    @include media(min, $sm) {
      flex-basis: 177px;
      margin-right: 10px;
      display: flex;
      align-items: center;
      margin-bottom: 0;
    }
  }

  .select {
    display: flex;
    background-color: getColor(bg);
    border-radius: 3px;
    min-height: $input-height;
    border: 1px solid getColor(light-blue);
    position: relative;
    flex-basis: 100%;
    outline-offset: 2px;

    @include media(min, $sm) {
      flex-basis: 330px;
    }

    &:hover {
      cursor: pointer;
    }

    &:focus {
      outline: none;
      border-color: getColor(blue);
    }

    &__text {
      padding: 0 rem($input-height) 0 rem(11);
      min-height: $input-height;
      width: 100%;
      display: flex;
      align-items: center;
      font-size: $root;
      font-weight: $bold;
      color: getColor(green);
    }

    &__wrapper {
      display: flex;
      flex-grow: 1;
      align-items: center;
      position: relative;
    }

    &__items {
      position: absolute;
      z-index: 5;
      top: calc(100% + 1px);
      font-weight: $bold;
      color: getColor(green);
      background-color: getColor(white);
      border: 1px solid getColor(light-blue);
      border-radius: 0 0 3px 3px;
    }

    &__item {
      padding: 7.5px;

      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }

      &:last-child {
        padding-bottom: 15px;
      }

      &:first-child {
        padding-top: 15px;
      }

      &__wrapper {
        max-height: 230px;
        overflow-y: auto;
      }
    }

    &__filter {
      padding: 15px 0;
      margin: 0 7.5px;
      border-bottom: 1px solid getColor(light-blue);

      input {
        padding: 0 rem(11);
        width: 100%;
        min-height: 40px;
        border: 1px solid getColor(light-blue);
        border-radius: 3px;
        background-color: getColor(bg);
        color: getColor(default);

        &::-ms-clear {
          display: none;
        }

        &:focus {
          border-color: getColor(blue);
          outline: none;
        }

        &::placeholder {
          color: getColor(light-blue);
        }
      }
    }

    &.open {
      border-color: getColor(blue);
      border-radius: 3px 3px 0 0;

      i {
        transform: rotate(180deg);
      }
    }

    i {
      position: absolute;
      right: 0;
      width: $input-height;
      height: $input-height;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 0;
    }

    &--error {
      border-color: getColor(danger, 1);
    }
  }

  // Description + Error
  .description,
  .error {
    display: block;
    padding: .125em 0;
    margin-top: .25em;
  }

  // Required field + Error
  .required,
  .error {
    color: getColor(danger, 1);
  }

  .required {
    margin: 0 auto 0 .125em;
  }

  .disabled {
    color: rgba(131, 151, 179, 1);
    pointer-events: none;
  }

  .pointer-events-none {
    pointer-events: none;
  }
</style>
