import { acceptHMRUpdate, defineStore } from 'pinia';
import { computed, reactive, toRefs } from 'vue';
import { state as changeRequestState } from './state';
import type {
  StoreValidationParams,
} from '@/js/stores/utils';
import gtm from '@/js/services/gtm';

import {
  validateStoreField,
  validateStoreFields,
} from '@/js/stores/utils';

import { calculateAge, canEnterRent, personalIdNumber } from '@/js/utils';
import { parse } from 'date-fns';

export const useChangeRequestStore = defineStore('changeRequest', () => {
  const state = reactive(changeRequestState);

  const participantsBirthDay = computed(() => {
    try {
      return personalIdNumber(state.clientData.personalIdNumber.value)?.birthDay;
    } catch (e) {
      console.warn(e);

      return undefined;
    }
  });

  const birthDay = computed(() => {
    if (state.clientData.permanentAddressArea.value !== 'cz' && state.clientData.birthDate.value !== null) {
      return parse(state.clientData.birthDate.value, 'dd. MM. yyyy', new Date());
    }

    return participantsBirthDay.value;
  });

  const isDPS = computed(() => {
    const contractNumber = String(state.clientData.contractNumber.value);

    return contractNumber && contractNumber.startsWith('6');
  });

  const clientAge = computed(() => {
    return birthDay.value ? calculateAge(birthDay.value.toISOString()) : null;
  });

  async function validateField ({ contractUuid, fieldPath, pathToValidate, value, urlPath: _urlPath }: StoreValidationParams) {
    if (state.rehydrated.value === false) {
      console.warn('Skipping validation as store has not been hydrated yet');
      return;
    }

    try {
      await validateStoreField({
        contractUuid,
        fieldPath,
        // This is used for nominees array validation
        // where fieldPath is nominees.0.foo.value, but we need to trigger validation
        // for nominees.*.foo.value but reset errors for the nominees.0.foo.errors
        pathToValidate,
        throwOnErrors: true,
        value,
        state,
      });
    } catch (e: any) {
      if (!e.response) {
        throw e;
      }

      const { errors } = e.response.data;

      if (errors) {
        const keys = Object.keys(errors);

        // We want only the first one as we validate single field
        const [firstError] = errors[keys[0]];

        gtm.onFormFieldError(fieldPath, firstError);
      }
    }
  }

  async function validateFields ({ contractUuid, fields, throwOnErrors, fieldPaths, documentsSent = false }: {
    contractUuid: string | string[]
    fields?: { path: string, value?: unknown, pathToValidate?: string }[]
    fieldPaths?: string | string[]
    throwOnErrors: boolean
    documentsSent?: boolean
  }) {
    return await validateStoreFields(state, {
      contractUuid,
      fields,
      throwOnErrors,
      fieldPaths,
      documentsSent,
    });
  }

  const isCommittee = computed(() => state.signer.type.value === 'committee');

  function updatePreferConservativeFund () {
    state.strategy.preferConservativeFund.value = canEnterRent(birthDay.value) || false;
  }

  function resetPensionQuestionnaire () {
    const path = state.strategy;
    const questionnaire = state.questionnaire;

    path.selectCustomStrategy.value = path.selectCustomStrategy.defaultValue;
    path.showQuestionnaire.value = path.showQuestionnaire.defaultValue;
    path.totalPoints.value = path.totalPoints.defaultValue;
    path.selectedStrategy.value = path.selectedStrategy.defaultValue;
    path.fundCombination.value = path.fundCombination.defaultValue;
    path.investmentRiskCategory.value = path.investmentRiskCategory.defaultValue;
    path.excludeConservativeFund.value = path.excludeConservativeFund.defaultValue;
    path.recommendedStrategies.value = path.recommendedStrategies.defaultValue;

    const questionFields = ['answer', 'openAnswer', 'category'];
    for (let i = 1; i <= 10; i++) {
      const questionKey = `question${i}` as keyof typeof questionnaire;
      const question = questionnaire[questionKey];

      questionFields.forEach((field) => {
        const questionField = question[field as keyof typeof question];
        questionField.value = questionField.defaultValue;
      });
    }
  }

  return {
    ...toRefs(state),
    isCommittee,
    participantsBirthDay,
    clientAge,
    isDPS,
    validateField,
    validateFields,
    updatePreferConservativeFund,
    resetPensionQuestionnaire,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useChangeRequestStore, import.meta.hot));
}
