<template>
  <div dusk="thank-you">
    <section class="benefits bg-blue pt-30 pb-10" />

    <div class="container--sm my-30">
      <TheHeading>Děkujeme, že spoříte s ČSOB Penzijní společností</TheHeading>
    </div>

    <div class="container--sm mb-20">
      <h3 class="my-20">
        Nyní dodatek stáhněte, vytiskněte a s účastníkem podepište.
      </h3>

      <p
        v-if="isCommittee"
        class="mb-20"
      >
        <ul>
          <li>Přiložte kopii listiny na jejímž základě opatrovník jedná.</li>
          <li>Dodatek a listinu odešlete neprodleně na adresu: </li>
        </ul>
      </p>

      <p
        v-else-if="store.beforeStart.isTiedAgent.value"
        class="mb-20"
      >
        Vytiskněte prosím 2x:
        <ul>
          <li>jedno vyhotovení podpsané vámi a účastníkem předejte účastníkovi,</li>
          <li>druhé neprodelně zašlete na adresu:</li>
        </ul>
      </p>

      <p
        v-else
        class="mb-20"
      >
        Vytiskněte prosím 3x:
        <ul>
          <li>jedno vyhotovení podpsané vámi a účastníkem předejte účastníkovi,</li>
          <li>druhé vyhotovení si ponechte,</li>
          <li>třetí neprodelně zašlete na adresu:</li>
        </ul>
      </p>

      <p class="mb-20">
        ČSOB Penzijní společnost, a. s.<br>
        Collinova 573<br>
        500 03  Hradec Králové
      </p>

      <p
        v-if="store.beforeStart.isTiedAgent.value"
        class="mb-20"
      >
        Dodatek ke smlouvě <span v-if="isMeetingRecordExpected">a Záznam z jednání byly pro informaci v PDF zaslány</span><span v-else>byl pro informaci v PDF zaslán</span> také na e-mail účastníka.
      </p>
      <p v-else>
        Dodatek ke smlouvě byl zároveň zaslán vám i účastníkovi do e-mailu k případnému tisku a podpisu.
      </p>

      <LitAlert
        v-if="store.beforeStart.isTiedAgent.value && !showContractHiddenAlert"
        alert-type="warning"
        class="mb-20"
      >
        <strong>Dokumentace již není odesílána na e-mail obchodníka.</strong> V případě ukončení procesu/zavření tohoto okna,
        už nebude možné dokumentaci znovu vytisknout.
      </LitAlert>

      <div v-if="loading">
        <div class="flex flex-center mb-20 flip">
          <img
            :src="getIconUrl('icon-loader_blue')"
            alt="Načítání..."
            class="spin"
          >
        </div>
        <div class="text-loading text-center text-bold">
          Nahráváme ke stažení uzavřený dodatek v PDF, chvíli strpení
        </div>
      </div>

      <LitAlert
        v-if="showContractHiddenAlert"
        class="mb-20"
        alert-type="info"
        dusk="contract-sending-failure"
      >
        <div v-if="!store.beforeStart.isTiedAgent.value">
          Odkaz ke stažení dodatku ke smlouvě již není platný nebo jste jej stáhli již {{ maxDownloadCount }}x.
          K podpisu lze využít dodatku ke smlouvě, který byl zaslán na e-mail účastníka.
        </div>
        <div v-else>
          Odkaz ke stažení dodatku již není platný nebo jste jej stáhli již {{ maxDownloadCount }}x.
          Dodatek však máte vy i účastník v e-mailu.
        </div>
      </LitAlert>

      <div class="flex flex-center">
        <div v-if="contractPdf && remainingAttempts > 0">
          <LitButton
            :href="contractPdf.link"
            as="a"
            download
            @click="downloaded++"
          >
            <template #before>
              <Download :size="20" />
            </template>

            <template #default>
              <div class="flex flex-dir-column">
                <span class="text-uppercase">stáhnout dodatek</span>
                <span class="text-sm text-light"><span v-if="isMeetingRecordExpected">vč. záznamu z jednání, </span>PDF: {{ contractPdf.size }} kB</span>
              </div>
            </template>
          </LitButton>
        </div>
      </div>

      <div
        v-if="contractPdf && remainingAttempts > 0"
        class="mt-30"
      >
        Odkaz ke stažení dodatku ve formátu PDF je
        <strong>platný do {{ accessTokenValidity && useDateTimeFormat(accessTokenValidity) }}</strong>,
        dodatek můžete stáhnout {{ remainingAttempts }}x.
      </div>

      <hr class="py-20 mt-20">

      <div class="flex flex-center">
        <LitButton
          variant="outline"
          :to="{ name: 'distribution.home' }"
        >
          Přejít na hlavní rozcestník
        </LitButton>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Download } from 'lucide-vue-next';
import { storeToRefs } from 'pinia';
import { computed, onMounted, ref, watch } from 'vue';
import { onBeforeRouteLeave } from 'vue-router';
import type { File } from '@/js/stores/types.ts';
import contractFiles from '@/js/api/contractFiles';
import LitAlert from '@/js/components/Base/LitAlert.vue';

import LitButton from '@/js/components/Base/LitButton.vue';
import TheHeading from '@/js/components/TheHeading.vue';
import { useDateTimeFormat } from '@/js/composables/useDateTimeFormat';

import env from '@/js/env';
import { useChangeRequestStore } from '@/js/stores/distibution/changeRequest';

import { resetState } from '@/js/stores/utils';
import { getIconUrl } from '@/js/utils';

const store = useChangeRequestStore();
const { isCommittee, submitResponse } = storeToRefs(store);

const loading = ref(true);

const downloaded = ref(0);
const now = ref(new Date());

const accessToken = computed(() => {
  return submitResponse.value.value?.metadata.accessToken;
});

const isMeetingRecordExpected = computed(() => {
  if (!store.isDPS) {
    return false;
  }

  return store.beforeStart.isTiedAgent.value
    && (store.contractSettings.contributionChangeRequired.value
      || store.contractSettings.taxOptimizationChangeRequired.value
      || store.nominees.changeRequired.value
      || store.strategy.changeRequired.value
    );
});

const accessTokenValidity = computed(() => {
  if (!submitResponse.value.value?.metadata.accessTokenValidity) {
    return null;
  }

  return new Date(submitResponse.value.value?.metadata.accessTokenValidity);
});

const isTokenValid = computed(() => {
  if (accessTokenValidity.value) {
    return accessTokenValidity.value > now.value;
  }

  return false;
});

const maxDownloadCount = computed(() => {
  return submitResponse.value.value
    ?.metadata
    ?.maxDownloadCount ?? 0;
});

const remainingAttempts = computed(() => {
  return maxDownloadCount.value - downloaded.value;
});

const showContractHiddenAlert = computed(() => {
  return !isTokenValid.value || remainingAttempts.value <= 0;
});

function clearLocalContractData () {
  resetState(store.$state, false);
}

onBeforeRouteLeave((to, _from, next) => {
  clearLocalContractData();

  const disallowedPaths = [
    'changeRequest.recapitulation',
  ];

  if (to.name && disallowedPaths.includes(to.name as string)) {
    next({ name: 'distribution.home' });
  } else {
    next();
  }
});

const currentTimeInterval = ref<number | null>(null);

function startTimeMeasuring () {
  currentTimeInterval.value = setInterval(() => {
    now.value = new Date();
  }, 1000);
}

const contractPdf = ref<{ link: string, size: number } | null>(null);

function waitForContractPdf () {
  let attempts = 0;

  const interval = setInterval(async () => {
    const contractId = submitResponse.value.value?.metadata.contractId;
    const files: File[] = await contractFiles.index(contractId);

    attempts += 1;

    const fileType = isMeetingRecordExpected.value ? 'joined-pdf' : 'contract';
    const file: File | undefined = files.find((file) => file.file_type === fileType);

    if (files.length > 0 && file) {
      contractPdf.value = {
        link: `${env.REST_API_URI}/v1/files/${file.id}?token=${accessToken.value}&contractId=${contractId}`,
        size: Math.round(file.file_size / 1024),
      };

      clearInterval(interval);
    } else if (attempts > 10) {
      clearInterval(interval);
    }
  }, 3 * 1000);
}

watch(contractPdf, (newValue) => {
  if (newValue !== null) {
    loading.value = false;
  }
});

watch(isTokenValid, (newValue) => {
  if (!newValue) {
    currentTimeInterval.value && clearInterval(currentTimeInterval.value);
    contractPdf.value = null;
  }
});

onMounted(() => {
  waitForContractPdf();
  startTimeMeasuring();
});
</script>

<style lang="scss" scoped>
@import '@sass/tools/mixins';
@import '@sass/tools/variables';
@import '@sass/tools/functions';

.btn-meeting-record {
  padding: 15px 20px 10px !important;
  align-items: center;
  text-transform: initial;

  .icon_justify {
    margin-left: -20px !important;
  }
}

.btn-contract {
  padding: 11px 30px 3px !important;
  align-items: center;
  text-transform: initial;

  .icon_justify {
    margin-left: -30px !important;
  }
}

.small {
  font-size: 14px;
  text-transform: initial;
  font-weight: initial;
}

.thanksAlert {
  margin-bottom: 0;
  font-size: 1.375rem;
}

.grey {
  color: getColor(grey);
}

.download {
  text-decoration: none;
  align-items: center;
}

.rotate {
  transform: rotate(180deg);
}

.big {
  height: 70px;
}

.text-loading {
  color: #092f68;
}
</style>
