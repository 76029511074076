<script lang="ts" setup>
import { objectEntries } from '@vueuse/core';
import { computed, defineAsyncComponent, reactive, ref } from 'vue';

import LitButton from '@/js/components/Base/LitButton.vue';
import fundsData from '@/js/data/fundsData' with { type: 'json' };

import LitIconSvg from '@/js/components/Base/LitIconSvg.vue';
import LitModal from '@/js/components/Base/LitModal.vue';

const props = withDefaults(defineProps<{
  preferConservativeFund: boolean
  isReinvestmentAllowed: boolean
}>(), {
  preferConservativeFund: false,
  isReinvestmentAllowed: true,
});

defineEmits([
  'update:modelValue',
]);

const LifeCycleStrategyDetail = defineAsyncComponent(() => import('@/js/components/LifeCycleStrategyDetail.vue'));
const FundsChart = defineAsyncComponent(() => import('@/js/components/FundsChart.vue'));

const selectedFund = defineModel<string | null>({ default: null, required: true });

const showStrategyDetail = ref(false);

interface StrategyDetail {
  title: string
  description: {
    short: string
    long: string
  }
  fund: string | null
}

const initialState: StrategyDetail = {
  title: '',
  description: {
    short: '',
    long: '',
  },
  fund: null,
};

const strategyDetail = reactive<StrategyDetail>({ ...initialState });

const filteredFundsData = computed(() => {
  const { reinvestment, ...fundsCopy } = fundsData;

  const finalFundsData = props.isReinvestmentAllowed ? fundsData : fundsCopy;

  return objectEntries(finalFundsData)
    .filter(([key, _value]) => {
      if (props.preferConservativeFund) {
        return key !== 'conservative';
      }

      return key !== 'preferredConservativeFund';
    })
    .map(([_key, value]) => ({ ...value }))
    .toSorted((a, b) => {
      const order = ['konzervativní', 'vyvážený', 'dynamický'];

      const typeComparison = order.indexOf(a.typeText.toLowerCase()) - order.indexOf(b.typeText.toLowerCase());
      if (typeComparison !== 0) {
        return typeComparison;
      }

      return a.title.localeCompare(b.title);
    });
});

function handleShowStrategyDetail (item: any) {
  strategyDetail.title = item.titleFull;
  strategyDetail.description = item.content.description.long;

  strategyDetail.fund = item.slug === 'reinvestment' ? null : item.slug;

  showStrategyDetail.value = true;
}
</script>

<template>
  <div class="cols">
    <template
      v-for="item in filteredFundsData"
      :key="`selection-funds${item.slug}`"
    >
      <div
        :class="[
          'contribution',
          { selected: selectedFund === item.slug }
        ]"
      >
        <label :for="`strategySelectionFunds-${item.slug}`">
          <div class="contribution__container">
            <div class="contribution__value">
              <div class="contribution__input">
                <input
                  :id="`strategySelectionFunds-${item.slug}`"
                  :checked="selectedFund === item.slug"
                  type="radio"
                  name="strategySelectionFunds"
                  @input="() => $emit('update:modelValue', item.slug)"
                >

                <span class="contribution__control" />
              </div>
            </div>

            <div class="contribution__label">
              {{ item?.title }}
            </div>

            <div class="contribution__batch-container">
              <span
                v-if="item.label"
                class="contribution__batch"
              >
                {{ item.label }}
              </span>
            </div>
          </div>

          <div class="contribution__info">
            <div :class="item.typeIconName">
              <span class="pr-8 text-bold">
                {{ item.typeText }}
              </span>

              <LitIconSvg
                v-if="item.typeIconName"
                :icon-name="item.typeIconName"
                color="red"
              />
            </div>

            <div class="fond-link" @click.prevent="handleShowStrategyDetail(item)">
              Více o fondu
            </div>
          </div>

        </label>
      </div>
    </template>
  </div>

  <LitModal
    v-model="showStrategyDetail"
    :max-width="838"
    @close="Object.assign(strategyDetail, initialState)"
  >
    <template #header>
      <h3 class="text-left">
        {{ strategyDetail.title }}
      </h3>
    </template>

    <template #body>
      <div v-if="strategyDetail.fund && strategyDetail.fund !== 'lifeCycle'">
        <FundsChart
          :fund-type="strategyDetail.fund"
          class="mb-30"
        />
      </div>

      <p v-if="strategyDetail.fund !== 'lifeCycle'">
        {{ strategyDetail.description }}
      </p>

      <LifeCycleStrategyDetail v-if="strategyDetail.fund === 'lifeCycle'" />
    </template>

    <template #footer>
      <div class="modal__buttons flex-end">
        <LitButton
          variant="outline"
          @click="showStrategyDetail = false"
        >
          <span>Zavřít</span>
        </LitButton>
      </div>
    </template>
  </LitModal>
</template>

<style lang="scss" scoped>
@import '@sass/tools/variables';
@import '@sass/tools/mixins';

.cols {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
}

.bar-1 {
  color: getColor(success, 1);

  svg {
    fill: getColor(success, 1);
  }
}

.bar-2 {
  color: getColor(warning, 1);

  svg {
    fill: getColor(warning, 1);
  }
}

.bar-3 {
  color: getColor(danger, 1);

  svg {
    fill: getColor(danger, 1);
  }
}

.flex-1 {
  flex: 1;
}

.text-bold {
  font-weight: $bold;
  font-size: 13px;
}

.fond-link {
  color: getColor(fund-balanced, .5);
  text-decoration: underline;
  cursor: pointer;
  font-size: $root-mini;

  &:hover {
    text-decoration: none;
  }
}

.cols > * {
  width: 100%;
}

.contribution {
  position: relative;

  &__container {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;

    @include media(max, $xs) {
      flex-wrap: wrap;

      & > .contribution__label {
        flex: 1 1 0;
      }

      & > .contribution__value {
        flex: 0 0 auto;
      }

      & .contribution__batch-container {
        flex: 0 0 100%;
      }
    }
  }

  label {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
    line-height: 1.2;
    padding: 12px 16px;
    border: 1px solid getColor(lighter-grey);
    border-radius: 4px;
    height: 100%;
    transition: all .15s ease-in-out;
    flex-direction: column;

    &:focus-within {
      box-shadow: 0 0 0 .05em #fff, 0 0 .15em .1em getColor(green);
    }

    @include media(min, $sm) {
      flex-direction: row;
    }
  }

  &__value {
    font-weight: $bold;
    display: flex;
  }

  &__info {
    display: flex;
    align-items: center;
    gap: 20px;
    width: 100%;
    justify-content: space-between;
    flex-shrink: 0;

    @include media(min, $sm) {
      width: auto;
      justify-content: flex-end;
    }
  }

  &__label {
    font-weight: $bold;
  }

  &__input {
    display: flex;
    align-items: center;
    flex-shrink: 0;

    input {
      opacity: 0;
      width: 0;
      height: 0;
      position: absolute;
    }
  }

  &__control {
    $control-bg: #d8e8c5;

    width: rem(23);
    height: rem(23);
    border-radius: 50%;
    border: 1px solid $control-bg;
    background: $control-bg;
    display: flex;
    align-items: center;
    justify-content: center;

    &::before {
      content: '';
      width: rem(9);
      height: rem(9);
      border-radius: 50%;
      transition: 180ms transform ease-in-out;
      transform: scale(0);
      background: getColor(green);
    }
  }

  input:checked + &__control {
    background: getColor(white);
    border-color: getColor(green);

    &::before {
      transform: scale(1);
    }
  }

  &__result {
    font-size: $root-mini;
    display: flex;
    justify-content: space-between;
    text-wrap: balance;
  }

  &__batch {
    background: getColor(yellow);
    color: getColor(white);
    font-size: 10px;
    font-weight: $bold;
    padding: 4px 5px;
  }

  &.selected label,
  & label:hover {
    border-color: getColor(green);
    box-shadow: inset 4px 0 0 getColor(green);
  }

  &--error label {
    border-color: getColor(danger, 1);
  }

  &--error.selected label {
    border-color: getColor(danger, 1);
    box-shadow: inset 0 4px 0 getColor(danger, 1);
  }

  &:not(.selected) label:hover {
    cursor: pointer;
  }
}
</style>
