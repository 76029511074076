import { acceptHMRUpdate, defineStore } from 'pinia';
import { computed, reactive, toRefs } from 'vue';
import { isValid, parse } from 'date-fns';

import { state as distributionState } from './state';

import { validateStoreField, validateStoreFields } from '@/js/stores/utils';
import { calculateAge, canEnterRent, personalIdNumber } from '@/js/utils';

import gtm from '@/js/services/gtm';

export const useDistributionStore = defineStore('distribution', () => {
  const state = reactive(distributionState);

  const isParticipant = computed(() => state.signer.type.value === 'participant');
  const isCommittee = computed(() => state.signer.type.value === 'committee');
  const isClientFromCzechia = computed(() => state.personalData.permanentAddressArea.value === 'cz');
  const participantsBirthDay = computed(() => {
    try {
      return personalIdNumber(state.personalData.personalIdNumber.value)?.birthDay;
    } catch (e) {
      console.warn(e);

      return undefined;
    }
  });

  const isFromTransfer = computed(() => typeof state.transferUuid.value !== 'undefined' && state.transferUuid.value !== null);
  const isFromTermination = computed(() => typeof state.terminationUuid.value !== 'undefined' && state.terminationUuid.value !== null);
  const isFromRequest = computed(() => state.transferUuid.value !== null
    || state.terminationUuid.value !== null);
  const transferFromTransformFund = computed(() => state.contractSettings.currentContract.type.value === 'transformFund');

  const birthDay = computed(() => {
    const birthDate = state.personalData.birthDate.value;

    if (state.personalData.permanentAddressArea.value !== 'cz' && birthDate) {
      const possibleFormats = ['dd. MM. yyyy', 'dd.MM.yyyy'];

      let parsedDate = null;

      for (const formatStr of possibleFormats) {
        parsedDate = parse(birthDate, formatStr, new Date());

        if (isValid(parsedDate)) {
          break;
        }
      }

      if (isValid(parsedDate)) {
        return parsedDate;
      }
    }

    return participantsBirthDay.value;
  });

  const clientAge = computed(() => {
    return birthDay.value ? calculateAge(birthDay.value.toISOString()) : null;
  });

  async function validateField ({
    contractUuid,
    fieldPath,
    pathToValidate,
    value,
    urlPath: _urlPath,
  }: {
    value: unknown
    pathToValidate: string | undefined
    contractUuid: string | string[]
    fieldPath: string
    urlPath: string
  }) {
    if (state.rehydrated.value === false) {
      console.warn('Skipping validation as store has not been hydrated yet');
      return;
    }
    try {
      await validateStoreField({
        contractUuid,
        fieldPath,
        // This is used for nominees array validation
        // where fieldPath is nominees.0.foo.value, but we need to trigger validation
        // for nominees.*.foo.value but reset errors for the nominees.0.foo.errors
        pathToValidate,
        throwOnErrors: true,
        value,
        state,
      });
    } catch (e: any) {
      if (!e.response) {
        throw e;
      }

      const { errors } = e.response.data;

      if (errors) {
        const keys = Object.keys(errors);

        // We want only the first one as we validate single field
        const [firstError] = errors[keys[0]];

        gtm.onFormFieldError(fieldPath, firstError);
      }
    }
  }

  async function validateFields ({
    contractUuid,
    fields,
    throwOnErrors,
    fieldPaths,
    validateAll = false,
    omit = [],
    documentsSent = false,
  }: {
    contractUuid: string | string[]
    fields?: { path: string, value?: unknown, pathToValidate?: string }[]
    fieldPaths?: string | string[]
    throwOnErrors: boolean
    validateAll?: boolean
    omit?: string[]
    documentsSent?: boolean
  }) {
    return await validateStoreFields(state, {
      contractUuid,
      fields,
      throwOnErrors,
      fieldPaths,
      validateAll,
      omit,
      documentsSent,
    });
  }

  function resetPensionQuestionnaire () {
    const path = state.contributionAndStrategy;

    path.selectCustomStrategy.value = path.selectCustomStrategy.defaultValue;
    path.showQuestionnaire.value = path.showQuestionnaire.defaultValue;
    path.totalPoints.value = path.totalPoints.defaultValue;
    path.selectedStrategy.value = path.selectedStrategy.defaultValue;
    path.fundCombination.value = path.fundCombination.defaultValue;
    path.investmentRiskCategory.value = path.investmentRiskCategory.defaultValue;
    path.excludeConservativeFund.value = path.excludeConservativeFund.defaultValue;
    path.recommendedStrategies.value = path.recommendedStrategies.defaultValue;

    const questionFields = ['answer', 'openAnswer', 'category'];
    for (let i = 1; i <= 10; i++) {
      const questionKey = `question${i}` as keyof typeof path.questionnaire;
      const question = path.questionnaire[questionKey];

      questionFields.forEach((field) => {
        const questionField = question[field as keyof typeof question];
        questionField.value = questionField.defaultValue;
      });
    }
  }

  function updatePreferConservativeFund () {
    if (birthDay.value === null) {
      return;
    }

    state.contributionAndStrategy.preferConservativeFund.value = canEnterRent(birthDay.value);
  }

  return {
    ...toRefs(state),
    isParticipant,
    isCommittee,
    birthDay,
    isClientFromCzechia,
    participantsBirthDay,
    isFromTransfer,
    isFromTermination,
    isFromRequest,
    transferFromTransformFund,
    validateField,
    validateFields,
    resetPensionQuestionnaire,
    clientAge,
    updatePreferConservativeFund,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useDistributionStore, import.meta.hot));
}
