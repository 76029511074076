<template>
  <LitModal
    :model-value="modelValue"
    @click="$emit('close', $event)"
  >
    <template #header>
      <h3>
        Opravdu chcete {{ form }} uzavřít<br> v&nbsp;papírové podobě?
      </h3>
    </template>

    <template #footer>
      <div class="flex flex-between gap-1">
        <LitButton
          variant="outline"
          class="text-uppercase"
          :disabled="disabled"
          @click="$emit('close', $event)"
        >
          ne, podepsat elektronicky
        </LitButton>

        <LitButton
          type="button"
          dusk="submit-step"
          :disabled="disabled"
          @click="$emit('click', $event)"
        >
          <span class="text-uppercase">
            ano, vytisknout
          </span>
        </LitButton>
      </div>
    </template>
  </LitModal>
</template>

<script setup lang="ts">
import { computed, shallowRef } from 'vue';
import LitButton from '../Base/LitButton.vue';
import LitModal from '../Base/LitModal.vue';

interface FormType {
  contract: string
  request: string
  changeRequest: string
}

interface Props {
  modelValue: boolean
  formType: keyof FormType
  disabled: boolean
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
  disabled: false,
  formType: 'contract',
});

defineEmits([
  'click',
  'close',
]);

const text = shallowRef({
  contract: 'smlouvu',
  request: 'žádost',
  changeRequest: 'dodatek ke smlouvě',
});

const form = computed(() => {
  return text.value[props.formType];
});
</script>
