<script lang="ts" setup>
import type { ComputedRef } from 'vue';
import { computed, nextTick, onMounted, ref, shallowRef, useTemplateRef, watch } from 'vue';

import { storeToRefs } from 'pinia';
import { useRoute, useRouter } from 'vue-router';
import { RefreshCw } from 'lucide-vue-next';

import LitCheckbox from '@/js/components/Base/LitCheckbox.vue';
import LitIconSvg from '@/js/components/Base/LitIconSvg.vue';
import LitInput from '@/js/components/Base/LitInput.vue';
import LitAlert from '@/js/components/Base/LitAlert.vue';
import LitButton from '@/js/components/Base/LitButton.vue';
import LitRadio from '@/js/components/Base/LitRadio.vue';
import LitSelect from '@/js/components/Base/LitSelect.vue';
import ContributionSelection from '@/js/components/ContributionSelection.vue';
import ChangeRequestFormsOfferModal from '@/js/components/Modals/ChangeRequestFormsOfferModal.vue';
import MissingChangeWarningModal from '@/js/components/Modals/MissingChangeWarningModal.vue';
import ChangeRequestStepper from '@/js/components/Steppers/ChangeRequestStepper.vue';
import { useDateRange } from '@/js/composables/useDateRange.ts';
import gtm from '@/js/services/gtm';
import { useChangeRequestStore } from '@/js/stores/distibution/changeRequest';
import { scrollToError } from '@/js/utils.ts';
import StepButtons from '@/js/views/common/StepButtons.vue';

import QuestionnaireForm from '@/js/components/QuestionnaireForm.vue';
import QuestionnaireRadioGroup from '@/js/components/QuestionnaireRadioGroup.vue';
import CustomStrategySelection from '@/js/components/CustomStrategySelection.vue';
import StrategySelection from '@/js/components/StrategySelection.vue';
import StepperVertical from '@/js/components/StepperVertical.vue';
import StepperVerticalItem from '@/js/components/StepperVerticalItem.vue';
import QuestionnaireLoader from '@/js/components/Questionnaire/QuestionnaireLoader.vue';
import CustomStrategyWarningModal from '@/js/components/Modals/CustomStrategyWarningModal.vue';

import availableAnswers from '@/js/data/financialQuestionnaire.json' with { type: 'json' };
import type { Question } from '@/js/types/questionnaire';
import { useQuestionnaire } from '@/js/composables/useQuestionnaire';

import { calculateQuestionnairePoints } from '@/js/utils/questionnairePoints';
import { getCategoryDescription, getStrategiesForCategory } from '@/js/utils/recommendedStrategies';
import { updateContract } from '@/js/stores/utils';
import { useDebounceFn } from '@vueuse/core';

const route = useRoute();
const router = useRouter();

const store = useChangeRequestStore();
const {
  validateField,
  validateFields,
  updatePreferConservativeFund,
  resetPensionQuestionnaire,
} = store;
const {
  contractSettings,
  isDPS,
  clientAge,
  strategy,
  questionnaire,
} = storeToRefs(store);

const {
  questions,
  status,
  loading,
  resetAnswer,
  isQuestionVisible,
  lastAnsweredQuestion,
  handleMultipleChoice,
  updateQuestionnaireUI,
  processMatrixResults,
  handleSingleChoice,
  forceKoAfterQuestion2,
} = useQuestionnaire(availableAnswers.questions, questionnaire.value);

const validating = ref(false);
const showFormsOfferModal = ref(false);
const displayChangeCheckModal = ref(false);
const showCustomStrategyModal = ref(false);

const taxOptimizationOptions = shallowRef([
  { value: true, label: 'Účastník chce sjednat Program daňové optimalizace' },
  { value: false, label: 'Účastník chce zrušit Program daňové optimalizace' },
]);

const employerActionOptions = shallowRef([
  { value: 'add', label: 'Přidat zaměstnavatele' },
  { value: 'change', label: 'Upravit zaměstnavatele' },
  { value: 'remove', label: 'Odstranit zaměstnavatele' },
]);

const taxOptimization: ComputedRef<boolean | null> = computed(() => {
  return contractSettings.value.taxOptimization.value.value;
});

const taxOptimizationChangeRequired: ComputedRef<boolean> = computed(() => {
  return contractSettings.value.taxOptimizationChangeRequired.value;
});

const contribution = computed(() => {
  return contractSettings.value.contribution.amount.value;
});

const contributionChangeRequired: ComputedRef<boolean> = computed(() => {
  return contractSettings.value.contributionChangeRequired.value;
});

const employerAction = computed(() => {
  return contractSettings.value.employer.action.value;
});

const employerChangeRequired: ComputedRef<boolean> = computed(() => {
  return contractSettings.value.employerChangeRequired.value;
});

const questionnaireFirstStep = useTemplateRef('questionnaireFirstStep');
const questionnaireSecondStep = useTemplateRef('questionnaireSecondStep');

const getQuestionErrors = (questionId: number) => {
  const questionnaireKey = `question${questionId}` as keyof typeof questionnaire.value;

  return questionnaire.value[questionnaireKey].answer.errors;
};

const getQuestionOpenAnswerErrors = (questionId: number) => {
  const questionnaireKey = `question${questionId}` as keyof typeof questionnaire.value;
  const answer = questionnaire.value[questionnaireKey].answer.value;
  const openAnswerChoice = 'd';

  if (answer !== null && answer.includes(openAnswerChoice)) {
    return questionnaire.value[questionnaireKey].openAnswer.errors;
  }

  return [];
};

const selectCustomStrategy = computed({
  get () {
    return strategy.value.selectCustomStrategy.value;
  },
  set (value) {
    strategy.value.selectCustomStrategy.value = value;
  },
});

const nothingChanged = computed(() => {
  return !contractSettings.value.contributionChangeRequired.value
    && !contractSettings.value.taxOptimizationChangeRequired.value
    && !contractSettings.value.employerChangeRequired.value
    && !strategy.value.changeRequired.value;
});

watch(taxOptimization, (newVal) => {
  if (newVal !== null) {
    contractSettings.value.taxOptimizationChangeRequired.value = true;
  }
});

watch(taxOptimizationChangeRequired, (newVal) => {
  if (!newVal) {
    contractSettings.value.taxOptimization.value.value = null;
  }
});

watch(contribution, (newVal) => {
  if (newVal !== null && newVal !== 0) {
    contractSettings.value.contributionChangeRequired.value = true;
  }
});

watch(contributionChangeRequired, (newVal) => {
  if (!newVal) {
    contractSettings.value.contribution.amount.value = null;
    contractSettings.value.contribution.group.value = '';
    contractSettings.value.contribution.grantedPension.value = false;

    handleFillQuestionnaireAgain();
  }
});

watch(employerAction, (newVal) => {
  if (newVal !== null) {
    contractSettings.value.employerChangeRequired.value = true;
  }
});

watch(employerChangeRequired, (newVal) => {
  if (!newVal) {
    contractSettings.value.employer.action.value = null;
    contractSettings.value.employer.name.value = null;
    contractSettings.value.employer.vat.value = null;
  }
});

watch(() => strategy.value.changeRequired.value, () => {
  handleFillQuestionnaireAgain();
});

function setContributionEffectiveDate () {
  if (contributionChangeRequired.value) {
    contractSettings.value.contribution.effectiveDate.value = contractSettings.value.taxOptimization.effectiveDate.value;
  }
}

function setAnswer (question: Question, answer: string | string[] | null) {
  const questionKey = `question${question.id}` as keyof typeof questionnaire.value;
  const questionAnswer = questionnaire.value[questionKey];

  let result;

  if (question.type === 'single') {
    result = handleSingleChoice(question, answer as string | null);
  } else {
    result = handleMultipleChoice(
      question,
      answer as string,
      questionAnswer.answer.value as string[] | null,
    );
  }

  if (result.answer !== undefined) {
    questionAnswer.answer.value = result.answer;
  }

  if (result.category !== undefined) {
    questionAnswer.category.value = result.category;
  }

  // Reset open answer if needed
  if (result.answer === null || (Array.isArray(result.answer) && result.answer.length === 0)) {
    questionAnswer.openAnswer.value = '';
  }

  // Reset strategy selection when answers change
  strategy.value.selectedStrategy.value = null;
  selectCustomStrategy.value = false;
}

function setOpenAnswer (question: Question, answer: string | number) {
  const questionKey = `question${question.id}` as keyof typeof questionnaire.value;
  const questionAnswer = questionnaire.value[questionKey];

  questionAnswer.openAnswer.value = String(answer);
}

const handleAnswerUpdate = (question: Question, answer: string | string[] | null) => {
  setAnswer(question, answer);
};

const handleOpenAnswerUpdate = (question: Question, answer: string | number) => {
  setOpenAnswer(question, answer);
};

async function checkSubmission () {
  if (nothingChanged.value) {
    displayChangeCheckModal.value = true;
  } else {
    await handleSubmit();
  }
}

async function handleSubmit () {
  const { contractUuid } = route.params;

  setContributionEffectiveDate();

  try {
    validating.value = true;

    await validateFields({
      contractUuid,
      throwOnErrors: true,
      fieldPaths: [
        'questionnaire.question1.openAnswer',
        'questionnaire.question1.answer',
        'questionnaire.question2.answer',
        'questionnaire.question3.answer',
        'questionnaire.question4.answer',
        'questionnaire.question5.answer',
        'questionnaire.question6.answer',
        'questionnaire.question7.answer',
        'questionnaire.question8.answer',
        'questionnaire.question9.answer',
        'questionnaire.question10.answer',

        'contractSettings.employer.vat',
        'contractSettings.employer.name',
        'contractSettings.employer.action',
        'contractSettings.employer.effectiveDate',
        'contractSettings.taxOptimization.value',
        'contractSettings.taxOptimization.effectiveDate',
        'contractSettings.contribution.grantedPension',
        'contractSettings.contribution.amount',
        'contractSettings.contribution.effectiveDate',
      ],
    });

    await router.push({
      name: 'changeRequest.recapitulation',
      params: {
        contractUuid: route.params.contractUuid,
      },
    });

    gtm.onStepSubmit('zmena-smlouvy');
  } catch (e) {
    scrollToError();

    console.warn(`There was a validation RecapitulationError: ${e}`);
  } finally {
    validating.value = false;
  }
}

const isQuestionnaireProcessed = computed(() => {
  return strategy.value.totalPoints.value !== null && !loading.value;
});

const showStrategySelection = computed(() => {
  const investmentStrategy = strategy.value.investmentRiskCategory.value;
  // do not show for investment strategy E or D
  if (investmentStrategy === 'E' || investmentStrategy === 'D') {
    return false;
  }

  return isQuestionnaireProcessed.value || selectCustomStrategy.value;
});

const currentRiskCategoryDescription = computed(() => {
  const riskCategory = strategy.value.investmentRiskCategory.value;
  if (!riskCategory) {
    return null;
  }

  return getCategoryDescription(riskCategory);
});

const fundCombination = computed({
  get () {
    return strategy.value.fundCombination.value;
  },
  set (value) {
    strategy.value.fundCombination.value = value;
  },
});

const isFundCombinationComplete = computed(() => {
  const sum = Object.values(fundCombination.value).reduce((accumulator, current) => accumulator + current, 0);

  return sum === 100;
});

const canBeSubmitted = computed(() => {
  if (!strategy.value.changeRequired.value) {
    return true;
  }

  if (strategy.value.selectedStrategy.value === 'customFundCombination') {
    return isFundCombinationComplete.value;
  }

  return strategy.value.selectedStrategy.value !== null;
});

const recommendedStrategies = computed(() => {
  const riskCategory = strategy.value.investmentRiskCategory.value;
  if (!riskCategory) {
    return [];
  }

  return getStrategiesForCategory(riskCategory);
});

const syncQuestionnaireToServer = useDebounceFn(async () => {
  const { contractUuid } = route.params;

  try {
    await updateContract({
      state: store.$state,
      contractUuid: contractUuid as string,
    });
  } catch (error) {
    console.error('Error sending answer to server:', error);
  }
}, 500);

watch(recommendedStrategies, async (newVal) => {
  if (newVal) {
    strategy.value.recommendedStrategies.value = newVal;

    await syncQuestionnaireToServer();
  }
});

const excludeConservativeFund = computed({
  get () {
    return strategy.value.excludeConservativeFund.value;
  },
  set (value) {
    strategy.value.excludeConservativeFund.value = value;
  },
});

const showQuestionnaire = computed({
  get () {
    return strategy.value.showQuestionnaire.value;
  },
  set (value) {
    strategy.value.showQuestionnaire.value = value;
  },
});

watch(clientAge, () => {
  updatePreferConservativeFund();
  resetPensionQuestionnaire();
}, {
  immediate: true,
});

const questionnaireHasErrors = ref(false);
const isValidating = ref(false);

const validateQuestionnaireFields = async (isKo = false) => {
  if (isValidating.value) {
    return false;
  }

  if (isKo) {
    updateQuestionnaireUI();
  }

  isValidating.value = true;
  const { contractUuid } = route.params;

  try {
    const fieldPaths = isKo
      ? [
          'questionnaire.question1.openAnswer',
          'questionnaire.question1.answer',
          'questionnaire.question2.answer',
        ]
      : [
          'questionnaire.question1.openAnswer',
          'questionnaire.question1.answer',
          'questionnaire.question2.answer',
          'questionnaire.question3.answer',
          'questionnaire.question4.answer',
          'questionnaire.question5.answer',
          'questionnaire.question6.answer',
          'questionnaire.question7.answer',
          'questionnaire.question8.answer',
          'questionnaire.question9.answer',
          'questionnaire.question10.answer',
        ];

    await validateFields({
      contractUuid,
      throwOnErrors: true,
      fieldPaths,
    });

    return true;
  } catch {
    questionnaireHasErrors.value = true;
    // Check if there's an error in question 1
    const question1Key = 'question1' as keyof typeof questionnaire.value;
    const question1 = questionnaire.value[question1Key];

    if (question1.answer.errors.length > 0 || question1.openAnswer.errors.length > 0) {
      showQuestionnaire.value = true;

      if (isKo) {
        // Reset from question 2 if question 1 has errors
        resetAnswer(2, (questionId, _answer) => {
          const questionKey = `question${questionId}` as keyof typeof questionnaire.value;
          const questionAnswer = questionnaire.value[questionKey];

          questionAnswer.answer.value = null;
          questionAnswer.answer.errors = [];
          questionAnswer.openAnswer.value = '';
          questionAnswer.openAnswer.errors = [];
          questionAnswer.category.value = null;
        });
      }
    }

    return false;
  } finally {
    isValidating.value = false;
  }
};

const debouncedValidation = useDebounceFn(async (isKo: boolean) => {
  const isValid = await validateQuestionnaireFields(isKo);
  if (isValid) {
    handleQuestionnairePoints();
  }

  return isValid;
}, 300);

watch([lastAnsweredQuestion, status], async ([newLastAnsweredQuestion, newStatus]) => {
  if (newLastAnsweredQuestion === 2 && strategy.value.preferConservativeFund.value && !excludeConservativeFund.value) {
    // Validate before setting the strategy
    const isValid = await debouncedValidation(true);
    if (!isValid) {
      return;
    }

    forceKoAfterQuestion2.value = true;
    strategy.value.investmentRiskCategory.value = 'E';
    handleQuestionnairePoints();
  }

  if (newStatus.isKo) {
    const isValid = await debouncedValidation(true);
    if (!isValid) {
      return;
    }

    // Check if it's a forced KO due to conservative fund conditions
    if (strategy.value.preferConservativeFund.value && !excludeConservativeFund.value) {
      strategy.value.investmentRiskCategory.value = 'E';
    } else if (!strategy.value.preferConservativeFund.value || excludeConservativeFund.value) {
      strategy.value.investmentRiskCategory.value = 'D';
    }
  }
});

watch(excludeConservativeFund, (newValue, oldValue) => {
  if (oldValue === undefined) {
    return;
  }

  if (oldValue !== null) {
    handleFillQuestionnaireAgain();
  }

  if (strategy.value.preferConservativeFund.value && !newValue) {
    forceKoAfterQuestion2.value = true;
    strategy.value.investmentRiskCategory.value = 'E';
    handleQuestionnairePoints();
  } else {
    forceKoAfterQuestion2.value = false;
  }
});

watch(() => strategy.value.preferConservativeFund.value, (newValue) => {
  if (excludeConservativeFund.value === true) {
    return;
  }

  if (newValue && excludeConservativeFund.value === false) {
    return;
  }

  excludeConservativeFund.value = newValue ? false : null;
}, {
  immediate: true,
});

function setTotalPointsFromStatus () {
  if ((status.value.isKo || status.value.unansweredQuestions === 0)
    && strategy.value.totalPoints.value === null) {
    strategy.value.totalPoints.value = calculateQuestionnairePoints(status.value.totalPoints, clientAge.value);
  }
}

function setDefaultStrategy () {
  if (strategy.value.selectedStrategy.value !== null) {
    return;
  }

  if (strategy.value.selectedStrategy.value === 'lifeCycle') {
    strategy.value.selectedStrategy.value = 'reinvestment';
  }

  if (strategy.value.selectedStrategy.value === 'reinvestment') {
    strategy.value.selectedStrategy.value = 'lifeCycle';
  }
}

function calculateAndSetPoints () {
  const basePoints = status.value.totalPoints;
  const age = clientAge.value;
  const totalPointsWithAge = calculateQuestionnairePoints(basePoints, age);

  if (status.value.unansweredQuestions === 0 || status.value.isKo) {
    strategy.value.totalPoints.value = totalPointsWithAge;
  }

  return totalPointsWithAge;
}

function handleQuestionnairePoints () {
  const totalPoints = calculateAndSetPoints();
  updateQuestionnaireUI();

  if (status.value.unansweredQuestions === 0 || status.value.isKo) {
    showQuestionnaire.value = false;
  }

  return totalPoints;
}

const handleValidateContributionFields = async () => {
  try {
    const isValid = await validateQuestionnaireFields();
    if (!isValid) {
      return;
    }

    if (!questions.value) {
      throw new Error('Questions not loaded');
    }

    const totalPoints = handleQuestionnairePoints();

    if (totalPoints < 0) {
      strategy.value.investmentRiskCategory.value = 'A';
      return;
    }

    const question7 = questions.value.find((q) => q.id === 7);
    if (question7 && question7.answer !== 'b') {
      strategy.value.investmentRiskCategory.value = 'A';
      return;
    }

    const REQUIRED_QUESTION_IDS = [8, 9, 10] as const;

    // Get required questions
    const [question8, question9, question10] = REQUIRED_QUESTION_IDS.map((id) => {
      const question = questions.value.find((q) => q.id === id);

      if (!question) {
        throw new Error(`Missing required question ${id}`);
      }

      return question;
    });

    // Validate categories
    if (!question8?.category || !question9?.category || !question10?.category) {
      throw new Error('Missing categories for questions');
    }

    // Process matrix results
    processMatrixResults(
      question8 as Question,
      question9 as Question,
      question10 as Question,
      (_matrix89Result, matrix10Result) => {
        strategy.value.investmentRiskCategory.value = matrix10Result;
      },
    );
    questionnaireHasErrors.value = false;
  } catch (error) {
    console.error('Validation error:', error);
  } finally {
    await nextTick();
    if (questionnaireHasErrors.value) {
      questionnaireFirstStep.value?.scrollIntoView({
        behavior: 'smooth',
      });
    } else {
      questionnaireSecondStep.value?.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }
};

function setShowQuestionnaire () {
  if (!selectCustomStrategy.value) {
    showQuestionnaire.value = !isQuestionnaireProcessed.value;
  } else {
    showQuestionnaire.value = false;
  }
}

function onCustomStrategyClick () {
  strategy.value.selectedStrategy.value = null;
  selectCustomStrategy.value = true;
  showQuestionnaire.value = true;
  showCustomStrategyModal.value = false;
}

function handleFillQuestionnaireAgain () {
  showQuestionnaire.value = true;
  resetAnswer(1, (questionId, _answer) => {
    const questionKey = `question${questionId}` as keyof typeof questionnaire.value;
    const questionAnswer = questionnaire.value[questionKey];

    questionAnswer.answer.value = null;
    questionAnswer.answer.errors = [];
    questionAnswer.openAnswer.value = '';
    questionAnswer.openAnswer.errors = [];
    questionAnswer.category.value = null;
  });

  strategy.value.investmentRiskCategory.value = null;
  strategy.value.selectedStrategy.value = null;
  selectCustomStrategy.value = false;
  strategy.value.totalPoints.value = null;
  strategy.value.recommendedStrategies.value = [];
}

watch(showStrategySelection, (val) => {
  if (!val) {
    strategy.value.selectedStrategy.value = null;
  }
});

watch(() => strategy.value.changeRequired.value, (v) => {
  forceKoAfterQuestion2.value = !v;
}, {
  immediate: true,
});

watch(selectCustomStrategy, async (val) => {
  const { contractUuid } = route.params;

  if (val) {
    showQuestionnaire.value = false;
  } else {
    strategy.value.selectedStrategy.value = null;
  }

  await validateFields({
    contractUuid,
    throwOnErrors: false,
    fields: [
      {
        value: val,
        path: 'strategy.selectCustomStrategy',
      },
    ],
  });
});

watch(fundCombination, () => {
  const { contractUuid } = route.params;

  updateContract({
    state: store.$state,
    contractUuid: contractUuid as string,
  });
}, {
  deep: true,
});

watch(isDPS, (newValue) => {
  // Reset form if user is not on DPS and change required is true
  if (!newValue && strategy.value.changeRequired.value) {
    handleFillQuestionnaireAgain();

    strategy.value.changeRequired.value = false;
  }
}, {
  immediate: true,
});

onMounted(() => {
  setTotalPointsFromStatus();
  setShowQuestionnaire();
  setDefaultStrategy();

  // Show questionnaire if not processed or in error state
  if (!isQuestionnaireProcessed.value || status.value.isKo) {
    showQuestionnaire.value = true;
  }
});
</script>

<template>
  <div>
    <ChangeRequestStepper current-step="contractSettings" />

    <div class="container--sm mt-20">
      <div v-if="isDPS" class="mb-50">
        <h3>
          Úprava sjednané strategie
        </h3>

        <p>
          Abychom mohli doporučit tu nejvhodnější strategii spoření, potřebujeme vědět, jaké jsou účastníkovy cíle v rámci Penzijka, jak se orientuje ve světě financí a investic a jaký je jeho vztah k investičnímu riziku.
        </p>
        <p>Prosíme, vyplňte s účastníkem penzijní dotazník.</p>

        <ServerValidatedFormField
          v-slot="{ value, input, errors }"
          namespace="changeRequest"
          class="my-20"
          field-path="strategy.changeRequired"
          :default-value="null"
          :debounce="0"
          :validate-on-input="true"
          @validate="validateField"
        >
          <LitCheckbox
            dusk="show-contribution-questionnaire"
            :model-value="value"
            :error="errors.length > 0 ? errors[0] : ''"
            @update:model-value="input"
          >
            Upravit sjednanou strategii
          </LitCheckbox>
        </ServerValidatedFormField>

        <div v-if="strategy.changeRequired.value">
          <LitAlert v-if="strategy.preferConservativeFund.value && strategy.excludeConservativeFund !== null">
            <p>
              Vážený kliente,
              <br><br>
              ze zákona nám vyplývá povinnost umístit Vaše prostředky nejpozději 5 let před dosažením důchodového věku do povinného konzervativního fondu.
              <br><br>
              V případě, že s umístěním Vašich prostředků do povinného konzervativního fondu nesouhlasíte, vystavujete se riziku, že v případě nepříznivého vývoje na trzích může dojít k významným ztrátám, které mohou ovlivnit hodnotu úspor těsně před důchodem.
            </p>

            <ServerValidatedFormField
              v-slot="{ value, input, errors }"
              namespace="changeRequest"
              field-path="strategy.excludeConservativeFund"
              :validate-on-input="true"
              @validate="validateField"
            >
              <LitCheckbox
                :model-value="value"
                :error="errors.length > 0 ? errors[0] : ''"
                class="mt-20"
                @update:model-value="input"
              >
                Žádám, aby mé prostředky nebyly umístěny do povinného konzervativní fondu a přeji si doporučit jinou strategii spoření.
              </LitCheckbox>
            </ServerValidatedFormField>
          </LitAlert>

          <StepperVertical>
            <StepperVerticalItem :class="{ disabled: isQuestionnaireProcessed }">
              <template #icon>
                <span ref="questionnaireFirstStep" class="step__icon--number">
                  1
                </span>
              </template>

              <template #label>
                Vyplňte penzijní dotazník
              </template>

              <template #content>
                <LitAlert
                  v-if="isQuestionnaireProcessed"
                  dusk="questionnaire-successful-alert"
                  alert-type="success"
                  class="mb-20"
                >
                  Děkujeme za vyplnění penzijního dotazníku.
                </LitAlert>

                <div
                  v-show="isQuestionnaireProcessed"
                  dusk="toggle-show-questionnaire"
                  class="btn-simple mb-20"
                  @click="handleFillQuestionnaireAgain"
                >
                  <RefreshCw :size="12" class="mr-5" />

                  <span>
                    Vyplnit dotazník znovu
                  </span>
                </div>

                <div v-show="!loading && showQuestionnaire">
                  <QuestionnaireForm
                    :questions="(questions as Question[])"
                    :is-question-visible="isQuestionVisible"
                    @update:answer="handleAnswerUpdate"
                    @update:open-answer="handleOpenAnswerUpdate"
                  >
                    <template #singleOption="{ question, updateAnswer }">
                      <ServerValidatedFormField
                        v-slot="{ value, errors, input }"
                        namespace="changeRequest"
                        :default-value="question.answer"
                        :field-path="`questionnaire.question${question.id}.answer`"
                        :validate-on-input="true"
                        @validate="validateField"
                      >
                        <QuestionnaireRadioGroup
                          v-if="question.viewStyle && question.viewStyle === 'radioGroup'"
                          :model-value="typeof question.answer === 'string' ? question.answer : ''"
                          :options="(question.options as any)"
                          :error="errors.length > 0 ? errors[0] : ''"
                          @update:model-value="updateAnswer(question, $event); input($event)"
                        />
                        <LitRadio
                          v-else
                          :name="`question${question.id}Options-change`"
                          :options="question.options"
                          :model-value="value"
                          :error="errors.length > 0 ? errors[0] : ''"
                          @update:model-value="updateAnswer(question, $event); input($event)"
                        />
                      </ServerValidatedFormField>
                    </template>

                    <template #multipleOption="{ question, option, updateAnswer, disabled }">
                      <ServerValidatedFormField
                        v-slot="{ errors, input }"
                        namespace="changeRequest"
                        :default-value="question.answer"
                        :field-path="`questionnaire.question${question.id}`"
                        :validate-on-input="true"
                        @validate="validateField"
                      >
                        <LitCheckbox
                          :label="option.label"
                          :error="errors.length > 0 ? errors[0] : ''"
                          :model-value="question.answer?.includes(option.value)"
                          rounded
                          :is-disabled="disabled"
                          @update:model-value="updateAnswer(question, option.value); input($event)"
                        >
                          {{ option.label }}
                        </LitCheckbox>
                      </ServerValidatedFormField>
                    </template>

                    <template #additionalContent="{ question }">
                      <div
                        v-if="getQuestionErrors(question.id).length > 0"
                        class="text-danger pt-10"
                      >
                        {{ getQuestionErrors(question.id)[0] }}
                      </div>

                      <div
                        v-if="getQuestionOpenAnswerErrors(question.id).length > 0"
                        class="text-danger pt-10"
                      >
                        {{ getQuestionOpenAnswerErrors(question.id)[0] }}
                      </div>
                    </template>
                  </QuestionnaireForm>

                  <div
                    v-if="!status.isKo && lastAnsweredQuestion >= 2"
                    class="flex flex-center"
                  >
                    <LitButton
                      class="mt-15 mb-15"
                      dusk="evaluate-questionnaire"
                      :disabled="status.unansweredQuestions !== 0"
                      @click="handleValidateContributionFields"
                    >
                      Vyhodnotit penzijní dotazník
                    </LitButton>
                  </div>
                </div>

                <QuestionnaireLoader v-if="loading" />
              </template>
            </StepperVerticalItem>

            <StepperVerticalItem :class="{ disabled: !isQuestionnaireProcessed }">
              <template #icon>
                <span
                  ref="questionnaireSecondStep"
                  class="step__icon--number"
                >
                  2
                </span>
              </template>

              <template #label>
                Vyberte strategii spoření
              </template>

              <template #content>
                <LitAlert
                  v-if="!showStrategySelection && !isQuestionnaireProcessed"
                  dusk="strategy-selection-alert"
                >
                  Navrhované strategie spoření se zobrazí po vyplnění Penzijního dotazníku.
                </LitAlert>

                <div v-if="isQuestionnaireProcessed && selectCustomStrategy">
                  <p class="mb-30">
                    Výběrem vlastní strategie spoření se klient vystavuje riziku,
                    že zvolená strategie spoření nebude odpovídat
                    jeho cílům, odborným znalostem nebo zkušenostem potřebným
                    pro pochopení rizik souvisejících s investováním.
                  </p>

                  <ServerValidatedFormField
                    v-slot="{ value, input }"
                    namespace="changeRequest"
                    field-path="strategy.selectedStrategy"
                    :debounce="0"
                    :validate-on-input="true"
                    @validate="validateField"
                  >
                    <CustomStrategySelection
                      v-model:fund-combination="fundCombination"
                      :model-value="value"
                      :is-custom-strategy="selectCustomStrategy"
                      :is-reinvestment-allowed="false"
                      :prefer-conservative-fund="strategy.preferConservativeFund.value"
                      @update:model-value="input"
                    />
                  </ServerValidatedFormField>
                </div>

                <div v-if="isQuestionnaireProcessed && !selectCustomStrategy">
                  <LitAlert
                    v-if="status.isKo && !status.isKoFromQuestion2"
                    class="mb-30"
                  >
                    Vážená klientko / Vážený kliente,
                    <br><br>
                    na základě vámi uvedených informací jsme vyhodnotili, že pro vás sjednání Doplňkového penzijního
                    spoření není vhodné. Jedná se o dlouhodobé spoření,
                    a proto vám nedoporučujeme smlouvu uzavřít.
                  </LitAlert>
                  <p v-else class="mb-30">
                    <span v-if="currentRiskCategoryDescription !== null">
                      {{ currentRiskCategoryDescription }}
                      <br><br>
                      Na základě vašich odpovědí vám doporučujeme níže uvedené strategie.
                    </span>
                  </p>

                  <ServerValidatedFormField
                    v-slot="{ value, input }"
                    namespace="changeRequest"
                    field-path="strategy.selectedStrategy"
                    validate-on-input
                    :debounce="0"
                    @validate="validateField"
                  >
                    <StrategySelection
                      dusk="strategy-selection"
                      :model-value="value"
                      :recommended-strategies="recommendedStrategies"
                      @update:model-value="input"
                    />
                  </ServerValidatedFormField>
                </div>

                <div v-show="showStrategySelection && isQuestionnaireProcessed" class="text-center mt-20">
                  <button
                    v-if="!selectCustomStrategy"
                    class="btn-simple"
                    type="button"
                    @click="showCustomStrategyModal = true"
                  >
                    Případně vyberte vlastní strategii
                  </button>
                  <button
                    v-else
                    class="btn-simple"
                    type="button"
                    @click="selectCustomStrategy = false"
                  >
                    Zpět na doporučené strategie
                  </button>
                </div>
              </template>
            </StepperVerticalItem>
          </StepperVertical>
        </div>
      </div>

      <div class="flex flex-between">
        <h3 dusk="contribution-heading" class="mt-10">
          Měsíční příspěvek
        </h3>

        <LitIconSvg
          icon-name="choice-reset"
          class="pointer"
          @click="contractSettings.contributionChangeRequired.value = false"
        />
      </div>

      <ServerValidatedFormField
        v-slot="{ value, input, errors }"
        namespace="changeRequest"
        field-path="contractSettings.contribution.amount"
        class="mb-40"
        :validate-on-input="true"
        :debounce="0"
        @validate="validateField"
      >
        <ContributionSelection
          v-model:contribution-group="contractSettings.contribution.group.value"
          class="mb-30"
          :model-value="value"
          :error="errors.length > 0 ? errors[0] : ''"
          :is-for-distributor="true"
          :is-granted-pension="contractSettings.contribution.grantedPension.value"
          @update:model-value="input"
        />
      </ServerValidatedFormField>

      <ServerValidatedFormField
        v-slot="{ value, input, errors }"
        namespace="changeRequest"
        class="mb-40"
        field-path="contractSettings.contribution.grantedPension"
        :default-value="false"
        :debounce="0"
        :validate-on-input="true"
        @validate="validateField"
      >
        <LitCheckbox
          class="mb-20"
          dusk="granted-pension"
          tooltip="Od 1. 7. 2024 nemá klient, kterému již byl přiznám starobní důchod, nárok na státní příspěvek. Daňový odpočet lze uplatnit již od 1 Kč zaplaceného vlastního příspěvku."
          :show-tooltip="true"
          :model-value="value"
          :error="errors.length > 0 ? errors[0] : ''"
          @update:model-value="input"
        >
          Klientovi již byl přiznán starobní důchod
        </LitCheckbox>
      </ServerValidatedFormField>

      <div
        v-show="contractSettings.contributionChangeRequired.value && isDPS && !strategy.changeRequired.value"
        class="mb-20"
      >
        <QuestionnaireForm
          :questions="(questions as Question[])"
          :is-question-visible="isQuestionVisible"
          @update:answer="handleAnswerUpdate"
          @update:open-answer="handleOpenAnswerUpdate"
        >
          <template #singleOption="{ question, updateAnswer }">
            <ServerValidatedFormField
              v-slot="{ value, errors, input }"
              namespace="changeRequest"
              :default-value="question.answer"
              :field-path="`questionnaire.question${question.id}.answer`"
              :validate-on-input="true"
              @validate="validateField"
            >
              <QuestionnaireRadioGroup
                v-if="question.viewStyle && question.viewStyle === 'radioGroup'"
                :model-value="typeof question.answer === 'string' ? question.answer : ''"
                :options="(question.options as any)"
                :error="errors.length > 0 ? errors[0] : ''"
                @update:model-value="updateAnswer(question, $event); input($event)"
              />
              <LitRadio
                v-else
                :name="`question${question.id}Options`"
                :options="question.options"
                :model-value="value"
                :error="errors.length > 0 ? errors[0] : ''"
                @update:model-value="updateAnswer(question, $event); input($event)"
              />
            </ServerValidatedFormField>
          </template>

          <template #multipleOption="{ question, option, updateAnswer, disabled }">
            <ServerValidatedFormField
              v-slot="{ errors, input }"
              namespace="changeRequest"
              :default-value="question.answer"
              :field-path="`questionnaire.question${question.id}`"
              :validate-on-input="true"
              @validate="validateField"
            >
              <LitCheckbox
                :label="option.label"
                :error="errors.length > 0 ? errors[0] : ''"
                :model-value="question.answer?.includes(option.value)"
                rounded
                :is-disabled="disabled"
                @update:model-value="updateAnswer(question, option.value); input($event)"
              >
                {{ option.label }}
              </LitCheckbox>
            </ServerValidatedFormField>
          </template>

          <template #additionalContent="{ question }">
            <div
              v-if="getQuestionErrors(question.id).length > 0"
              class="text-danger pt-10"
            >
              {{ getQuestionErrors(question.id)[0] }}
            </div>

            <div
              v-if="getQuestionOpenAnswerErrors(question.id).length > 0"
              class="text-danger pt-10"
            >
              {{ getQuestionOpenAnswerErrors(question.id)[0] }}
            </div>
          </template>
        </QuestionnaireForm>
      </div>

      <div class="flex flex-between">
        <h3>Program daňové optimalizace</h3>
        <LitIconSvg
          icon-name="choice-reset"
          class="pointer"
          @click="contractSettings.taxOptimizationChangeRequired.value = false"
        />
      </div>

      <ServerValidatedFormField
        v-slot="{ value, input, errors }"
        namespace="changeRequest"
        field-path="contractSettings.taxOptimization.value"
        :debounce="0"
        @validate="validateField"
      >
        <LitRadio
          class="mb-20"
          dusk="tax-optimalization"
          name="tax-optimalization"
          :options="taxOptimizationOptions"
          :model-value="value"
          :error="errors.length > 0 ? errors[0] : ''"
          @update:model-value="input"
        />
      </ServerValidatedFormField>

      <ServerValidatedFormField
        v-slot="{ value, input, errors }"
        namespace="changeRequest"
        field-path="contractSettings.taxOptimization.effectiveDate"
        :validate-on-input="true"
        :debounce="0"
        @validate="validateField"
      >
        <LitSelect
          class="mb-20 mt-30"
          dusk="optimalization-start-date"
          label="Datum účinnosti měsíčního příspěvku a Programu daňové optimalizace"
          :options="useDateRange(3)"
          :model-value="value"
          :error="errors.length > 0 ? errors[0] : ''"
          @update:model-value="input"
        />
      </ServerValidatedFormField>

      <div class="flex flex-between">
        <h3>Příspěvek zaměstnavatele</h3>
        <LitIconSvg
          icon-name="choice-reset"
          class="pointer"
          @click="contractSettings.employerChangeRequired.value = false"
        />
      </div>

      <ServerValidatedFormField
        v-slot="{ value, input, errors }"
        namespace="changeRequest"
        field-path="contractSettings.employer.action"
        :debounce="0"
        @validate="validateField"
      >
        <LitRadio
          class="mb-20"
          dusk="employer-change"
          name="employer-change"
          :options="employerActionOptions"
          :model-value="value"
          :error="errors.length > 0 ? errors[0] : ''"
          @update:model-value="input"
        />
      </ServerValidatedFormField>

      <div v-if="contractSettings.employerChangeRequired.value">
        <div v-if="contractSettings.employer.action.value !== 'remove'">
          <ServerValidatedFormField
            v-slot="{ value, input, errors, validate }"
            namespace="changeRequest"
            field-path="contractSettings.employer.name"
            :debounce="0"
            @validate="validateField"
          >
            <LitInput
              class="mb-20"
              dusk="employer-name"
              name="nameOfEmployer"
              label="Název zaměstnavatele"
              placeholder="Např. AAA Auto, a.s."
              :model-value="value"
              :errors="errors"
              @update:model-value="input"
              @blur="validate($event.target.value)"
            />
          </ServerValidatedFormField>

          <ServerValidatedFormField
            v-slot="{ value, input, errors, validate }"
            namespace="changeRequest"
            field-path="contractSettings.employer.vat"
            :debounce="0"
            @validate="validateField"
          >
            <LitInput
              class="mb-20"
              name="employerId"
              dusk="employer-id"
              label="IČ zaměstnavatele"
              placeholder="Např. 95874375"
              :model-value="value"
              :errors="errors"
              @update:model-value="input"
              @blur="validate($event.target.value)"
            >
              <template #actions>
                <div class="actions-group">
                  <a
                    href="https://ares.gov.cz/ekonomicke-subjekty"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Vyhledat IČO v obchodním rejstříku
                  </a>
                </div>
              </template>
            </LitInput>
          </ServerValidatedFormField>
        </div>

        <ServerValidatedFormField
          v-slot="{ value, input, errors }"
          namespace="changeRequest"
          field-path="contractSettings.employer.effectiveDate"
          :validate-on-input="true"
          :debounce="0"
          @validate="validateField"
        >
          <LitSelect
            class="mb-20 mt-30"
            dusk="employer-start-date"
            label="Datum účinnosti úpravy příspěvku zaměstnavatele"
            :options="useDateRange(3)"
            :model-value="value"
            :error="errors.length > 0 ? errors[0] : ''"
            @update:model-value="input"
          />
        </ServerValidatedFormField>
      </div>

      <hr class="separator my-30">

      <div class="flex flex-between">
        <StepButtons
          namespace="changeRequest"
          previous-step="clientData"
          :disabled="!canBeSubmitted"
          @submit="checkSubmission"
        />
      </div>

      <MissingChangeWarningModal
        v-model="displayChangeCheckModal"
        @close="displayChangeCheckModal = false"
      />

      <ChangeRequestFormsOfferModal
        v-model="showFormsOfferModal"
        :forms="store.changeSettings.involvedForms.value"
        @close="showFormsOfferModal = false"
        @finish="handleSubmit"
      />
    </div>
  </div>

  <CustomStrategyWarningModal
    :model-value="showCustomStrategyModal"
    @close="showCustomStrategyModal = false"
    @click="onCustomStrategyClick"
  />
</template>

<style scoped lang="scss">
.heading_tooltip {
  display: flex;
  align-items: center;
}

.pointer {
  cursor: pointer;
}
</style>
