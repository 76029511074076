<template>
  <div dusk="transfer-confirmation">
    <div class="benefits bg-blue pt-30 pb-10">
      <div class="container--sm" />
    </div>

    <div class="container--sm my-30">
      <div>
        <div
          v-if="isNewContractFinished && !isProcessed"
          class="text-center"
        >
          <TheHeading
            type="info"
            :show-icon="false"
          >
            A už jen podpis
          </TheHeading>
        </div>
        <div
          v-else-if="isProcessed"
        >
          <TheHeading>A máte hotovo!</TheHeading>

          <h3 v-if="store.beforeStart.isTiedAgent.value">
            Nyní si stáhněte a vytiskněte dokumentaci
          </h3>

          <p v-if="!store.beforeStart.isTiedAgent.value">
            Na e-mail <strong>{{ beforeStart.distributor.email.value }}</strong> a
            <strong>{{ contactInformation.email.value }}</strong>
            jsme odeslali
            <span v-if="hasAlreadyNewContract">žádost o převod</span>
            <span v-else>žádost o převod společně s novou smlouvou</span>.
          </p>

          <p v-else>
            Na e-mail klienta <strong>{{ contactInformation.email.value }}</strong> jsme odeslali
            <span v-if="hasAlreadyNewContract">žádost o převod</span>
            <span v-else>žádost o převod společně s novou smlouvou vč. záznamu z&nbsp;jednání</span>.
          </p>

          <hr class="pb-20 mt-20">
        </div>
        <div
          v-else
          class="text-center"
        >
          <TheHeading
            type="info"
            :show-icon="false"
          >
            Výborně, to těžší máte za sebou!
          </TheHeading>
        </div>

        <TwoFormsStepper
          v-if="!isProcessed"
          class="my-30"
          :steps="steps"
        />

        <div v-if="!isNewContractFinished && !isProcessed">
          <StepButtons
            namespace="transfer"
            button-text="POKRAČOVAT NA NOVOU SMLOUVU"
            button-position="flex-center"
            dusk="go-to-new-contract"
            :show-submit-after-icon="false"
            :display-previous-step-button="false"
            @submit="createNewContract"
          />

          <router-link
            :to="{ name: 'transfer.newContract' }"
            class="flex flex-center"
            dusk="has-already-new-contract"
          >
            Klient již má sjednanou novou smlouvu
            <LitTooltip
              text="Vyberte v případě, že si klient založil smlouvu v Doplňkovém
                penzijním spoření prostřednictvím jiného kanálu
                (internetového bankovnictví, KC či jiné distribuční sítě ČSOB)"
            />
          </router-link>
        </div>

        <div v-if="isNewContractFinished && !isProcessed">
          <div v-if="isRedirected && isLitSignEnabled">
            <button
              type="button"
              class="btn btn-green btn-big"
              dusk="digital-submit-step-redirection"
              @click="showLitSignRedirectModal = true"
            >
              <span>
                PODEPSAT
              </span>
            </button>
          </div>

          <div v-if="isNewContractFinished && !isProcessed">
            <StepButtons
              namespace="transfer"
              button-position="flex-end"
              container-size="container"
              :button-text="hasAlreadyNewContract ? 'VYTISKNOUT DOKUMENT' : 'VYTISKNOUT DOKUMENTY'"
              :show-submit-after-icon="false"
              :display-previous-step-button="false"
              :disabled="isSubmitting"
              dusk="print-submit-step"
              @submit="submitStep('offline')"
            />
          </div>
          <div v-if="isLitSignEnabled">
            <!--            <DropDownButtonsGroup -->
            <!--              :display-drop-down-button="isParticipant && !isRedirected" -->
            <!--              form-type="transfer" -->
            <!--              @open-modal="displayLitSignModal" -->
            <!--              @send-draft-token="sendDraftToken('digital')" -->
            <!--            /> -->
          </div>
        </div>
        <div v-if="isLitSignEnabled">
          <div
            v-show="isParticipant && isNewContractFinished && !isProcessed"
            dusk="print-info"
            class="print-info"
          >
            <div class="container mt-30">
              <hr>
            </div>

            <PrintOffer
              form-type="request"
              @show-modal="submitStep('offline')"
            />
          </div>

          <ContractSubmissionModal
            type="transfer"
            :model-value="redirecting"
            :is-digital="true"
          >
            <template #action>
              <p
                v-if="signingLink !== null"
                class="text-loading text-center"
              >
                Pokud nedojde k přesměrování, klikněte prosím na
                <a
                  :href="signingLink"
                >
                  tento odkaz
                </a>.
              </p>
            </template>
          </ContractSubmissionModal>

          <LitSignSignatureModal
            :model-value="showLitSignRedirectModal"
            :is-redirected="isRedirected"
            @sign-later="modalSignLater"
            @click="submitStep('digital')"
            @close="showLitSignRedirectModal = false"
          />
        </div>

        <div v-if="isProcessed">
          <div v-if="loading">
            <div class="flex flex-center mb-20 flip">
              <img
                :src="getIconUrl('icon-loader_blue')"
                alt="Načítání..."
                class="spin"
              >
            </div>
            <div class="text-loading text-center text-bold">
              Nahráváme ke stažení uzavřenou žádost o&nbsp;převod
              <span v-if="!hasAlreadyNewContract">
                a novou smlouvu
              </span>
              v PDF, chvíli strpení
            </div>
          </div>

          <LitAlert
            v-if="showTransferHiddenAlert"
            class="mb-20"
            alert-type="info"
            dusk="contract-sending-failure"
          >
            <div>
              Odkaz ke stažení žádosti o převod již není platný nebo jste
              jej stáhli již {{ transferMaxDownloadCount }}x. <span v-if="!store.beforeStart.isTiedAgent.value">Žádost však
                máte Vy i&nbsp;klient v&nbsp;e-mailu.</span>
            </div>
          </LitAlert>

          <div
            v-if="transfer.pdf"
            class="pt-10"
          >
            <p>
              Dokumenty 2x vytiskněte
              (včetně nové smlouvy, je-li součástí) a&nbsp;s&nbsp;klientem řádně podepište
              (není potřeba úřední ověření).
            </p>
            <br>
            <p>
              Jedno podepsané vyhotovení předejte klientovi.
            </p>
            <br>
            <p>
              Druhé vyhotovení bez odkladu odešlete do sídla Penzijní společnosti.
              Jsou-li vyžadovány doplňující dokumenty od klienta (rozsudek soudu, rodný list,…),
              nezapomeňte je také přiložit k zásilce.
            </p>
          </div>

          <hr
            v-if="isProcessed && transfer.pdf && transferRemainingAttempts > 0"
            class="pb-20 mt-20"
          >

          <LitAlert
            v-if="store.beforeStart.isTiedAgent.value && !showTransferHiddenAlert"
            alert-type="warning"
            class="my-20"
          >
            <strong>Dokumentace již není odesílána na e-mail obchodníka.</strong> V případě ukončení procesu/zavření
            tohoto okna,
            už nebude možné dokumentaci znovu vytisknout.
          </LitAlert>

          <div class="buttons flex flex-center gap-1">
            <div v-if="transfer.pdf && transferRemainingAttempts > 0">
              <LitButton
                :href="transfer.pdf.link"
                as="a"
                download
                class="download-button"
                @click="transfer.downloaded++"
              >
                <template #before>
                  <Download :size="20" />
                </template>

                <template #default>
                  <div class="flex flex-dir-column">
                    <span class="text-uppercase">žádost o převod</span>
                    <span class="text-sm font-weight-light">PDF: {{ transfer.pdf.size }} kB</span>
                  </div>
                </template>
              </LitButton>
            </div>

            <div v-if="newContractLocal.pdf && newContractMaxDownloadPdf > 0">
              <LitButton
                :href="newContractLocal.pdf?.link"
                as="a"
                download
                class="download-button"
                @click="newContractLocal.downloaded++"
              >
                <template #before>
                  <Download :size="20" />
                </template>

                <template #default>
                  <div class="flex flex-dir-column">
                    <span class="text-uppercase">Nová smlouva</span>
                    <span class="text-sm font-weight-light"><span v-if="store.beforeStart.isTiedAgent.value">vč. záznamu z jednání, </span>PDF: {{
                      newContractLocal.pdf?.size
                    }} kB </span>
                  </div>
                </template>
              </LitButton>
            </div>
          </div>
        </div>

        <div
          v-if="transfer.pdf && transferRemainingAttempts > 0"
          class="my-20"
        >
          <div class="mt-10">
            Odkaz ke stažení žádosti o převod <span v-if="newContractLocal.pdf && newContractRemainingAttempts > 0"> a
              nové smlouvy </span>ve formátu PDF je <strong>platný
              do {{ transferAccessTokenValidity && useDateTimeFormat(transferAccessTokenValidity) }}</strong>.
            Žádost o převod můžete stáhnout {{ transferRemainingAttempts }}x<span
              v-if="newContractLocal.pdf && newContractMaxDownloadPdf > 0"
            >, novou smlouvu {{ newContractMaxDownloadPdf }}x</span>.
          </div>
        </div>

        <hr
          v-if="isProcessed"
          class="pb-20 mt-20"
        >

        <div
          v-if="isProcessed"
          class="flex flex-center"
        >
          <LitButton
            variant="outline"
            :to="{ name: 'distribution.home' }"
          >
            Přejít na hlavní rozcestník
          </LitButton>
        </div>
      </div>
    </div>

    <ContractSubmissionModal
      type="transfer"
      :model-value="submitting"
    />
  </div>
</template>

<script setup lang="ts">
import { Download } from 'lucide-vue-next';
import { storeToRefs } from 'pinia';
import { computed, onMounted, ref, watch } from 'vue';
import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router';
import StepButtons from '../../common/StepButtons.vue';
import type { File } from '@/js/stores/types';
import { contracts, signatures } from '@/js/api';
import contractFiles from '@/js/api/contractFiles';

import { fields } from '@/js/commonStepFieldPaths';
import LitAlert from '@/js/components/Base/LitAlert.vue';
import LitButton from '@/js/components/Base/LitButton.vue';
import LitTooltip from '@/js/components/Base/LitTooltip.vue';
import ContractSubmissionModal from '@/js/components/Modals/ContractSubmissionModal.vue';
import LitSignSignatureModal from '@/js/components/Modals/LitSignSignatureModal.vue';
import PrintOffer from '@/js/components/PrintOffer.vue';
import TwoFormsStepper from '@/js/components/Steppers/TwoFormsStepper.vue';

import TheHeading from '@/js/components/TheHeading.vue';
import { useDateTimeFormat } from '@/js/composables/useDateTimeFormat';
import env from '@/js/env';
import { useDistributionStore, useTransferStore } from '@/js/stores';
import { copyRootFields, resetState, submitContract } from '@/js/stores/utils';

import { getIconUrl, removeContractsMetadata, scrollToError } from '@/js/utils';

const route = useRoute();
const router = useRouter();

const store = useTransferStore();
const { validateFields } = store;
const {
  isParticipant,
  contactInformation,
  beforeStart,
  currentContractInformation,
  submitResponse,
  newContract,
} = storeToRefs(store);

const distributionStore = useDistributionStore();

const transfer = ref<{ pdf: null | { size: number, link: string }, downloaded: number }>({
  pdf: null,
  downloaded: 0,
});
const newContractLocal = ref<{ pdf: null | { size: number, link: string }, downloaded: number }>({
  pdf: null,
  downloaded: 0,
});

const loading = ref(false);
const now = ref(new Date());
const showLitSignRedirectModal = ref(false);
const isRedirected = ref(false);
const redirecting = ref(false);
const signingLink = ref(null);
const isProcessed = ref(false);
const submitting = ref(false);
const validating = ref(false);
const currentTimeInterval = ref<number | null>(null);
const error = ref(false);

const isLitSignEnabled = computed(() => {
  return env.ENABLE_LITSIGN;
});

const isSubmitting = computed(() => {
  return submitting.value || redirecting.value;
});

const transferAccessTokenValidity = computed(() => {
  const validToken = submitResponse.value.value
    ?.metadata
    .accessTokenValidity;
  return validToken ? new Date(validToken) : null;
});

const isTransferTokenValid = computed(() => {
  if (transferAccessTokenValidity.value) {
    return transferAccessTokenValidity.value > now.value;
  }

  return false;
});

const transferPdf = computed(() => {
  return transfer.value.pdf;
});

const isNewContractFinished = computed(() => {
  if (route.query.contract) {
    return route.query.contract;
  }

  return false;
});

const newContractRemainingAttempts = ref(0);

const newContractMaxDownloadPdf = computed(() => {
  return newContractRemainingAttempts.value - newContractLocal.value.downloaded;
});

const transferMaxDownloadCount = computed(() => {
  return submitResponse.value.value
    ?.metadata
    .maxDownloadCount;
});

const transferRemainingAttempts = computed(() => {
  return (transferMaxDownloadCount.value || 0) - transfer.value.downloaded;
});

const showTransferHiddenAlert = computed(() => {
  return !isTransferTokenValid.value || transferRemainingAttempts.value <= 0;
});

const transferId = computed(() => {
  return submitResponse.value.value?.metadata.contractId;
});

const transferAccessToken = computed(() => {
  return submitResponse.value.value?.metadata.accessToken;
});

const newContractMaxDownloadCount = ref(0);

watch(newContractMaxDownloadCount, (newValue) => {
  newContractRemainingAttempts.value = newValue - newContractLocal.value.downloaded;
});

const newContractAccessTokenValidity = computed(() => {
  const validToken = distributionStore.submitResponse.value
    ?.metadata
    .accessTokenValidity;
  return validToken ? new Date(validToken) : null;
});

const isNewContractTokenValid = computed(() => {
  if (newContractAccessTokenValidity.value) {
    return newContractAccessTokenValidity.value > now.value;
  }

  return false;
});

const newContractPdf = computed(() => {
  return newContractLocal.value.pdf;
});

const hasAlreadyNewContract = computed({
  get () {
    return newContract.value.hasAlreadyNewContract.value;
  },
  set (value) {
    newContract.value.hasAlreadyNewContract.value = value;
  },
});

const newContractUuid = computed({
  get () {
    return newContract.value.uuid.value;
  },
  set (value) {
    newContract.value.uuid.value = value;
  },
});

const steps = computed(() => {
  return [
    {
      stepNumber: 1,
      title: 'Výpověď smlouvy se žádostí o převod',
      isActive: true,
    },
    {
      stepNumber: 2,
      title: 'Založení nové smlouvy',
      isActive: isNewContractFinished.value,
    },
    {
      stepNumber: 3,
      title: 'Podpis dokumentace',
      isActive: isProcessed.value,
    },
  ];
});

onBeforeRouteLeave((to, _from, next) => {
  const disallowedPaths = [
    'transfer.transferConfirmation',
    'notFound',
  ];

  if (isProcessed.value) {
    clearLocalTransferContractData();

    if (isNewContractFinished.value) {
      clearLocalDistributionContractData();
    }

    if (to.name && disallowedPaths.includes(to.name.toString())) {
      return next({ name: 'distribution.home' });
    }
  }

  return next();
});

function clearLocalTransferContractData () {
  resetState(store.$state, false);
}

function clearLocalDistributionContractData () {
  resetState(distributionStore.$state, false);
}

function copyCommonFields () {
  copyRootFields(store.$state, distributionStore.$state, fields());

  copyRootFields(store.$state, distributionStore.$state, [
    {
      pathFrom: 'currentContractInformation.terminationDay.value',
      pathTo: 'contractSettings.currentContract.contractEndsAt.value',
    },
  ]);
}

function waitForTransferPdf () {
  let attempts = 0;

  const interval = setInterval(async () => {
    const files: File[] = await contractFiles.index(transferId.value);

    attempts += 1;

    const file = files.find((file) => file.file_type === 'contract');

    if (files.length > 0 && file) {
      transfer.value.pdf = {
        link: `${env.REST_API_URI}/v1/files/${file.id}?token=${transferAccessToken.value}&contractId=${transferId.value}`,
        size: Math.round(file.file_size / 1024),
      };

      clearInterval(interval);
    } else if (attempts > 10) {
      clearInterval(interval);

      throw new Error(`Unable to located contract pdf ${newContractUuid.value}`);
    }
  }, 3 * 1000);
}

async function waitForNewContractPdf () {
  let attempts = 0;

  if (newContractUuid.value === null) {
    throw new Error('Invalid Contract UUID: cannot be null');
  }

  const { data } = await contracts.show(newContractUuid.value, 'distribution');
  const metadata = data.metadata;

  newContractMaxDownloadCount.value = metadata.maxDownloadCount;
  const submitResponse = distributionStore?.$state.submitResponse.value;

  if (submitResponse === null) {
    throw new Error('Submit has failed: Response is null');
  }

  submitResponse.metadata.accessTokenValidity = metadata.accessTokenValidity;

  const interval = setInterval(async () => {
    const files: File[] = await contractFiles.index(data.rest_api_contract_id);

    const fileType = store.beforeStart.isTiedAgent.value ? 'joined-pdf' : 'contract';
    const file: File | undefined = files.find((file) => file.file_type === fileType);

    attempts += 1;

    if (files.length > 0 && file) {
      newContractLocal.value.pdf = {
        link: `${env.REST_API_URI}/v1/files/${file.id}?token=${data.metadata.accessToken}&contractId=${data.rest_api_contract_id}`,
        size: Math.round(file.file_size / 1024),
      };

      clearInterval(interval);
    } else if (attempts > 10) {
      clearInterval(interval);

      throw new Error(`Unable to located contract pdf ${newContractUuid.value}`);
    }
  }, 3 * 1000);
}

function startTimeMeasuring () {
  currentTimeInterval.value = setInterval(() => {
    now.value = new Date();
  }, 1000);
}

async function createNewContract () {
  try {
    hasAlreadyNewContract.value = false;
    const { contractUuid } = route.params;

    const response = await contracts.create('distribution');

    copyCommonFields();

    await distributionStore.validateFields({
      contractUuid: response.contract.uuid,
      throwOnErrors: true,
      fieldPaths: [],
    });

    const signature = [
      {
        signer: 'distributor',
        signMethod: 'offline',
      },
      {
        signer: 'client',
        signMethod: 'offline',
      },
    ];

    await signatures.create(response.contract.uuid, JSON.stringify(signature));

    await router.push({
      name: 'distribution.contractDocumentsAndConsents',
      params: { contractUuid: response.contract.uuid },
      query: {
        transferUuid: contractUuid,
        currentContractNumber: currentContractInformation.value.contractNumber.value,
      },
    });
  } catch (e: any) {
    const status = e?.response?.status;
    const conflictingUuid = e?.response?.headers['x-conflicts-with'];

    if (status === 409 && conflictingUuid) {
      console.warn('Redirecting to ongoing contract', conflictingUuid);

      await router.push({
        name: 'distribution.contractDocumentsAndConsents',
        params: { contractUuid: conflictingUuid },
        query: route.query,
      });
    } else {
      await router.push({
        name: 'error',
      });
    }
  }
}

// TODO finish method
async function sendDraftToken (signMethod: string) {
  const { contractUuid } = route.params;

  try {
    error.value = false;
    validating.value = true;

    await validateFields({
      contractUuid,
      throwOnErrors: true,
      fieldPaths: [],
    });

    validating.value = false;

    const signature = [
      {
        signer: 'distributor',
        signMethod,
      },
      {
        signer: 'client',
        signMethod,
      },
    ];

    await signatures.update(contractUuid, JSON.stringify(signature), false, true);

    /* const { data } = await draftTokens.create(contractUuid as string, signMethod);

    this.draftTokenValue = data.draftToken;
    this.draftTokenValidity = data.validTo; */

    // TODO where to redirect, if draftToken is sent
  } catch (e: any) {
    error.value = true;

    scrollToError();

    console.error('Něco se nepovedlo', {
      response: e?.response,
    });
  } finally {
    validating.value = false;
  }
}

// TODO finish method
async function submitStep (signMethod: string) {
  const { contractUuid } = route.params;

  try {
    error.value = false;
    validating.value = true;
    showLitSignRedirectModal.value = false;
    submitting.value = true;

    const isDigital = signMethod === 'digital';

    if (isDigital) {
      redirecting.value = true;
    }

    const signature = [
      {
        signer: 'client',
        signMethod,
      },
      {
        signer: 'distributor',
        signMethod,
      },
    ];

    await signatures.update(contractUuid, JSON.stringify(signature), true);

    if (!hasAlreadyNewContract.value) {
      await signatures.update(newContractUuid.value!, JSON.stringify(signature), true);
      await sendNewContract(signMethod);
    }

    await submitContract(store.$state, {
      contractUuid: contractUuid as string,
      throwOnErrors: true,
      signature,
      removeContract: false,
    });

    if (isDigital) {
      // TODO get signingLinks and redirect
    } else {
      loading.value = true;
      isProcessed.value = true;
      submitting.value = false;

      if (!hasAlreadyNewContract.value) {
        await waitForNewContractPdf();
      }

      waitForTransferPdf();

      startTimeMeasuring();
    }
  } catch (e: any) {
    error.value = true;
    submitting.value = false;

    scrollToError();

    console.error('Něco se nepovedlo', {
      response: e?.response,
    });
  } finally {
    validating.value = false;
    submitting.value = false;

    if (newContractUuid.value) {
      removeContractsMetadata([contractUuid as string, newContractUuid.value]);
    }
  }
}

async function sendNewContract (signMethod: string) {
  try {
    const signature = [
      {
        signer: 'client',
        signMethod,
      },
      {
        signer: 'distributor',
        signMethod,
      },
    ];

    await submitContract(distributionStore.$state, {
      contractUuid: route.query.newContractUuid as string,
      throwOnErrors: true,
      signature,
      removeContract: false,
    });
  } catch (e: any) {
    error.value = true;

    scrollToError();

    console.error('Něco se nepovedlo', {
      response: e?.response,
    });
  }
}

function modalSignLater () {
  if (!isRedirected.value) {
    sendDraftToken('digital');
  }

  showLitSignRedirectModal.value = false;
}

watch(transferPdf, (newValue) => {
  if (newValue !== null) {
    loading.value = false;
  }
});

watch(newContractPdf, (newValue) => {
  if (newValue !== null && transferPdf.value !== null) {
    loading.value = false;
  }
});

watch(isTransferTokenValid, (newValue) => {
  if (!newValue) {
    currentTimeInterval.value && clearInterval(currentTimeInterval.value);
    transfer.value.pdf = null;
  }
});

watch(isNewContractTokenValid, (newValue) => {
  if (!newValue) {
    currentTimeInterval.value && clearInterval(currentTimeInterval.value);
    newContractLocal.value.pdf = null;
  }
});

onMounted(() => {
  if (route.query.newContractUuid) {
    newContractUuid.value = route.query.newContractUuid as string;
  }
});
</script>

<style lang="scss" scoped>
@import '@sass/tools/variables';
/* stylelint-disable-line no-extra-semicolons */
@import '@sass/tools/functions';
@import '@sass/tools/mixins';

.modal__body {
  .btn-custom {
    padding-left: 0;
    padding-right: 0;
  }

  .btn-big {
    padding: 20px 30px 15px 25px;
  }
;

  .btns-small-font {
    .btn {
      font-size: 12px;
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .icons {
    flex: 0 0 70px;
    position: relative;

    &__warning {
      position: absolute;
      top: 0;
      right: 20px;
      width: 18px;
    }

    &__default {
      width: 35px;
      height: 35px;
    }
  }

  .info-text {
    color: rgba(9, 47, 104, .647);
  }
}

.buttons {
  @include media(max, $md) {
    flex-direction: column;
    gap: 1rem;
    flex-grow: 1;
  }
}

.download-button {
  @include media(max, $md) {
    width: 100%;
  }
}
</style>
